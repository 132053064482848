import React, { useEffect, useState, useMemo } from 'react';
import { FlatList, Text, View, StyleSheet } from 'react-native';
import { withTheme } from 'react-native-paper';

// Define TypeScript interface for props
interface IAlphabetListProps {
  items: any[];  // Consider defining a more specific type
  theme: any;    // Define a specific type for theme if possible
  displayCount: number;
  renderListItem: (item: any) => JSX.Element;
}

// Helper function to sort and group items
const sortAndGroupItems = (items) => {
  const sortedItems = items.sort((a, b) => a.value.localeCompare(b.value));
  return sortedItems.reduce((result, item) => {
    const firstLetter = item.value[0].toUpperCase();
    if (!result[firstLetter]) {
      result[firstLetter] = [];
    }
    result[firstLetter].push(item);
    return result;
  }, {});
};

const AlphabetList = React.memo(({ items, theme, renderListItem, displayCount }: IAlphabetListProps) => {
  const [groupedItems, setGroupedItems] = useState({});
  const themeStyles = styles(theme);

  useEffect(() => {
    if (items) {
      const newGroupedItems = sortAndGroupItems(items);
      setGroupedItems(newGroupedItems);
    }
  }, [items]);

  const flattenedItems = useMemo(() =>
    Object.entries(groupedItems).reduce((acc, [letter, items]) =>
      [...acc, ...items.map(item => ({ letter, item }))],
    []),
    [groupedItems]
  );

  const renderHeader = (letter) => (
    <View style={themeStyles.sectionHeader}>
      <Text style={themeStyles.sectionHeaderText}>{letter}</Text>
    </View>
  );

  return (
    <View>
      {flattenedItems.slice(0, displayCount).map(({ letter, item }, index, self) => {
        const showHeader = index === 0 || letter !== self[index - 1].letter;
        return (
          <View key={`${item.id}_${index}`}>
            {showHeader && renderHeader(letter)}
            {renderListItem(item)}
          </View>
        );
      })}
    </View>
  );
});
const styles = theme => StyleSheet.create({
  sectionHeader: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    marginTop: 20,
  },
  sectionHeaderText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 20,
    fontWeight: '700',
  }
})


export default withTheme(AlphabetList);
