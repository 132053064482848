import { StyleSheet, Text, View, Image, ActivityIndicator } from 'react-native';
import * as Font from 'expo-font';
import Constant from 'expo-constants';
import Home from './screens/Home';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { useCallback, useEffect, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './core/store'
import { Button, Provider as PaperProvider } from 'react-native-paper';
import { CombinedDefaultTheme } from './Theme'
import { faDumbbell, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import AppSettings from './screens/SettingsScreen';
import { getUserSettingsSelector } from './core/selectors/UserSettingsSelector';
import FlashMessage from 'react-native-flash-message';
import CreateTrainingContainer from './screens/training/CreateTrainingContainer';
import { useFonts } from 'expo-font';
import AddUpdateRound from './screens/training/AddUpdateRound';
import AddExercises from './screens/training/AddExercises';
import ClientsContainer from './screens/clients/ClientsContainer';
import ClientView from './screens/clients/ClientView';
import ExercisesContainer from './screens/exercises/ExercisesContainer';
import { ImagesAssets } from './assets/ImagesAssets';
import TrainingsContainer from './screens/training/TrainingsContainer';
import EditTrainingContainer from './screens/training/EditTrainingContainer';
import CreateClientContainer from './screens/clients/CreateClientContainer';
import EditClientContainer from './screens/clients/EditClientContainer';
import CreateExerciseContainer from './screens/exercises/CreateExerciseContainer';
import EditExerciseContainer from './screens/exercises/EditExerciseContainer';
import CreateExerciseGroupContainer from './screens/exerciseGroups/CreateExerciseGroupContainer';
import ExerciseGroupsContainer from './screens/exerciseGroups/ExerciseGroupsContainer';
import EditExerciseGroupContainer from './screens/exerciseGroups/EditExerciseGroupContainer';
import CreateFitnessCenterContainer from './screens/fitnessCenter/CreateFitnessCenterContainer';
import SignUpScreen from './screens/SignUpScreen';
// import SplashScreen from './screens/SplashScreen';
import Login from './screens/LoginScreen';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getUser } from './core/actions/UserActions';
import { userSelector } from './core/selectors/UserSelector';
import PreviousTrainings from './screens/training/PreviousTrainings';
import UserSettings from './screens/user/UserSettings';
import * as SplashScreen from 'expo-splash-screen';

const Stack = createNativeStackNavigator();

SplashScreen.preventAutoHideAsync()
  .then((result) => console.log(`SplashScreen.preventAutoHideAsync() succeeded: ${result}`))
  .catch(console.warn); // it's good to explicitly catch and inspect any error


function LogoTitle() {
  return (
    <Image style={{width: 120, height: 46}} source={ImagesAssets.logoIcon}/>
  );
}

const NavigationStackScreen = (name, component, label, showLogo) => {
  return (
    <Stack.Screen name={name} component={component} options={
      ({route, navigation}) => ({
      ...( showLogo ? { headerTitle: props => <LogoTitle  />} :  {title: label}
    ),
      headerRight: () => (
        <Button
          onPress={() => navigation.navigate('AppSettings')}
        >
          <FontAwesomeIcon icon={faEllipsisVertical}/>
        </Button>
      ),
      contentStyle:{
        backgroundColor:'#FFFFFF',
      }
   })}
   />
  );
}


const Auth = () => {
  // Stack Navigator for Login and Sign up Screen
  return (
    <Stack.Navigator initialRouteName="SignUpContainer">
      <Stack.Screen
       name="LoginScreen"
       component={Login}
       options={{headerShown: false, title: "Fitplan Login"}}
      />
      <Stack.Screen
        name="SignUpScreen"
        component={SignUpScreen}
        options={{
          headerShown: false, title: "Fitplan Registracija"
         }}
      />
    </Stack.Navigator>
  );
};


const AppNavigatorRoutes = () => {
  return (
    <Stack.Navigator>
      {NavigationStackScreen('Home', Home, 'Dom', true)}
      {NavigationStackScreen('ViewClients', ClientsContainer, 'Klijenti', false)}
      {NavigationStackScreen('ViewTrainings', TrainingsContainer, 'Treninzi', false)}
      {NavigationStackScreen('CreateTrainingContainer', CreateTrainingContainer, 'Novi trening', false)}
      {NavigationStackScreen('EditTrainingContainer', EditTrainingContainer, 'Izmena treninga', false)}
      {NavigationStackScreen('AddExercises', AddExercises, 'Dodaj vežbe', false)}
      {NavigationStackScreen('EditClientContainer', EditClientContainer, 'Izmena klijenta', false)}
      {NavigationStackScreen('CreateClientContainer', CreateClientContainer, 'Novi klijent', false)}
      {NavigationStackScreen('CreateExercise', CreateExerciseContainer, 'Nova vežba', false)}
      {NavigationStackScreen('CreateExerciseGroup', CreateExerciseGroupContainer, 'Nova grupa vežbi', false)}
      {NavigationStackScreen('EditExercise', EditExerciseContainer, 'Izmena vežbe', false)}
      {NavigationStackScreen('AppSettings', AppSettings, 'Porešavanja', false)}
      {NavigationStackScreen('AddUpdateRound', AddUpdateRound, 'Grupa vežbi', false)}
      {NavigationStackScreen('ViewExercises', ExercisesContainer, 'Vežbe', false)}
      {NavigationStackScreen('ClientView', ClientView, 'Klijent', false)}
      {NavigationStackScreen('ExerciseGroupsContainer', ExerciseGroupsContainer, 'Grupe vežbi', false)}
      {NavigationStackScreen('UserSettings', UserSettings, 'Podešavanje koristnika', false)}
      {NavigationStackScreen('EditExerciseGroup', EditExerciseGroupContainer, 'Izmena grupe vežbi', false)}
      {NavigationStackScreen('Login', Login, 'Ulogovanje', false)}
      {NavigationStackScreen('PreviousTrainings', PreviousTrainings, 'Prethodni treninzi klijenta', false)}
      {NavigationStackScreen('CreateFitnessCenterContainer', CreateFitnessCenterContainer, 'Novi fitnes centar', false)}
      {NavigationStackScreen('UpdateFitnessCenterContainer', CreateFitnessCenterContainer, 'Izmeni fitnes centar', false)}
    </Stack.Navigator>
  )
}

const App = () => {
  const userSettings = useSelector(getUserSettingsSelector);
  const [theme, setTheme] = React.useState(CombinedDefaultTheme);

  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    loadFonts();
  }, []);

  const loadFonts = async () => {
    await Font.loadAsync({
      'Montserrat': require('./assets/fonts/Montserrat_medium.ttf'),
      'Source_Sans_Pro': require('./assets/fonts/Source_Sans_Pro_semibold.ttf'),
    });
    setFontsLoaded(true);
  };


  // const [isLoaded] = useFonts({
  //   'Montserrat': require('./assets/fonts/Montserrat_medium.ttf'),
  //   'Source_Sans_Pro': require('./assets/fonts/Source_Sans_Pro_semibold.ttf'),
  // });
  const [status, setStatus] = useState('loading');
  const [token, setToken] = useState(null);
  const user = useSelector(userSelector);
  const dispatch = useDispatch();


  useEffect(() => {
    setTheme(CombinedDefaultTheme);
  }, []);


  const loadJWT = useCallback(async () => {
    try {
      const jwt = await AsyncStorage.getItem("@token");
      setToken(jwt);
      setStatus('success');
    } catch (error) {
      setToken(null);
      setStatus('error');
      console.log(`Error: ${error.message}`);
    }
  }, []);


  const navTheme = DefaultTheme;
  navTheme.colors.background = '#ffffff';

  useEffect(() => {
    loadJWT();
  }, [loadJWT]);

  useEffect(() => {
    if (token) {
      dispatch(getUser.request());
    }
  }, [token]);

  useEffect(() => {
    console.log(status)
    console.log(fontsLoaded)
    if (fontsLoaded && status!=='loading') {
      console.log('hide async')
      SplashScreen.hideAsync();
    }
  }, [fontsLoaded, status]);


  if (!fontsLoaded || status==="loading") {
    return <ActivityIndicator />;
  }

  return (
    <PaperProvider theme={theme}>
      <NavigationContainer theme={navTheme}>
        <Stack.Navigator initialRouteName='SplashScreen'>
          {/* SplashScreen which will be shown once for 5 Seconds */}
          {/* <Stack.Screen
            name='SplashScreen'
            component={SplashScreen}
            // Hiding header for Splash Screen
            options={{headerShown: false}}
          /> */}
          {(!user) ? (
            <Stack.Screen
              name='Auth'
              component={Auth}
              options={{headerShown: false}}
            />
          ) : (
          <Stack.Screen
            name='AppNavigatorRoutes'
            component={AppNavigatorRoutes}
            options={{headerShown: false}}
          />
          )}
        </Stack.Navigator>
      </NavigationContainer>
      <FlashMessage position="top" />
    </PaperProvider>
  );
}

export default () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#c1e3d5',
    marginTop: Constant.statusBarHeight,
    // alignItems: 'center',
    // justifyContent: 'center',
  },
});
