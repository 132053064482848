import { faCheckCircle, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, ScrollView, ActivityIndicator, Touchable } from 'react-native';
import { Chip, withTheme } from 'react-native-paper';
import { ScreenHeader } from '../components/Headers';
import { IconTextInput } from '../components/InputComponents';
import ExerciseInfoDialog from './ExerciseInfoDialog';
import { IExerciseInRound, IPreviousExercises, ITraining } from '../../core/models/Models';
import { getExercisesSelector } from '../../core/selectors/ExerciseSelector';
import { getExercises } from '../../core/actions/ExerciseActions';
import { useDispatch, useSelector } from 'react-redux';
import { getExerciseGroupsSelector } from '../../core/selectors/ExerciseGroupSelectors';
import { getExerciseGroups } from '../../core/actions/ExerciseGroupActions';
import { IconInCircle } from '../components/Icons';
import { getClientsPreviousTrainingsSelector, getCurrentTrainingSelector, getNewTrainingClientsPreviousTrainingsSelector, getNewTrainingSelector } from '../../core/selectors/TrainingSelector';
import { getClientsPreviousExerciseInRound, updateRound, updateTrainingAction } from '../../core/actions/TrainingActions';
import { useRoute } from '@react-navigation/native';
import { userSelector } from '../../core/selectors/UserSelector';
import AlphabetList from '../components/AlphabetList';


const preparateExercisesData = (exercises) => {
  return exercises.map((item) => {
    return {
      key: item?.id,
      value: `${item.name}`,
      exerciseGroups: (item.exerciseGroups).map((item) =>
       {
        return {
          groupId: item?.id,
          groupName: item.name
        }
      }
      )
  }})
}

const extractExercisesFromTraining = (trainings: Array<ITraining>):IPreviousExercises => {
  if (!trainings) {
    return {
      previous1: [],
      previous2: [],
      previous3: []
    }
  }

  const exercises = {
    previous1: [],
    previous2: [],
    previous3: []
  }

  trainings?.forEach((training, index) => {
    const trainingExercises = []
    training?.rounds?.forEach((round) => {
      round?.roundExerciseInRound?.forEach((exerciseInRound) => {
        trainingExercises.push(exerciseInRound?.exercise?.id);
      })
    })
    if (index == 0) {
      exercises.previous1 = trainingExercises
    } else if (index == 1) {
      exercises.previous2 = trainingExercises
    } else if (index == 2) {
      exercises.previous3 = trainingExercises
    }
  });

  return exercises;
}

const AddExercises = ({theme, navigation}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedChips, setSelectedChips] = useState([]);
  const [isExerciseInfoDialogOpen, setIsExerciseDialogOpen] = useState(false);
  const exercises = useSelector(getExercisesSelector);
  const exerciseGroups = useSelector(getExerciseGroupsSelector);
  const [exercisesData, setExercisesData] = useState([]);
  const [filteredExercisesData, setFilteredExercisesData] = useState([]);
  const [addedExerciseIds, setAddedExerciseIds] = useState([]);
  const [selectedExercises, setSelectedExercises] = useState([]);
  const [currentExercise, setCurrentExercise] = useState(undefined);
  const [isLastExercise, setIsLastExercise] = useState(false);
  const editTraining = useSelector(getCurrentTrainingSelector);
  const newTraining = useSelector(getNewTrainingSelector);
  const [currentTraining, setCurrentTraining] = useState(undefined);
  const [exercisesInRound, setExercisesInRound] = useState(new Array<IExerciseInRound>());
  const [isAllGroupsSelected, setIsAllGroupsSelected] = useState(true);
  const [addedExercisesInRound, setAddedExercisesInRound] = useState(new Array<IExerciseInRound>())
  const editClientsTrainings = useSelector(getClientsPreviousTrainingsSelector);
  const newClientsTrainings = useSelector(getNewTrainingClientsPreviousTrainingsSelector);
  const [previousExercises, setPreviousExercises] = useState(undefined);
  const [currentExerciseInRound, setCurrentExerciseInRound] = useState(undefined);
  const [round, setRound] = useState(undefined);
  const [mode, setMode] = useState('')
  const route = useRoute();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const ITEMS_PER_PAGE = 50;
  const [displayCount, setDisplayCount] = useState(ITEMS_PER_PAGE);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!exercises?.length) {
      if(!user.isIndependentUser) {
        dispatch(getExercises.request(user?.currentFitnessCenter?.id)); //TODO add if check for isShared
      } else {
        dispatch(getExercises.request(null)); //If user is independent then 
      }
    }
    if (!exerciseGroups?.length) {
      dispatch(getExerciseGroups.request());
    }
  }, []);


  useEffect(() => {
    const roundNumber = route.params["roundNumber"];
    const modeValue = route.params['mode'];
    setMode(modeValue);
    if (modeValue == 'create') {
      setCurrentTraining(newTraining);
      const selectedRound = newTraining?.rounds?.find((item) => item?.roundSequenceNumber == roundNumber);
      setRound(selectedRound);
      if (selectedRound?.roundExerciseInRound.length) {
        setExercisesInRound([...selectedRound?.roundExerciseInRound]);
      }
      setPreviousExercises(extractExercisesFromTraining(newClientsTrainings));
    } else {
      setCurrentTraining(editTraining);
      const selectedRound = editTraining?.rounds?.find((item) => item?.roundSequenceNumber == roundNumber);
      setRound(selectedRound);
      if (selectedRound?.roundExerciseInRound.length) {
        setExercisesInRound([...selectedRound?.roundExerciseInRound]);
      }
      setPreviousExercises(extractExercisesFromTraining(editClientsTrainings));
    }
  }, [route?.params]);

  useEffect(() => {
    const formattedExercises = preparateExercisesData(exercises)
    setExercisesData(formattedExercises);
    setFilteredExercisesData(formattedExercises);
  }, [exercises]);

  useEffect(() => {
    const formattedExercises = preparateExercisesData(exercises)
    setExercisesData(formattedExercises);
    setFilteredExercisesData(formattedExercises);
  }, [exercises]);


  const themeStyles = styles(theme)

  const checkIfContained = (newSelectedChips, exercise) => {
    for(let i = 0; i < newSelectedChips.length; i++){
      for(let j = 0; j < exercise.exerciseGroups.length; j++){
        if(newSelectedChips[i] == exercise.exerciseGroups[j].groupId){
          return true;
        }
      }
    }
    return false;
  }

  const toggleChipSelection = (chipId) => {
    let newSelectedChips = [];
    if (selectedChips.includes(chipId)) {
      newSelectedChips = selectedChips.filter((element) => element != chipId);
      setSelectedChips(newSelectedChips);
    } else {
      setIsAllGroupsSelected(false);
      newSelectedChips = [...selectedChips, chipId]
      setSelectedChips(newSelectedChips);
    }
    const newFilteredExercises = exercisesData.filter((exercise) =>
    checkIfContained(newSelectedChips, exercise) && exercise.value.toLowerCase().includes(searchQuery.toLowerCase()));
    setFilteredExercisesData(newFilteredExercises);
  }

  const toggleAllSelected = () => {
    const newIsAllGroupsSelected = !isAllGroupsSelected;
    setIsAllGroupsSelected(newIsAllGroupsSelected);
    if (newIsAllGroupsSelected) {
      const newFilteredExercises  = exercisesData.filter((exercise) => exercise.value.toLowerCase().includes(searchQuery.toLowerCase()));
      setFilteredExercisesData(newFilteredExercises);
      setSelectedChips([]);
    }
    else {
      const newFilteredExercises = exercisesData.filter((exercise) =>
      checkIfContained(selectedChips, exercise) && exercise.value.toLowerCase().includes(searchQuery.toLowerCase()));
      setFilteredExercisesData(newFilteredExercises);
    }
  }

  const isChipSelected = (chipId) => {
    return selectedChips.includes(chipId);
  }

  const closeExerciseDialog = () => {
    setIsExerciseDialogOpen(false);
  }

  const addOrRemoveExercise = (exerciseId) => {
    if (addedExerciseIds.includes(exerciseId)) {
      setAddedExerciseIds(addedExerciseIds.filter((e) => e != exerciseId));
    } else {
      setAddedExerciseIds([...addedExerciseIds, exerciseId]);
    }
  }


  const handleSearchExercises = (query) => {
    const newFilteredExercises = exercisesData.filter((exercise) =>
      (isAllGroupsSelected || checkIfContained(selectedChips, exercise)) && exercise.value.toLowerCase().includes(query.toLowerCase()));
    setFilteredExercisesData(newFilteredExercises);
    setSearchQuery(query);
  }


  const handleSaveExercise = (numberOfRepetition, measurementUnits, note) => {
    const newExerciseInRound: IExerciseInRound = {
      numberOfRepetitions: numberOfRepetition,
      note: note,
      exercise: currentExercise,
      difficulty: measurementUnits,
    };
    let newAddedExercisesInRound;
    if (currentExerciseInRound) {
      newAddedExercisesInRound = addedExercisesInRound?.map((item) => item?.exercise?.id == currentExerciseInRound?.exercise?.id ? newExerciseInRound : item);
    }
    else {
      newAddedExercisesInRound = [...addedExercisesInRound, newExerciseInRound];
    }
    setAddedExercisesInRound(newAddedExercisesInRound);
    if (isLastExercise) {
      setIsExerciseDialogOpen(false);
      if (mode == 'edit') {
        const roundId = round.id;
        const newExercisesInRound = newAddedExercisesInRound;
        dispatch(updateRound.request(roundId, newExercisesInRound))
        navigation.navigate("AddUpdateRound", {roundNumber: round?.roundSequenceNumber, refresh: true, mode: mode});
      } else {
        const newExercisesInRound = [...exercisesInRound, ...newAddedExercisesInRound];
        const newRound = {...round, roundExerciseInRound: newExercisesInRound};
        const newRounds = currentTraining.rounds.map((r) => r?.roundSequenceNumber == round?.roundSequenceNumber ? newRound : r);
        const newTraining = {...currentTraining, rounds: newRounds};
        dispatch(updateTrainingAction(newTraining, 'create'));
        navigation.navigate("AddUpdateRound", {roundNumber: round?.roundSequenceNumber, refresh: true, mode: mode});
      }
    } else {
      // if dialog was previously opened and then closed before save was pressed, pass in entered info
      const nextExercise = selectedExercises[selectedExercises.indexOf(currentExercise) + 1];
      const nextExerciseInRound = addedExercisesInRound.find((item) => item?.exercise?.id == nextExercise?.id);
      setCurrentExerciseInRound(nextExerciseInRound);
      setIsLastExercise(selectedExercises.indexOf(nextExercise) == selectedExercises.length - 1)
      setCurrentExercise(nextExercise);

    }
  }

  const openExercisesInfoDialog = () => {
    const filteredSelectedExercises = [];
    addedExerciseIds.forEach((id) => {
      for (let i=0; i<exercises.length; i++) {
        if (exercises[i].id == id) {
          filteredSelectedExercises.push(exercises[i]);
          break;
        }
      }
    })
    setSelectedExercises(filteredSelectedExercises);
    setIsLastExercise(filteredSelectedExercises?.length == 1);
    if (filteredSelectedExercises?.length) {
      const firstExercise = filteredSelectedExercises[0];
      const nextExerciseInRound = addedExercisesInRound.find((item) => item?.exercise?.id == firstExercise?.id)
      setCurrentExerciseInRound(nextExerciseInRound);
      setCurrentExercise(firstExercise);
      dispatch(getClientsPreviousExerciseInRound.request(currentTraining?.client?.id, firstExercise?.id));
      setIsExerciseDialogOpen(true);
    }
  }

  const groupNames = (item) => {
    return item.exerciseGroups.map(group => group.groupName).join(", ");
  };


  const renderListItem = (item: any) => {
    return (
      <View style={themeStyles.itemContainer}>
        <View style={themeStyles.itemExerciseContainer}>
          <TouchableOpacity style={themeStyles.exerciseContainer} onPress={() => addOrRemoveExercise(item?.key)}>
            <View style={themeStyles.exerciseTexts}>
              <Text style={themeStyles.itemExerciseText}>{item.value}</Text>
              <Text style={themeStyles.itemExerciseGroupText}>{groupNames(item)}</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity style={themeStyles.selectedContainer} onPress={() => addOrRemoveExercise(item?.key)}>
            <View style={themeStyles.exclamationSign}>
              {previousExercises?.previous1?.includes(item?.key) ? (
                <FontAwesomeIcon size={20} color={theme.colors.redMarker} icon={faTriangleExclamation} />
              ) : previousExercises?.previous2?.includes(item?.key) ? (
                <FontAwesomeIcon size={20} color={theme.colors.orangeMarker} icon={faTriangleExclamation} />
              ) : previousExercises?.previous3.includes(item?.key) ?
                <FontAwesomeIcon size={20} color={theme.colors.yellowMarker} icon={faTriangleExclamation} />
              : null
              }
              </View>
            {addedExerciseIds.includes(item?.key) ? (
              <FontAwesomeIcon size={20} color={theme.colors.tertiaryBackground} icon={faCheckCircle}/>
              ) :
              <IconInCircle borderColor={theme.colors.primaryText} circleSize={20}/>
            }
          </TouchableOpacity>
        </View>
      </View >
    );
  };

  const onScroll = ({ nativeEvent }) => {
    if (isCloseToBottom(nativeEvent) && displayCount < filteredExercisesData.length) {
      setDisplayCount(currentCount => currentCount + ITEMS_PER_PAGE);
      setIsLoading(true);
      setTimeout(() => {
        // Here you would load or render your items
        setIsLoading(false);
      }, 500); // 300 milliseconds dela
    }
  };

  const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
    const paddingToBottom = 300;
    return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
  };


  const renderHeader = () => {
    return (
      <View>
        <ScreenHeader buttonText='Dalje' onButtonPressed={openExercisesInfoDialog}/>
        <View style={themeStyles.inputContainer}>
          <IconTextInput
            label="Pretraga"
            value={searchQuery}
            onChangeText={(text) => handleSearchExercises(text)}
            icon={'magnify'}
          />
        </View>
        <View style={themeStyles.chipsContainer}>
          <Chip key={'all'} selected={isAllGroupsSelected} style={themeStyles.chip}
              compact onPress={toggleAllSelected}>
              Sve
          </Chip>
          {exerciseGroups?.map((group) => (
            <Chip key={group.id} selected={isChipSelected(group.id)} style={themeStyles.chip}
                compact onPress={() => toggleChipSelection(group.id)}>
              {group.name}
            </Chip>
          ))}
        </View>
        <ExerciseInfoDialog
          exercise={currentExercise}
          exerciseInRound={currentExerciseInRound}
          previousExercises={previousExercises}
          isDialogVisible={isExerciseInfoDialogOpen}
          isLastExercise={isLastExercise}
          onDismiss={closeExerciseDialog}
          onSave={handleSaveExercise}
          currentTraining={currentTraining}
        />
      </View>
    );
  };

  return (
  <View style={themeStyles.root}>
    <ScrollView onScroll={onScroll} style={themeStyles.container} scrollEventThrottle={16}>
      {renderHeader()}
      <AlphabetList
        items={filteredExercisesData}
        renderListItem={renderListItem}
        displayCount={displayCount}
        />
    </ScrollView>
      {isLoading && (
        <View style={themeStyles.loadingContainer}>
          <ActivityIndicator size="large" />
        </View>
      )}
    </View>
  );
};

const styles = theme => StyleSheet.create({
  inputContainer: {
    margin: 10,
  },
  root: {
    flex: 1,
    backgroundColor: theme.colors.lightText,
  },
  exclamationSign: {
    marginRight: 10,
  },
  containerStyle: {
    padding: 20,
  },
  container: {
    flex: 1,
    backgroundColor: theme.colors.lightText,
  },
  loadingContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
    height: 50, // Adjust the height as needed
    backgroundColor: 'rgba(0, 0, 0, 0.2)', // Semi-transparent background
    zIndex: 1, // Ensure it's on top
  },
  chipsContainer: {
    flexDirection: 'row',
    margin: 10,
    flexWrap: 'wrap',
  },
  chip: {
    margin: 5,
    backgroundColor: theme.colors.quaternaryBackground,
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.secondaryFont,
    borderColor: theme.colors.primaryText,
    borderWidth: 1,
    borderRadius: 20,
  },
  modalContent: {
    borderRadius: 10,
    overflow: 'hidden',
  },
  mainContent: {
    flexDirection: 'column',
    backgroundColor: theme.colors.lightText,
  },
  alphabetList: {
    flex: 1,
  },
  sectionHeader: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    marginTop: 20,
  },
  sectionHeaderText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 16,
    fontWeight: '600',
  },

  itemContainer: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 0,
    marginTop: 10,
  },
  itemExerciseText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 20,
    fontWeight: '600',
    marginBottom: 5,
  },
  itemExerciseGroupText: {
    color: theme.colors.secondaryText,
    fontFamily: theme.fontFamilies.secondaryFont,
    fontSize: 12,
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  itemExerciseContainer: {
    borderTopColor: theme.colors.primaryText,
    borderTopWidth: 1,
    paddingTop: 10,
    paddingBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  exerciseTexts: {
    marginRight: 5,
    numberOfLines: 3,
    flex: 1,
  },
  exerciseContainer: {
    flex: 6,
    flexDirection: 'row',
  },
  selectedContainer: {
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  indexLetters: {
    color: theme.colors.tertiaryBackground,
    fontSize: 12,
    margin: 10
  }
});



export default withTheme(AddExercises);
