import { useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { withTheme } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { createClient, updateClient } from '../../core/actions/ClientActions';
import BottomMenu from '../components/BottomMenu';
import CreateEditClient from './CreateEditClientInfo';
import { userSelector } from '../../core/selectors/UserSelector';


const EditClientContainer = ({theme, navigation}) => {

  const themeStyles = styles(theme);
  const dispatch = useDispatch();
  const [currentClient, setCurrentClient] = useState(undefined);
  const route = useRoute();
  const user = useSelector(userSelector);

  // TODO do not pass in client object, pass in just id and then load from the store
  useEffect(() => {
    const client = route?.params["client"];
    setCurrentClient(client);
  }, [route?.params]);

  const onSaveClient = (updatedClient) => {
    const client = {...updatedClient, id: currentClient?.id}
    dispatch(updateClient.request(client));
    navigation.navigate('ClientView', {'clientId': currentClient?.id});
  }

  const onCancel = () => {
    navigation.navigate('ClientView', {'clientId': currentClient?.id});
  }


  return (
    <View style={themeStyles.root}>
      <ScrollView style={themeStyles.mainContent}>
        <CreateEditClient client={currentClient} onSavePressed={onSaveClient} onCancelPressed={onCancel}/>
      </ScrollView>
      <BottomMenu navigation={navigation} currentScreen='Clients'/>
    </View>
  )
}

const styles = theme => StyleSheet.create({
  mainContent: {
    backgroundColor: '#ffffff',
    margin: 20,
  },
  root: {
    flex: 1
  }
});

export default withTheme(EditClientContainer);

