import { AppState } from '../reducers/RootReducer';
import { createSelector } from 'reselect';
import { IClient } from '../models/Models';

const getClients = (state: AppState): Array<IClient> => state.clients.clients;

export const getClientsSelector = createSelector(getClients, (clients) => clients);

const getClientById = (state: AppState, clientId: number): IClient =>
  state?.clients?.clients?.find((item) => item?.id == clientId);

export const getClientByIdSelector = (clientId: number) =>
  createSelector(
    (state: AppState) => getClientById(state, clientId),
    (clients) => clients
  );