import * as Alert from '../../utils/Alerts';
import axios from "axios";
import { all, put, takeLatest } from 'redux-saga/effects';
import { getClients as getClientsQuery } from '../graphql/queries';
import {
  deleteClient as deleteClientMutation,
  createClient as createClientMutation,
  updateClient as updateClientMutation,
} from '../graphql/clientMutations';
import { deleteClient, CLIENT, CLIENTS, getClients, createClient, updateClient } from '../actions/ClientActions';
import { graphQLEndpoint } from '../settings';
import { getHeaders } from './Helper';

export function* getClientsSaga({ payload: { fitnessCenterId } }) {
  try {
    const query = getClientsQuery(fitnessCenterId);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();
    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.errors) {
      throw new Error(response?.errors);
    }
    
    yield put(getClients.success(response?.data?.data?.filterClientsByFitnessCenter));
  } catch (e) {
    yield put(getClients.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri učitavanju klijenata");
  }
}

export function *deleteClientSaga({ payload: { id } }) {
  try {
    const query = deleteClientMutation(id);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();
    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.data?.errors[0]?.message);
    }

    yield put(deleteClient.success(id));
    yield Alert.setSuccessAlert("Klijent je uspešno izbrisana");
  } catch (e) {
    console.log(e)
    yield put(deleteClient.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri brisanju klijenta");
  }
}

export function *createClientSaga({ payload: { data } }) {
  try {
    const query = createClientMutation(data);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();

    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.data?.errors[0]?.message);
    }

    yield put(createClient.success(response?.data?.data?.createClient?.client));
    yield Alert.setSuccessAlert("Klijent je uspešno kreiran");
  } catch (e) {
    console.log(e)
    yield put(createClient.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri kreiranju klijenta");
  }
}

export function *updateClientSaga({ payload: { data } }) {
  try {
    const query = updateClientMutation(data);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();

    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.data?.errors[0]?.message);
    }

    yield put(updateClient.success(response?.data?.data?.updateClient?.client));
    yield Alert.setSuccessAlert("Klijent je uspešno izmenjen");
  } catch (e) {
    console.log(e)
    yield put(updateClient.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri izmeni klijenta");
  }
}

function* clientSaga() {
  yield all([
    takeLatest(CLIENTS.GET.REQUEST, getClientsSaga),
    takeLatest(CLIENT.POST.REQUEST, createClientSaga),
    takeLatest(CLIENT.DELETE.REQUEST, deleteClientSaga),
    takeLatest(CLIENT.PUT.REQUEST, updateClientSaga)
  ])
}
  
export default clientSaga;
