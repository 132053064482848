import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { Button, withTheme } from 'react-native-paper';
import IconButton from './IconButton';


interface IHeaderProps {
  theme: any;
  text: string;
  buttonIcon?: any;
  buttonText?: string;
  onButtonPressed?: () => void;
}

interface ISectionHeaderProps {
  theme: any;
  buttonText: string;
  onButtonPressed?: () => void;
}


const SectionHeaderComponent = ({theme, text, buttonIcon, buttonText, onButtonPressed}: IHeaderProps) => {
  const themeStyles = styles(theme);
  return (
    <View style={themeStyles.sectionHeaderContainer}>
      <Text style={themeStyles.sectionHeader}>{text}</Text>
      {buttonIcon &&
        <View style={themeStyles.sectionButtonContainer}>
          <IconButton icon={buttonIcon} onPress={onButtonPressed}/>
        </View>
      }
      {buttonText && (
        <Button style={themeStyles.headerButton} onPress={onButtonPressed}>
          <Text style={themeStyles.sectionHeader}>{buttonText}</Text>
        </Button>
      )}
    </View>
    )
}


const ScreenHeaderComponent = ({theme, buttonText, onButtonPressed}: ISectionHeaderProps) => {
  const themeStyles = styles(theme);

  return (
    <View style={themeStyles.headerContainer}>
      <Button style={themeStyles.saveButton} onPress={onButtonPressed}>
        <Text style={themeStyles.hederSaveText}>{buttonText}</Text>
      </Button>
    </View>
  )
}


const styles = theme => StyleSheet.create({
  sectionHeader: {
    fontFamily: theme.fontFamilies.primaryFont,
    color: theme.colors.primaryText,
    fontSize: 20,
    fontWeight: '600',
    marginBottom: 10,
  },
  sectionHeaderContainer: {
    marginTop: 20,
    marginBottom: 20,
    width: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerText: {
    fontFamily: theme.fontFamilies.primaryFont,
    color: theme.colors.primaryText,
    fontSize: 20,
    textAlign: 'center'
  },
  hederSaveText: {
    fontFamily: theme.fontFamilies.secondaryFont,
    color: theme.colors.primaryText,
    fontSize: 20,
    textAlign: 'right'
  },
  headerContainer: {
    alignItems: 'flex-end',
    marginBottom: 5,
  },
  saveButton: {
    marginTop: 5,
    marginLeft: 'auto'
  },
  sectionButtonContainer: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerButton: {
    marginTop: -6,
  },
})

export const SectionHeader = withTheme(SectionHeaderComponent);
export const ScreenHeader = withTheme(ScreenHeaderComponent);
