import { action, createRequestTypes } from '../../utils/ReduxHelpers';

import { DELETE, GET, POST, PUT } from '../../utils/Constants';
import { IExerciseGroup } from '../models/Models';

export const EXERCISE_GROUPS = createRequestTypes('EXERCISE_GROUPS', [GET]);
export const EXERCISE_GROUP = createRequestTypes('EXERCISE_GROUP', [GET, POST, PUT, DELETE]);

export const getExerciseGroups = {
  request: () => action(EXERCISE_GROUPS.GET.REQUEST),
  success: (payload: Array<IExerciseGroup>) => action(EXERCISE_GROUPS.GET.SUCCESS, payload),
  failure: (error: string) => action(EXERCISE_GROUPS.GET.FAILURE, error)
};

export const getExerciseGroup = {
  request: () => action(EXERCISE_GROUP.GET.REQUEST),
  success: (payload: IExerciseGroup) => action(EXERCISE_GROUP.GET.SUCCESS, payload),
  failure: (error: string) => action(EXERCISE_GROUP.GET.FAILURE, error)
}

export const createExerciseGroup = {
  request: (data: IExerciseGroup) => action(EXERCISE_GROUP.POST.REQUEST, { data }),
  success: (payload: any) => action(EXERCISE_GROUP.POST.SUCCESS, payload),
  failure: (error: string) => action(EXERCISE_GROUP.POST.FAILURE, error)
};

export const updateExerciseGroup = {
  request: (data: any) => action(EXERCISE_GROUP.PUT.REQUEST, { data }),
  success: (payload: any) => action(EXERCISE_GROUP.PUT.SUCCESS, payload),
  failure: (error: string) => action(EXERCISE_GROUP.PUT.FAILURE, error)
};

export const deleteExerciseGroup = {
  request: (id: number) => action(EXERCISE_GROUP.DELETE.REQUEST, { id }),
  success: (payload: any) => action(EXERCISE_GROUP.DELETE.SUCCESS, payload),
  failure: (error: string) => action(EXERCISE_GROUP.DELETE.FAILURE, error)
};

