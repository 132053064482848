import React from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { withTheme } from 'react-native-paper';
import { useDispatch } from 'react-redux';
import BottomMenu from '../components/BottomMenu';
import CreateEditExerciseGroupInfo from './CreateEditExerciseGroupInfo';
import { createExerciseGroup } from '../../core/actions/ExerciseGroupActions';


const CreateExerciseContainer = ({theme, navigation}) => {

  const themeStyles = styles(theme);
  const dispatch = useDispatch();


  const onSaveExerciseGroup = (exerciseGroup) => {
    dispatch(createExerciseGroup.request(exerciseGroup));
    navigation.navigate('AppSettings');
  }

  const onCancel = () => {
    navigation.navigate('AppSettings');
  }


  return (
    <View style={themeStyles.root}>
      <ScrollView style={themeStyles.mainContent}>
        <CreateEditExerciseGroupInfo onSavePressed={onSaveExerciseGroup} onCancelPressed={onCancel}/>
      </ScrollView>
      <BottomMenu navigation={navigation} currentScreen='Exercises'/>
    </View>
  )
}

const styles = theme => StyleSheet.create({
  mainContent: {
    backgroundColor: '#ffffff',
    margin: 20,
  },
  root: {
    flex: 1
  }
});

export default withTheme(CreateExerciseContainer);

