import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Switch, ScrollView, Pressable } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { getUserSettingsSelector } from '../core/selectors/UserSettingsSelector';
import { Text, withTheme } from 'react-native-paper';
import { LOGOUT, logout } from '../core/actions/AuthActions';
import { fitnessCenterUsersSelector, userSelector, usersInFitnessCenterSelector } from '../core/selectors/UserSelector';
import IconButton from './components/IconButton';
import { faArrowCircleRight, faArrowRightFromBracket, faBuilding, faCog, faPenToSquare, faBuildingCircleArrowRight, faPersonWalking, faPlus, faScrewdriverWrench, faUserPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import AccordionItem from './components/AccordionItem';
import { IUser } from '../core/models/Models';
import FitnessCenterSelectionDialog from './components/settings/FitnessCenterSelectionDialog';
import { findUsersByFitnessCenter, setUsersFitnessCenter } from '../core/actions/UserActions';
import AddUserToCenterDialog from './components/settings/AddUserToCenterDialog';

import { changePassword } from './../core/actions/AuthActions';
import UserSettings  from '../screens/user/UserSettings';
import { getFitnessCenterSelector } from '../core/selectors/FitnessCenterSelector';
import UserList from './components/settings/UserList';

const isUserAdmin = (user: IUser) => {
  const fitnessCenter = user?.currentFitnessCenter;
  if (!fitnessCenter) {
    return false;

  }
  const centerRole = user?.userFitnessCenterList.find((centerRole) => centerRole?.fitnessCenter?.id == fitnessCenter?.id);
  return centerRole?.authorities == 'ADMIN';
}

const AppSettings = ({theme, navigation}) => {

  const dispatch = useDispatch();
  const userSettings = useSelector(getUserSettingsSelector);
  const user = useSelector(userSelector);
  //const fitnessCenterUsers = useSelector(fitnessCenterUsersSelector);
  const usersInFitnessCenter = useSelector(usersInFitnessCenterSelector);
  const [isCenterSelectionDialogVisible, setIsCenterSelectionDialogVisible] = useState(false);
  const [isAddUserDialogVisible, setIsAddUserDialogVisible] = useState(false);
  const [isDeleteUserDialogVisible, setIsDeleteUserDialogVisible] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const fitnessCenterUpdated = useSelector(getFitnessCenterSelector);
  //Change password constants
  const [password, setPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  //Change password constants end

  const handleCenterSelected = (studioId) => {
    const studio = user?.userFitnessCenterList?.find((userCenter) => userCenter?.fitnessCenter?.id == studioId)
    dispatch(setUsersFitnessCenter(studio?.fitnessCenter));
    setIsCenterSelectionDialogVisible(false);
  }

  const handleAddUser = (addedUser) => {
    console.log('Add user to center')
  }


  useEffect(() => {
    setIsAdmin(isUserAdmin(user));
  }, [user?.currentFitnessCenter]);

  const option = (icon, action, text) => {
    return (
      <View style={themeStyles.optionContainer}>
        <IconButton icon={icon} onPress={action} iconSize={20} />
        <Pressable onPress={action}>
          <Text style={themeStyles.optionText}>{text}</Text>
        </Pressable>
      </View>
    )
  }

  useEffect(() => {
    {user.currentFitnessCenter &&
      dispatch(findUsersByFitnessCenter.request(user.currentFitnessCenter.id));
      console.log("Ucitani svi korisnici za studio");
      console.log(usersInFitnessCenter);
    }
  },[]);

  const openCreateExerciseGroup = () => {
    navigation.navigate('ExerciseGroupsContainer')
  }

  const openCreateFitnessCenter = () => {
    navigation.navigate('CreateFitnessCenterContainer')
  }

  const handleLogOut = () => {
    dispatch(logout());
    navigation.navigate('LoginScreen')
  }

  //const changePassword = () => {
  //  console.log('Change password');
  //  navigation.navigate('UserSettings')
  //}

  const openAdminScreen = () => {
    console.log('Open admin page');
  }

  const openSettingsScreen = () => {
    console.log('Open settings page');
  }

  const handleChooseCurrentFitnessCenter = () => {
    setIsCenterSelectionDialogVisible(true);
  }

  const openAddFitnessCenterScreen = () => {
    console.log('Create fitness center')
    navigation.navigate('CreateFitnessCenterContainer')
  }

  const openUpdateFitnessCenterScreen = () => {
    console.log('Change fitness center')
    navigation.navigate('UpdateFitnessCenterContainer')
  }

  const openAddUser = () => {
    setIsAddUserDialogVisible(true);
  }

  const openDeleteUser = () => {
    console.log("Delete user from fitness center.");
    setIsDeleteUserDialogVisible(true);
  }

  //Change password section
  
  //Change password section end
  const themeStyles = styles(theme);

  return (
    <View style={themeStyles.root}>
      <ScrollView style={themeStyles.mainContent}>
        <View style={themeStyles.usereaderContainer}>
          <View style={themeStyles.userAvatarContainer}>
            <View style={themeStyles.userAvatar}>
              <Text style={themeStyles.userAvatarText}>{user?.personalName[0]}{user?.familyName[0]}</Text>
            </View>
          </View>
          <View style={themeStyles.userInfo}>
            <Text style={themeStyles.userNameText}>{user?.personalName} {user?.familyName}</Text>
            <View style={themeStyles.userInfoData}>
              {/* studio ovde */}
              <Text style={themeStyles.userInfoDataText}>{fitnessCenterUpdated?.name || user?.currentFitnessCenter?.name ||''}</Text>
            </View>
          </View>
        </View>
        <View style={themeStyles.optionsContainer}>
          <AccordionItem title="Fitnes centar">
            {user?.currentFitnessCenter?.name != null &&
              option(faBuilding, handleChooseCurrentFitnessCenter, 'Izbor fitnes centra')
            }
            {isUserAdmin(user) &&//|| user?.currentFitnessCenter?.name != null && //TODO
              option(faUserPlus, openAddUser, 'Dodaj korisnika u fitnes centar')
            }
            {isUserAdmin(user) &&//|| user?.currentFitnessCenter?.name != null && //TODO
              option(faTrash, openDeleteUser, 'Obrisi korisnika iz fitnes centra')
            }
            {user?.currentFitnessCenter?.name == null && option(faPlus, openAddFitnessCenterScreen, 'Kreiraj fitnes centar')}
            {isUserAdmin(user) && user?.currentFitnessCenter?.name != null && option(faPenToSquare, openUpdateFitnessCenterScreen, 'Izmeni fitnes centar')}
          </AccordionItem>
          {/* <AccordionItem title="Podešavanja">
            {option(faCog, openSettingsScreen, 'Podešavanja')}
          </AccordionItem> */}
          {user.authorities == "superuser" &&
            <AccordionItem title="Administracija">
              {/* {option(faScrewdriverWrench, openAdminScreen, 'Administracija')} */}
              {option(faPersonWalking, openCreateExerciseGroup, 'Grupe vežbi')}
              {/*option(faBuildingCircleArrowRight, openUpdateFitnessCenterScreen, 'Kreiraj novi fitnes centar')*/}
            </AccordionItem>
          }
          <AccordionItem title="Izlogujte se">
            {option(faArrowRightFromBracket, handleLogOut, 'Izlogujte se')}
          </AccordionItem>
          <AccordionItem title="Lična podešavanja">
            {option(faScrewdriverWrench, changePassword, 'Promeni lozinku')}
            {/* <View style={themeStyles.root}>
              <UserSettings
              navigation={Navigate}
              password={password}
              repeatPassword={repeatPassword}
              oldPassword={oldPassword}
              setPassword={(text) => setPassword(text)}
              setRepeatPassword={(text) => setRepeatPassword(text)}
              setOldPassword={(text) => setOldPassword(text)}
              />
            </View> */}
          </AccordionItem>
        </View>
      </ScrollView>
      <FitnessCenterSelectionDialog
        isDialogVisible={isCenterSelectionDialogVisible}
        onCenterSelected={handleCenterSelected}
        user={user}
        onDismiss={() => setIsCenterSelectionDialogVisible(false)}
      />
      <AddUserToCenterDialog
        isDialogVisible={isAddUserDialogVisible}
        onUserSelected={handleAddUser}
        user={user}
        onDismiss={() => setIsAddUserDialogVisible(false)}
      />
      <UserList
       isDialogVisible={isDeleteUserDialogVisible}
       onUserSelected={handleAddUser}
       onDismiss={() => setIsDeleteUserDialogVisible(false)}
      />
    </View>
  )
}


const styles = theme => StyleSheet.create({
  mainContent: {
    backgroundColor: '#ffffff',
    margin: 20,
  },
  root: {
    flex: 1
  },
  signUpButton: {
    backgroundColor: theme.colors.tertiaryBackground,
    fontFamily: theme.fontFamilies.primaryFont,
  },
  optionsContainer: {
    marginTop: 40,
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  optionText: {
    fontFamily: theme.fontFamilies.primaryFont,
    color: theme.colors.primaryText,
    fontSize: 18,
  },
  optionContainer: {
    flexDirection: 'row',
    marginBottom: 20,
  },
  usereaderContainer: {
    marginTop: 30,
    flexDirection: 'row',
  },
  userAvatarContainer: {

  },
  userAvatarText: {
    fontFamily: theme.fontFamilies.primaryFont,
    color: theme.colors.lightText,
    fontSize: 50,
  },
  userNameText: {
    fontFamily: theme.fontFamilies.primaryFont,
    color: theme.colors.primaryText,
    fontSize: 20,
    fontWeight: '600',
    textAlign: 'center',
  },
  userAvatar: {
    width: 110,
    height: 110,
    borderRadius: 110/2,
    backgroundColor: theme.colors.tertiaryBackground,
    alignItems: 'center',
    justifyContent: 'center',
  },
  userInfo: {
    flexGrow: 1
  },
  userInfoData: {
    marginTop: 10,
    flexDirection: 'row',
  },
  userInfoDataText: {
    fontFamily: theme.fontFamilies.secondaryFont,
    color: theme.colors.secondaryText,
    fontSize: 16,
    fontWeight: '400',
    flex: 2,
    textAlign: 'center',
    marginTop: 3,
  },
  editButton: {
    backgroundColor: theme.colors.primaryText,
    color: theme.colors.lightText,
    fontFamily: theme.fontFamilies.primaryFont,
    width: 100,
    alignSelf: 'center',
    margin: 20,
  },
  trainingsContainer: {

  },
  loadMoreButton: {
    backgroundColor: theme.colors.primaryText,
    color: theme.colors.lightText,
    fontFamily: theme.fontFamilies.primaryFont,
    width: 120,
    alignSelf: 'center',
    margin: 20,
  },
  buttonsContainer: {
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  viewContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 10,
  },
  viewModeText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 15,
    fontWeight: '600',
    marginBottom: 5,
    marginRight: 5,
  },
  editButtonContainer: {
    alignItems: 'flex-end',
    marginTop: 10,
    marginBottom: 10,
  },
  inputContainer: {
    marginBottom: 10,
    marginRight: 15
  },
  buttonContainer: {
    marginTop: 5,
    marginBottom: 20,
  },
});


export default withTheme(AppSettings);