import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text, SafeAreaView, TouchableOpacity, ScrollView } from 'react-native';
import { Portal, withTheme } from 'react-native-paper';
import { IconTextInput } from '../InputComponents';
import { IClient } from '../../../core/models/Models';
import Modal from 'react-native-modal/dist/modal';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../IconButton';
import AlphabetList from '../AlphabetList';


interface Props {
  theme: object;
  clients: IClient[];
  onClientSelected: (any) => void;
  onDismiss: () => void;
  isDialogVisible: boolean;
}


const prepareClientsData = (clients) => {
  return clients.map((item) => {
    return {
      key: item?.id,
      value: `${item.name} ${item.familyName}`
  }})
}


const ClientsDialog = ({theme, clients, isDialogVisible, onClientSelected, onDismiss}: Props) => {
  const [searchQuery, setSearchQuery] = useState('')
  const clientsData = prepareClientsData(clients);
  const themeStyles = styles(theme);
  const [filteredData, setFilteredData] = useState();


  useEffect(() => {
    const data = prepareClientsData(clients);
    setFilteredData(data);
  }, [clients]);


  const handleSearchClients = (query) => {
    const newClientsData = clientsData.filter((item) => item.value.toLowerCase().includes(query.toLowerCase()));
    setFilteredData(newClientsData);
    setSearchQuery(query);
  }


  const handleHideDialog = () => {
    console.log("zatvori")
    onDismiss();
  }

  const handleOnClientSelected = (item) => {
    console.log(item);
    const client = clients.find((c) => c.id === item.key);
    onClientSelected(client);
    console.log(client);
    onDismiss();
  }


  const renderListItem = (item: any) => {
    return (
      <View style={themeStyles.itemContainer}>
        <View style={themeStyles.itemExerciseContainer}>
        <TouchableOpacity style={themeStyles.exerciseContainer} onPress={() => handleOnClientSelected(item)}>
          <View style={themeStyles.exerciseTexts}>
            <Text style={themeStyles.itemExerciseText}>{item?.value}</Text>
          </View>
        </TouchableOpacity>
        </View>
      </View >
    );
  };


  const renderHeader = () => {
    return (
      <View>
        <View style={themeStyles.inputContainer}>
          <IconTextInput
            label="Pretraga"
            value={searchQuery}
            onChangeText={(text) => handleSearchClients(text)}
            icon={'magnify'}
            autoFocus = {true}
          />
        </View>
      </View>
    );
  };

  return (
    <Modal isVisible={isDialogVisible} onDismiss={onDismiss}>
      <View style={themeStyles.container}>
        <View style={[themeStyles.header, themeStyles.regularBackground]}>
          <View style={themeStyles.buttonHeaderContainer}>
            <IconButton width={10} icon={faXmark} iconSize={20} onPress={onDismiss}/>
          </View>
          <View style={themeStyles.headerTextContainer}>
            <Text style={themeStyles.headerText}>Izbor klijenta</Text>
          </View>
        </View>
        <ScrollView>
          {renderHeader()}
          <AlphabetList
            items={filteredData}
            renderListItem={renderListItem}
            displayCount={filteredData?.length || 0}
          />
        </ScrollView>
      </View>
    </Modal>
  );
};

const styles = theme => StyleSheet.create({
  inputContainer: {
    margin: 10,
  },
  containerStyle: {
    padding: 20,
    margin: 0, // This is the important style you need to set
    alignItems: undefined,
    justifyContent: undefined,
  },
  container: {
    flex: 1,
    backgroundColor: theme.colors.lightText,
    borderRadius: 10,
    overflow: 'hidden',
  },
  chipsContainer: {
    flexDirection: 'row',
    margin: 10,
  },
  modalContent: {
    borderRadius: 10,
    overflow: 'hidden',
  },
  mainContent: {
    flexDirection: 'column',
    backgroundColor: theme.colors.lightText,
  },
  alphabetList: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    height: 70,
    width: '100%',
    borderBottomColor: theme.colors.primaryText,
    borderBottomWidth: 1,
  },
  headerTextContainer: {
    flexGrow: 1,
    alignSelf: 'center',
    marginLeft: -20,
  },
  headerText: {
    color: theme.colors.primaryText,
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 20,
    fontWeight: '600',
  },
  regularBackground: {
    backgroundColor: theme.colors.tertiaryBackground,
  },
  buttonHeaderContainer: {
    alignSelf: 'center',
    width: 50,
  },
  sectionHeader: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    marginTop: 20,
  },
  itemExerciseContainer: {
    borderTopColor: theme.colors.primaryText,
    borderTopWidth: 1,
    paddingTop: 10,
    paddingBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  sectionHeaderText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 16,
    fontWeight: '600',
  },

  itemContainer: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 0,
    marginTop: 10,
  },
  itemExerciseText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 20,
    fontWeight: '600',
    marginBottom: 5,
  },
  itemExerciseGroupText: {
    color: theme.colors.secondaryText,
    fontFamily: theme.fontFamilies.secondaryFont,
    fontSize: 12,
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  exerciseTexts: {
    marginRight: 5,
  },
  exerciseContainer: {
    flex: 6,
    flexDirection: 'row',
  },
  selectedContainer: {
    alignItems: 'flex-end',
    flex: 6,
  },
  indexLetters: {
    color: theme.colors.tertiaryBackground,
    fontSize: 12,
    margin: 10
  }
});



export default withTheme(ClientsDialog);

