import { formatDateTimeDatabase } from "../../utils/utils";
import { IExerciseInRound, IRound } from "../models/Models";
import { stringify } from "./utils";
import { trainingsData } from "./queries";
import { current } from "immer";


//${training?.trainingStatus}
export function createTraining(training) {
  let client = -1;
  if(training?.client){
     client = training.client.id;
  }
  const formattedRounds = training.rounds.map((round: IRound) => {
    const exercisesInRound = round?.roundExerciseInRound.map((exerciseInRound: IExerciseInRound) => {
      return {
        exerciseId: exerciseInRound.exercise.id,
        numberOfRepetitions: `${exerciseInRound.numberOfRepetitions}`,
        difficulty: exerciseInRound.difficulty,
        note: exerciseInRound.note
      }
    });
    return {
      id: 1,
      roundSequenceNumber: round.roundSequenceNumber,
      exercisesInRound: exercisesInRound
    }
  });
  return `mutation {
      createTraining (
        numberOfTrainings: ${training.numberOfTrainings}
        clientId: ${client}
        date: "${formatDateTimeDatabase(training.date)}"
        rounds: ${stringify(formattedRounds)}
        status: ${JSON.stringify(training.status)}
        trainingCreatorId: ${training?.trainingCreator?.id}
        trainingExecutorId: ${training?.trainingExecutor?.id}
        pdfURL: "${training?.pdfURL}"
    ) {
      message {
        isRequestSuccessful
        message
        id
      }
      training ${trainingsData}
    }
  }`;
}


export function updateTraining(training) {
  let client = -1;
  let executor = training?.trainingExecutor?.id
  if(training?.client){
     client = training.client.id;
  }
  if(client == -1){
    executor = training?.trainingCreator?.id
  }
    return `mutation {
       updateTraining (
        id: ${training.id}
        numberOfTrainings: ${training.numberOfTrainings}
        clientId: ${client}
        date: "${formatDateTimeDatabase(training.date)}"
        status: ${JSON.stringify(training.status)}
        trainingCreatorId: ${training?.trainingCreator?.id}
        trainingExecutorId: ${executor}
        pdfURL: "${training?.pdfURL}"
        rounds: []
      ) {
        message {
          isRequestSuccessful
          message
          id
        }
        training ${trainingsData}
      }
    }`;
  }

export function deleteTraining(id){
  return `mutation {
      deleteTraining(
        id: ${id}
      ) {
        message
      }
  }`;
}

export function createRound(round, trainingId) {
  return `mutation {
    createRound (
      idTraining: ${trainingId},
      round: {
        roundSequenceNumber: ${round.roundSequenceNumber},
        exercisesInRound: []
      }
    ) {
      isRequestSuccessful
      message
      id
    }
  }`;
}


export function updateRound(roundId, newExercisesInRound) {
  const exercisesInRound = newExercisesInRound.map((exerciseInRound: IExerciseInRound) => {
    return {
      exerciseId: exerciseInRound.exercise.id,
      numberOfRepetitions: `${exerciseInRound.numberOfRepetitions}`,
      difficulty: exerciseInRound.difficulty,
      note: exerciseInRound.note
    }
  })
  return `mutation {
    updateRound (
      id: ${roundId}
      exercisesInRound: ${stringify(exercisesInRound)}
    ) {
      message {
        isRequestSuccessful
        message
        id
      }
      round {
        id
        roundSequenceNumber
        roundExerciseInRound {
          id: ExecInRound_Id
          numberOfRepetitions
          difficulty
          note
          exercise {
            id
            name
            exerciseGroups {
              name
            }
        }
      }
    }
  }
}`;
}

export function deleteRound(roundId) {
  return `mutation {
    deleteRound (
      id: ${roundId}
    ) {
        isRequestSuccessful
        message
    }
  }`;
}

export function updateExerciseInRound(exerciseInRound) {
  return `mutation {
    updateExerciseInRound (
      exerciseInRoundInput: {
        id: ${exerciseInRound.id}
        exerciseId: ${exerciseInRound.exercise?.id}
        numberOfRepetitions: "${exerciseInRound.numberOfRepetitions}"
        difficulty: "${exerciseInRound.difficulty}"
        note: "${exerciseInRound.note || ''}"
      }
    ) {
        isRequestSuccessful
        message
    }
  }`;
}



export function deleteExerciseInRound(exerciseInRoundId) {
  return `mutation {
    deleteExerciseInRound (
      id: ${exerciseInRoundId}
    ) {
        isRequestSuccessful
        message
        id
    }
  }`;
}


export function swapExercisesInRound(exerciseInRoundId1, exerciseInRoundId2) {
  return `mutation {
    swapExercisesInRound (
      id1: ${exerciseInRoundId1}
      id2: ${exerciseInRoundId2}
    ) {
        isRequestSuccessful
        message
    }
  }`;
}
