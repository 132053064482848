import { useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { withTheme } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { updateExerciseGroup } from '../../core/actions/ExerciseGroupActions';

import BottomMenu from '../components/BottomMenu';
import CreateEditExerciseGroupInfo from './CreateEditExerciseGroupInfo';


const EditExerciseContainer = ({theme, navigation}) => {

  const themeStyles = styles(theme);
  const dispatch = useDispatch();
  const route = useRoute();
  const [exerciseGroup, setExerciseGroup] = useState(undefined);


  useEffect(() => {
    setExerciseGroup(route?.params["exerciseGroup"]);
  }, [route?.params]);

  const onSaveExerciseGroup = (exerciseGroup) => {
    dispatch(updateExerciseGroup.request(exerciseGroup));
    navigation.navigate('AppSettings');
  }


  const onCancel = () => {
    navigation.navigate('ViewExercises');
  }


  return (
    <View style={themeStyles.root}>
      <ScrollView style={themeStyles.mainContent}>
        <CreateEditExerciseGroupInfo exerciseGroup={exerciseGroup} onSavePressed={onSaveExerciseGroup} onCancelPressed={onCancel}/>
      </ScrollView>
      <BottomMenu navigation={navigation} currentScreen='Exercises'/>
    </View>
  )
}

const styles = theme => StyleSheet.create({
  mainContent: {
    backgroundColor: '#ffffff',
    margin: 20,
  },
  root: {
    flex: 1
  }
});

export default withTheme(EditExerciseContainer);

