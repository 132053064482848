import { AppState } from '../reducers/RootReducer';
import { createSelector } from 'reselect';
import { IFitnessCenter } from '../models/Models';

const getFitnessCenter = (state: AppState): IFitnessCenter => state.fitnessCenters.fitnessCenter;
const updateFitnessCenter = (state: AppState) => state.fitnessCenters.fitnessCenters;

export const updateFitnessCentersSelector = createSelector(updateFitnessCenter, (fitnessCenters) => fitnessCenters);
export const getFitnessCenterSelector = createSelector(getFitnessCenter, (fitnessCenter) => fitnessCenter);

const getFitnessCenterById = (state: AppState, fitnessCenterId: number): IFitnessCenter =>
  state?.fitnessCenters?.fitnessCenters?.find((item) => item?.id == fitnessCenterId);

export const getFitnessCenterByIdSelector = (fitnessCenterId: number) =>
  createSelector(
    (state: AppState) => getFitnessCenterById(state, fitnessCenterId),
    (fitnessCenters) => fitnessCenters
  );