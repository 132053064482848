import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Text, ScrollView } from 'react-native';
import { Button, withTheme } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmationDialog from '../components/ConfirmationDialog';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { SectionHeader } from '../components/Headers';
import ExerciseInRoundCard from '../components/trainings/ExerciseInRoundCard';
import { useRoute } from '@react-navigation/native';
import { getClientsPreviousTrainingsSelector, getCurrentTrainingSelector, getNewTrainingClientsPreviousTrainingsSelector } from '../../core/selectors/TrainingSelector';
import { getNewTrainingSelector } from '../../core/selectors/TrainingSelector';
import IconButton from '../components/IconButton';
import { deleteExerciseInRound, deleteRound, getClientsPreviousTrainings, updateExerciseInRound, updateTrainingAction } from '../../core/actions/TrainingActions';
import ExerciseInfoDialog from './ExerciseInfoDialog';
import { IExerciseInRound, ITraining } from '../../core/models/Models';
import { formatDateFullMonthName } from '../../utils/utils';


const PreviousTrainings = ({theme, navigation}) => {

  const route = useRoute();
  const dispatch = useDispatch();
  const newClientsTrainings = useSelector(getNewTrainingClientsPreviousTrainingsSelector);
  const editClientsTrainings = useSelector(getClientsPreviousTrainingsSelector);

  const [previousTrainings, setPreviousTrainings] = useState(Array<ITraining>());
  const [mode, setMode] = useState('create');
  const themeStyles = styles(theme);
  const editTraining = useSelector(getCurrentTrainingSelector);
  const newTraining = useSelector(getNewTrainingSelector);
  const [currentTraining, setCurrentTraining] = useState(undefined);


  useEffect(() => {
    const modeValue = route.params['mode']
    setMode(modeValue);
    let currentTraining;
    if (modeValue == 'create') {
      currentTraining = newTraining;
    } else {
      currentTraining = editTraining;
    }
    setCurrentTraining(currentTraining);
    dispatch(getClientsPreviousTrainings.request(currentTraining?.client?.id, currentTraining?.date, mode, 20));
  }, [route?.params]);


  useEffect(() => {
    setPreviousTrainings(newClientsTrainings);
  }, [newClientsTrainings])

  useEffect(() => {
    setPreviousTrainings(editClientsTrainings);
  }, [editClientsTrainings])


  return (
    <View>
      <View style={themeStyles.mainContent}>
        <View>
          <SectionHeader text={`Klijent ${currentTraining?.client?.name} ${currentTraining?.client?.familyName}`} />
        </View>
        <ScrollView>
          {previousTrainings?.map((training) => (
            <View>
              <View style={themeStyles.headerTextContainer}>
                <Text style={themeStyles.headerText}>{formatDateFullMonthName(training?.date)}</Text>
              </View>
              {training?.rounds?.length == 0 && (
                <View style={themeStyles.round}>
                  <Text style={themeStyles.emptyTrainingText}>Vežbe nisu popunjene</Text>
                </View>
              )}
              {training?.rounds?.map((round) => (
                <View style={themeStyles.round}>
                  <View style={themeStyles.roundHeader}>
                    <Text style={themeStyles.roundHeaderText}>Grupa {round?.roundSequenceNumber}</Text>
                  </View>
                  {round?.roundExerciseInRound?.map((exerciseInRound) => (
                    <View style={themeStyles.roundContainer}>
                      <View style={themeStyles.roundExerciseContainer}>
                        <Text style={themeStyles.roundExerciseText}>{exerciseInRound?.exercise?.name} {exerciseInRound?.difficulty} {exerciseInRound?.numberOfRepetitions && 'X '} {exerciseInRound?.numberOfRepetitions}</Text>
                        <Text style={themeStyles.roundExerciseGroupText}>{exerciseInRound?.exercise?.exerciseGroup?.name}</Text>
                      </View>
                    </View>
                    ))}
                </View>
              ))}
            </View>
            ))}
        </ScrollView>
      </View>
    </View>
  )
}


const styles = theme => StyleSheet.create({
  previousTrainingsContainer: {
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  mainContent: {
    backgroundColor: '#ffffff',
    margin: 20,
    flex: 1,
  },
  hederSaveText: {
    fontFamily: theme.fontFamilies.secondaryFont,
    color: theme.colors.primaryText,
    fontSize: 16,
    textAlign: 'right'
  },
  headerContainer: {
    height: 36,
    justifyContent: 'center',
    flex: 8,
  },
  saveButton: {
    marginTop: 5,
    marginLeft: 'auto'
  },
  inputStyle: {
    margin: 5
  },
  buttonContainer: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 20,
    paddingBottom: 30
  },
  emptyText: {
    fontFamily: theme.fontFamilies.secondaryFont,
    color: theme.colors.disabled,
    fontSize: 14,
    textAlign: 'center'
  },
  addExercisesButton: {
    backgroundColor: theme.colors.primaryText,
    color: theme.colors.lightText,
    fontFamily: theme.fontFamilies.primaryFont,
    marginTop: 20,
    marginBottom: 10,
  },
  deleteExercisesButton: {
    backgroundColor: theme.colors.lightText,
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    borderWidth: 1,
    borderColor: theme.colors.primaryText,
  },
  editRemoveButtons: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    width: "100%",
    margin: 5,
  },
  redBackground: {
    backgroundColor: theme.colors.redMarker,
  },
  orangeBackground: {
    backgroundColor: theme.colors.orangeMarker,
  },
  yellowBackground: {
    backgroundColor: theme.colors.yellowMarker,
  },
  regularBackground: {
    backgroundColor: theme.colors.tertiaryBackground,
  },
  header: {
    flexDirection: 'row',
    height: 70,
    width: '100%',
    borderBottomColor: theme.colors.primaryText,
    borderBottomWidth: 1,
  },
  buttonHeaderContainer: {
    alignSelf: 'center',
    width: 50,
  },
  headerText: {
    color: theme.colors.primaryText,
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 20,
    fontWeight: '600',
  },
  headerTextContainer: {
    flexGrow: 1,
    alignSelf: 'center',
    marginLeft: -20,
  },
  containerStyle: {
    padding: 20,
  },
  modalContent: {
    borderRadius: 10,
    overflow: 'hidden',
    flex: 1,
  },
  round: {
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10,
  },
  roundHeader: {
    marginBottom: 10,
  },
  roundHeaderText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 16,
    fontWeight: '600',
  },
  roundContainer: {

  },
  roundExerciseText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 20,
    fontWeight: '600',
    marginBottom: 5,
  },
  roundExerciseGroupText: {
    color: theme.colors.secondaryText,
    fontFamily: theme.fontFamilies.secondaryFont,
    fontSize: 12,
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  roundExerciseContainer: {
    borderTopColor: theme.colors.primaryText,
    borderTopWidth: 1,
    paddingTop: 10,
    paddingBottom: 10,
  },
  emptyTrainingText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 20,
    fontWeight: '600',
  },
})

export default withTheme(PreviousTrainings);
