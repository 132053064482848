import React, { useState } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { Card, withTheme } from 'react-native-paper';
import { IClient } from '../../core/models/Models';


interface Props {
  theme: object;
  cardText: string;
  bottomText: string;
  key: number;
  onCardPress: () => void;
}


const ClientsCard = ({theme, cardText, bottomText, key, onCardPress}: Props) => {


  const themeStyles = styles(theme);
    return (
      <Card style={[themeStyles.containerCard]} onPress={onCardPress} key={key}>
        <View style={themeStyles.containerCardContent}>
          <View style={themeStyles.containerNameContainer}>
            <Text style={themeStyles.containerNameText} numberOfLines={3}>
              {cardText}
            </Text>
          </View>
          <View style={themeStyles.trainingsNumberContainer}>
            <Text style={themeStyles.trainingsNumberText}>{bottomText}</Text>
          </View>
        </View>
      </Card>
    )
  }


  const styles = theme => StyleSheet.create({
    redBorder: {
      borderTopColor: theme.colors.redMarker,
    },
    containerNameText: {
      fontFamily: theme.fontFamilies.primaryFont,
      color: theme.colors.lightText,
      fontSize: 16,
      textTransform: 'uppercase',
      textAlign: 'center',
    },
    containerNameContainer: {
      justifyContent: 'center',
      alignContent: 'center',
      flexGrow: 1,
    },
    trainingsNumberContainer: {
      backgroundColor: theme.colors.tertiaryBackground,
      justifyContent: 'center',
      alignContent: 'center',
      height: 25,
      margin: 1,
    },
    trainingsNumberText: {
      fontFamily: theme.fontFamilies.secondaryFont,
      color: theme.colors.lightText,
      fontSize: 12,
      textAlign: 'center',
    },
    orangeBorder: {
      borderTopColor: theme.colors.orangeMarker,
    },
    yellowBorder: {
      borderTopColor: theme.colors.yellowMarker,
    },
    containerCard: {
      margin: 10,
      height: 120,
      width: 120,
      color: 'transparent'
    },
    containerCardContent: {
      backgroundColor: theme.colors.backdrop,
      flexGrow: 1,
      flexDirection: 'column',
      height: 120,
      borderRadius: 4,
      borderWidth: 1,
      cursor: 'pointer',
    },
    containerCardTitle: {
      color: theme.colors.lightText,
      fontFamily: theme.fontFamilies.primaryFont,
      textAlign: 'center',
    },
})

export default withTheme(ClientsCard);
