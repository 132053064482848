import {
    DefaultTheme as NavigationDefaultTheme,
  } from '@react-navigation/native';
  import {
    DefaultTheme as PaperDefaultTheme,
    Provider as PaperProvider,
  } from 'react-native-paper';
import merge from 'deepmerge';



export const paperTheme = {
  ...PaperDefaultTheme,
  roundness: 2,
  colors: {
    ...PaperDefaultTheme.colors,
    onPrimary: '#111111',
    primary: '#111111',
    onPrimaryContainer: '#111111',
    border: '#899878',
    onSurfaceVariant: '#111111',
    primaryContainer: '#899878',
    secondary: '#ffffff',
    accent: '#ffffff',
    background: '#ffffff',
    surface: '#F7F7F2',
    text: '#1e1e21',
    disabled: '#666666',
    backdrop: '#111111',
    onSurface: '#111111',
    notification: '#dfdee3',
    primaryText: '#111111',
    secondaryText: '#00000099',
    lightText: '#ffffff',
    disabledText: '#D3D3D3',
    primaryBackground: '#F7F7F2',
    secondaryBackground: '#222725',
    tertiaryBackground: '#899878',
    quaternaryBackground: '#E4E6C3',
    redMarker: '#F25F5C',
    orangeMarker: '#FAA613',
    yellowMarker: '#F3DE2C',

  },
  fontFamilies: {
    primaryFont: 'Montserrat',
    secondaryFont: 'Source_Sans_Pro',
  },
};


const CombinedDefaultTheme = merge(paperTheme, NavigationDefaultTheme);
export {
  CombinedDefaultTheme
}

