import React, { useEffect, useState } from 'react';
import { View, StyleSheet, } from 'react-native';
import { Button, withTheme } from 'react-native-paper';
import { OutlinedTextInput, TextArea} from '../components/InputComponents';
import { IExercise } from '../../core/models/Models';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '../../core/selectors/UserSelector';



interface Props {
    theme: any;
    exerciseGroup?: IExercise;
    onSavePressed: (any) => void;
    onCancelPressed: () => void;
}


const CreateEditExerciseGroupInfo = ({theme, exerciseGroup, onSavePressed, onCancelPressed}: Props) => {

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const themeStyles = styles(theme)
  const user = useSelector(userSelector);

  useEffect(() => {
    setName(exerciseGroup?.name);
    setDescription(exerciseGroup?.description);
  }, [exerciseGroup]);

  const handleCacnel = () => {
    setName('');
    setDescription('');
    onCancelPressed();
  }

  const handleSave = () => {
    onSavePressed({name:name, description: description, id: exerciseGroup?.id});
  }


  return (
    <View>
      <View>
        <View style={themeStyles.inputContainer}>
          <OutlinedTextInput
            label='Naziv'
            value={name}
            onChangeText={(text) => setName(text)}
          />
        </View>
        <View style={themeStyles.descriptionContainer}>
          <TextArea value={description} onChangeText={(text) => setDescription(text)} label="Opis"/>
        </View>
        <View style={themeStyles.buttonContainer}>
          <Button uppercase={true} textColor={theme.colors.lightText} style={themeStyles.saveButton} onPress={handleSave}>Snimi</Button>
          <Button uppercase={true} textColor={theme.colors.primaryText} style={themeStyles.cancelButton} onPress={handleCacnel}>Otkaži</Button>
        </View>
      </View>
  </View>
  )
}


const styles = theme => StyleSheet.create({
  root: {
    flex: 1,
  },
  horizontalContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  fullWidthInput: {
    width: '100%'
  },
  numberContainer: {
    width: 100,
  },
  dateContainer: {
    width: 120,
  },
  row: {
    flexDirection: "row",
    marginRight: 20,
    flexWrap: 'wrap',
  },
  inputBorderStyle: {
    borderRadius: 5,
    borderColor: theme.colors.primaryText
  },
  inputContainer: {
    marginBottom: 10,
    marginRight: 15
  },
  descriptionContainer: {
    marginTop: 20,
    marginBottom: 10,
  },
  buttonContainer: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 20,
    paddingBottom: 30
  },
  saveButton: {
    backgroundColor: theme.colors.primaryText,
    color: theme.colors.lightText,
    fontFamily: theme.fontFamilies.primaryFont,
    marginTop: 20,
    marginBottom: 10,
  },
  cancelButton: {
    backgroundColor: theme.colors.lightText,
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    borderWidth: 1,
    borderColor: theme.colors.primaryText,
  },

})

export default withTheme(CreateEditExerciseGroupInfo);

