import React, { useState } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { Card, withTheme } from 'react-native-paper';
import { ITraining } from '../../../core/models/Models';
import { formatDateLocale } from '../../../utils/utils';
import ShowTrainingDialog from './ShowTrainingDialog';


interface Props {
  theme: object;
  navigation: any;
  key: number;
  previousTrainingNumber: number;
  width?: number;
  height?: number;
  training?: ITraining;
  isHideOpenButton?: boolean;
}

const getAllExercises = (training: ITraining) => {
  const exercises = [];
  training?.rounds?.forEach((round) => {
    round?.roundExerciseInRound.forEach((exerciseInRound) => {
      exercises.push(exerciseInRound?.exercise?.name);
    })
  })
  if (exercises?.length > 0) {
    return exercises.join(', ')
  }
  return 'Vežbe nisu definisane'
}


const PreviousTrainingCard = ({theme, key, isHideOpenButton, navigation, training, previousTrainingNumber, width, height}: Props) => {

  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const themeStyles = styles(theme);
  const colorStyle = previousTrainingNumber == 1 ? themeStyles.redBorder :
    previousTrainingNumber == 2 ? themeStyles.orangeBorder :
    previousTrainingNumber == 3 ? themeStyles.yellowBorder :
    themeStyles.greenBorder;

    return (
      <View key={key}>
        <Card style={[themeStyles.trainingCard, colorStyle, (width ? {width: width}: {}), (height ? {height: height}: {}) ]} onPress={() => setIsDialogVisible(true)}>
          <Card.Title
            titleStyle={themeStyles.trainingCardTitle}
            title={formatDateLocale(training?.date)}
          />
          <Card.Content>
            <Text style={themeStyles.trainingCardContent} numberOfLines={3}>
              {getAllExercises(training)}
            </Text>
          </Card.Content>
        </Card>
        <ShowTrainingDialog
          isDialogVisible={isDialogVisible}
          previousTrainingNumber={previousTrainingNumber}
          training={training}
          navigation={navigation}
          onDismiss={() => setIsDialogVisible(false)}
          isHideOpenButton={isHideOpenButton || false}
        />
      </View>
    )
  }


  const styles = theme => StyleSheet.create({
    redBorder: {
      borderTopColor: theme.colors.redMarker,
    },
    orangeBorder: {
      borderTopColor: theme.colors.orangeMarker,
    },
    yellowBorder: {
      borderTopColor: theme.colors.yellowMarker,
    },
    greenBorder: {
      borderTopColor: theme.colors.tertiaryBackground,
    },
    trainingCard: {
      margin: 10,
      backgroundColor: theme.colors.backdrop,
      color: theme.colors.lightText,
      borderRadius: 4,
      borderTopWidth: 10,
      cursor: 'pointer',
    },
    trainingCardTitle: {
      color: theme.colors.lightText,
      fontFamily: theme.fontFamilies.primaryFont,
      textAlign: 'center',
    },
    trainingCardContent: {
      color: theme.colors.lightText,
      fontFamily: theme.fontFamilies.secondaryFont,
      fontSize: 12,
      marginBottom: 5,
      marginTop: -10
    }
})

export default withTheme(PreviousTrainingCard);
