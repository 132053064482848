import { action, createRequestTypes } from '../../utils/ReduxHelpers';

import { DELETE, GET, POST, PUT } from '../../utils/Constants';
import { IExerciseInRound, IRound, ITraining } from '../models/Models';

export const TRAININGS = createRequestTypes('TRAININGS', [GET]);
export const HOME_TRAININGS = createRequestTypes('HOME_TRAININGS', [GET])
export const CLIENTS_TRAININGS = createRequestTypes('CLIENTS_TRAININGS', [GET]);
export const INDEPENDENT_USERS_TRAININGS = createRequestTypes('INDEPENDENT_USERS_TRAININGS', [GET]);
export const INDEPENDENT_USER_TRAININGS = createRequestTypes('INDEPENDENT_USER_TRAININGS', [GET]);
export const CLIENTS_TRAININGS_PAGINATION = createRequestTypes('CLIENTS_TRAININGS_PAGINATION', [GET]);
export const TRAINING = createRequestTypes('TRAINING', [GET, POST, PUT, DELETE]);
export const ROUND = createRequestTypes('ROUND', [POST, PUT, DELETE]);
export const EXERCISE_IN_ROUND = createRequestTypes('EXERCISE_IN_ROUND', [PUT, DELETE]);
export const PREVIOUS_EXERCISE_IN_ROUND = createRequestTypes('PREVIOUS_EXERCISE_IN_ROUND', [GET]);
export const SWAP_EXERCISES_IN_ROUND = createRequestTypes('SWAP_EXERCISES_IN_ROUND', [POST]);

export const UPLOAD_PDF = createRequestTypes('UPLOAD_PDF', [POST]);

export const UPDATE_TRAINING = 'UPDATE_TRAINING';
export const RESET_CLIENTS_TRAININGS = 'RESET_CLIENTS_TRAININGS';
export const RESET_CLIENTS_PREVIOUS_TRAININGS = 'RESET_CLIENTS_PREVIOUS_TRAININGS';


export const updateTrainingAction = (data: ITraining, source: string) => action(UPDATE_TRAINING, {training: data, source: source});
export const resetClientsTrainings = () => action(RESET_CLIENTS_TRAININGS);
export const resetClientsPreviousTrainings = () => action(RESET_CLIENTS_PREVIOUS_TRAININGS);

export const getTrainings = {
  request: (start: number, count: number, fitnessCenterId: number) => action(TRAININGS.GET.REQUEST, {start, count, fitnessCenterId}),
  success: (payload: any) => action(TRAININGS.GET.SUCCESS, payload),
  failure: (error: string) => action(TRAININGS.GET.FAILURE, error)
};

export const getIndependentUserTrainings = {
  request: (creatorId: number, start: number, count: number) => action(INDEPENDENT_USER_TRAININGS.GET.REQUEST, {creatorId, start, count}),
  success: (payload: any) => action(INDEPENDENT_USER_TRAININGS.GET.SUCCESS, payload),
  failure: (error: string) => action(INDEPENDENT_USER_TRAININGS.GET.FAILURE, error)
};

export const getHomeTrainings = {
  request: (startDate, endDate, userId) => action(HOME_TRAININGS.GET.REQUEST, {startDate, endDate, userId}),
  success: (payload: Array<ITraining>) => action(HOME_TRAININGS.GET.SUCCESS, payload),
  failure: (error: string) => action(HOME_TRAININGS.GET.FAILURE, error)
};


export const getClientsPreviousTrainings = {
  request: (clientId: number, startDate: Date, source?: string, numberOfTrainings?: number) => action(CLIENTS_TRAININGS.GET.REQUEST, { clientId, startDate, source, numberOfTrainings }),
  success: (payload: any) => action(CLIENTS_TRAININGS.GET.SUCCESS, payload),
  failure: (error: string) => action(CLIENTS_TRAININGS.GET.FAILURE, error)
};

export const getIndependentUsersPreviousTrainings = {
  request: (userId: number, startDate: Date, source?: string, numberOfTrainings?: number) => action(INDEPENDENT_USERS_TRAININGS.GET.REQUEST, { userId, startDate, source, numberOfTrainings }),
  success: (payload: any) => action(INDEPENDENT_USERS_TRAININGS.GET.SUCCESS, payload),
  failure: (error: string) => action(INDEPENDENT_USERS_TRAININGS.GET.FAILURE, error)
};


export const getClientsTrainingsPagination = {
  request: (clientId: number, start: number, count: number) => action(CLIENTS_TRAININGS_PAGINATION.GET.REQUEST, { clientId, start, count }),
  success: (payload: any) => action(CLIENTS_TRAININGS_PAGINATION.GET.SUCCESS, payload),
  failure: (error: string) => action(CLIENTS_TRAININGS.GET.FAILURE, error)
};


export const getTraining = {
  request: () => action(TRAINING.GET.REQUEST),
  success: (payload: ITraining) => action(TRAINING.GET.SUCCESS, payload),
  failure: (error: string) => action(TRAINING.GET.FAILURE, error)
}

export const createTraining = {
  request: (training: ITraining, fitnessCenterId: number) => action(TRAINING.POST.REQUEST, { training, fitnessCenterId}),
  success: (payload: any) => action(TRAINING.POST.SUCCESS, payload),
  failure: (error: string) => action(TRAINING.POST.FAILURE, error)
};

export const updateTraining = {
  request: (training: ITraining) => action(TRAINING.PUT.REQUEST, { training }),
  success: (payload: any) => action(TRAINING.PUT.SUCCESS, payload),
  failure: (error: string) => action(TRAINING.PUT.FAILURE, error)
};

export const deleteTraining = {
  request: (training: ITraining) => action(TRAINING.DELETE.REQUEST, { training }),
  success: (payload: any) => action(TRAINING.DELETE.SUCCESS, payload),
  failure: (error: string) => action(TRAINING.DELETE.FAILURE, error)
};

export const createRound = {
  request: (round: IRound, trainingId: number) => action(ROUND.POST.REQUEST, { round, trainingId }),
  success: (payload: any) => action(ROUND.POST.SUCCESS, payload),
  failure: (error: string) => action(ROUND.POST.FAILURE, error)
};

export const updateRound = {
  request: (roundId: number, newExercisesInRound: IExerciseInRound[]) => action(ROUND.PUT.REQUEST, { roundId, newExercisesInRound }),
  success: (payload: any) => action(ROUND.PUT.SUCCESS, payload),
  failure: (error: string) => action(ROUND.PUT.FAILURE, error)
};

export const deleteRound = {
  request: (roundId: number) => action(ROUND.DELETE.REQUEST, { roundId }),
  success: (payload: any) => action(ROUND.DELETE.SUCCESS, payload),
  failure: (error: string) => action(ROUND.DELETE.FAILURE, error)
};

export const updateExerciseInRound = {
  request: (exerciseInRound: IExerciseInRound) => action(EXERCISE_IN_ROUND.PUT.REQUEST, { exerciseInRound }),
  success: (payload: any) => action(EXERCISE_IN_ROUND.PUT.SUCCESS, payload),
  failure: (error: string) => action(EXERCISE_IN_ROUND.PUT.FAILURE, error)
}

export const deleteExerciseInRound = {
  request: (exerciseInRoundId: number) => action(EXERCISE_IN_ROUND.DELETE.REQUEST, { exerciseInRoundId }),
  success: (payload: any) => action(EXERCISE_IN_ROUND.DELETE.SUCCESS, payload),
  failure: (error: string) => action(EXERCISE_IN_ROUND.DELETE.FAILURE, error)
}

export const swapExercisesInRound = {
  request: (roundId: number, exerciseInRoundId1: number, exerciseInRoundId2: number) => action(SWAP_EXERCISES_IN_ROUND.POST.REQUEST, { roundId, exerciseInRoundId1, exerciseInRoundId2 }),
  success: (payload: any) => action(SWAP_EXERCISES_IN_ROUND.POST.SUCCESS, payload),
  failure: (error: string) => action(SWAP_EXERCISES_IN_ROUND.POST.FAILURE, error)
}

export const getClientsPreviousExerciseInRound = {
  request: (clientId, exerciseId) => action(PREVIOUS_EXERCISE_IN_ROUND.GET.REQUEST, { clientId, exerciseId }),
  success: (payload: any) => action(PREVIOUS_EXERCISE_IN_ROUND.GET.SUCCESS, payload),
  failure: (error: string) => action(PREVIOUS_EXERCISE_IN_ROUND.GET.FAILURE, error)
}

export const uploadPDF = {
  request: (training, fitnessCenterId, pdfFile) => action(UPLOAD_PDF.POST.REQUEST, { training, fitnessCenterId, pdfFile }),
  success: (payload: any) => action(UPLOAD_PDF.POST.SUCCESS, payload),
  failure: (error: string) => action(UPLOAD_PDF.POST.FAILURE, error)
}


