
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { CalendarProvider, LocaleConfig, WeekCalendar } from 'react-native-calendars';
import { withTheme } from 'react-native-paper';
import IconButton from '../IconButton';


interface ICalendarProps {
  theme: any;
  currentDay: Date,
  week: string,
  onDayPressed: (Date) => void;
  onPreviousWeekPressed: () => void;
  onNextWeekPressed: () => void;
}

LocaleConfig.locales['sr'] = {
    monthNames: [
      'Januar',
      'Februar',
      'Mart',
      'April',
      'Maj',
      'Jun',
      'Jul',
      'Avgust',
      'Septembar',
      'Octobar',
      'Novembar',
      'Decembar'
    ],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Avg', 'Sep', 'Okt', 'Nov', 'Dec'],
    dayNames: ['Nedelja', 'Ponedeljak', 'Utorak', 'Sreda', 'Četvrtak', 'Petak', 'Subota'],
    dayNamesShort: ['Ned', 'Pon', 'Uto', 'Sre', 'Čet', 'Pet', 'Sub'],
    today: "Danas"
  };
  LocaleConfig.defaultLocale = 'sr';

const today = new Date().toISOString()

const WeeklyCalendarComponent = ({theme , week, currentDay, onDayPressed, onPreviousWeekPressed, onNextWeekPressed}: ICalendarProps) => {

    const themeStyles = styles(theme)
    return (
      <View style={themeStyles.calendarComponent}>
        <CalendarProvider
          date={week}
          >
          <WeekCalendar
            onDayPress={(day) => onDayPressed(day)}
            firstDay={1}
            theme={{
              backgroundColor: theme.colors.primaryBackground,
              calendarBackground: theme.colors.primaryBackground,
              textSectionTitleColor: theme.colors.secondaryText,
              textSectionTitleDisabledColor: theme.colors.secondaryText,
              selectedDayBackgroundColor: theme.colors.tertiaryBackground,
              selectedDayTextColor: theme.colors.lightText,
              todayTextColor: theme.colors.tertiaryBackground,
              dayTextColor:  theme.colors.primaryText,
              textDisabledColor: theme.colors.secondaryText,
              indicatorColor: theme.colors.tertiaryBackground,
              textDayFontFamily: 'Source_Sans_Pro',
              textDayHeaderFontFamily: 'Montserrat',
              textDayFontWeight: '500',
              textMonthFontWeight: 'bold',
              textDayHeaderFontWeight: '100',
              textDayFontSize: 16,
              textMonthFontSize: 12,
              textDayHeaderFontSize: 12
            }}
          />
        </CalendarProvider>
      <View style={themeStyles.bottomContainer}>
        <IconButton icon={faChevronLeft} onPress={onPreviousWeekPressed} />
        <Text style={themeStyles.currentDayText}>
          Dan {currentDay.toLocaleDateString().substring(0,10)}
        </Text>
        <IconButton icon={faChevronRight} onPress={onNextWeekPressed} />
      </View>
    </View>
    )
  }

export default withTheme(WeeklyCalendarComponent);


const styles = theme => StyleSheet.create({
  bottomContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 'auto'
  },
  calendarComponent: {
    width: '100%',
    backgroundColor: theme.colors.primaryBackground,
    textAlign: 'center',
    fontFamily: theme.fontFamilies.secondaryFont,
    color: theme.colors.secondaryText,
    paddingBottom: 20,
    // boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.12)',
    marginBottom: 20
  },
  containerWithArrows: {
    flexDirection: 'row',
  },
  currentDayText: {
    fontFamily: theme.fontFamilies.secondaryFont,
    color: theme.colors.secondaryText,
    lineHeight: 24,
    fontWeight: '600',
    fontSize: 15,
    alignSelf: 'center',
  },
})
