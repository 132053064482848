import { action, createRequestTypes } from '../../utils/ReduxHelpers';

import { DELETE, GET, POST, PUT } from '../../utils/Constants';
import { IExercise } from '../models/Models';

export const EXERCISES = createRequestTypes('EXERCISES', [GET]);

export const EXERCISE = createRequestTypes('EXERCISE', [GET, POST, PUT, DELETE]);

export const getExercises = {
  request: (currentFitnessCenterId: number) => action(EXERCISES.GET.REQUEST, {currentFitnessCenterId}),
  success: (payload: Array<IExercise>) => action(EXERCISES.GET.SUCCESS, payload),
  failure: (error: string) => action(EXERCISES.GET.FAILURE, error)
};

export const getExercise = {
  request: () => action(EXERCISE.GET.REQUEST),
  success: (payload: IExercise) => action(EXERCISE.GET.SUCCESS, payload),
  failure: (error: string) => action(EXERCISE.GET.FAILURE, error)
}

export const createExercise = {
  request: (data: IExercise, currentFitnessCenterId: number) => action(EXERCISE.POST.REQUEST, { data, currentFitnessCenterId }),
  success: (payload: any) => action(EXERCISE.POST.SUCCESS, payload),
  failure: (error: string) => action(EXERCISE.POST.FAILURE, error)
};


export const updateExercise = {
  request: (data: IExercise, currentFitnessCenterId: number) => action(EXERCISE.PUT.REQUEST, { data, currentFitnessCenterId }),
  success: (payload: any) => action(EXERCISE.PUT.SUCCESS, payload),
  failure: (error: string) => action(EXERCISE.PUT.FAILURE, error)
};

export const deleteExercise = {
  request: (id: number) => action(EXERCISE.DELETE.REQUEST, { id }),
  success: (payload: any) => action(EXERCISE.DELETE.SUCCESS, payload),
  failure: (error: string) => action(EXERCISE.DELETE.FAILURE, error)
};
