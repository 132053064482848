import React, { useEffect, useState } from 'react';
import { Animated, ScrollView, StyleSheet, View } from 'react-native';
import { withTheme} from 'react-native-paper';
import BottomMenu from './components/BottomMenu';
import WeeklyCalendarComponent from './components/home/WeekCalendarComponent';
import TrainingTimeCard from './components/home/TrainingTimeCard';
import { SectionHeader } from './components/Headers';
import { useDispatch, useSelector } from 'react-redux';
import { getHomeTrainings } from '../core/actions/TrainingActions';
import { getHomeTrainingsSelector } from '../core/selectors/TrainingSelector';
import { removeTime, daysEqual, getTime, getMonday, getSunday } from '../utils/utils';
import ShowTrainingDialog from './components/trainings/ShowTrainingDialog';
import { TrainingStatus } from '../core/models/Models';
import { userSelector } from '../core/selectors/UserSelector';
import { DropDownInput } from './components/InputComponents';
import IconButton from './components/IconButton';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { findUsersByFitnessCenter } from '../core/actions/UserActions';


const getTrainingsByDate = (trainings,  date: Date) => {
  const dateDay = removeTime(date);
  const today = removeTime(new Date());
  let upcomingTrainings = [];
  let finishedTrainings = [];
  if (dateDay < today) {
    finishedTrainings = trainings?.filter((t) => daysEqual(t.date, date));
  } else if (dateDay > today) {
    upcomingTrainings = trainings?.filter((t) => {
      return daysEqual(t.date, date);
    })
  } else {
    upcomingTrainings = trainings?.filter((t) => {
      return daysEqual(t.date, date) && (t?.status == TrainingStatus.READY ||  t?.status == TrainingStatus.DRAFT);
    })
    finishedTrainings = trainings?.filter((t) => {
      return daysEqual(t.date, date) && t.status == TrainingStatus.DONE;
    })
  }
  return [upcomingTrainings, finishedTrainings];
}


enum FilterOption {
  MINE = 'MINE',
  ALL = 'ALL',
}


const filterList = [
  {
    label: 'Moji treninzi',
    value: FilterOption.MINE,
  },
  {
    label: 'Svi treninzi',
    value: FilterOption.ALL,
  },
];



const Home = ({theme, navigation}) => {

  const themeStyles = styles(theme);

  const [selectedDay, setSelectedDay] = useState(new Date())
  const [currentWeek, setCurrentWeek] = useState(new Date().toISOString().split('T')[0]);
  const trainings = useSelector(getHomeTrainingsSelector);
  const [finishedTrainings, setFinishedTrainings] = useState([]);
  const [upcomingTrainings, setUpcomingTrainings] = useState([]);
  const [isTrainingDialogVisible, setIsTrainingDialogVisible] = useState(false);
  const [currentTraining, setCurrentTraining] = useState(undefined);
  const [showDropDown, setShowDropDown] = useState(false);
  const [filterValue, setFilterValue] = useState(FilterOption.MINE);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const user = useSelector(userSelector);


  const dispatch = useDispatch();

  const onDayPressed = (pressedDate) => {
    const newSelectedDay = new Date(pressedDate.dateString);
    setSelectedDay(new Date(pressedDate.dateString))
    let [filteredUpcoming, filteredFinished] = getTrainingsByDate(trainings, newSelectedDay);
    setUpcomingTrainings(filteredUpcoming);
    setFinishedTrainings(filteredFinished);
  }

  const onPreviousWeekPressed = () => {
    const previoustWeek = new Date(currentWeek);
    previoustWeek.setDate(previoustWeek.getDate() - 7)
    const previoustMonday = getMonday(previoustWeek);
    console.log(previoustMonday.toISOString().split('T')[0])
    setSelectedDay(previoustMonday);
    setCurrentWeek(previoustMonday.toISOString().split('T')[0]);
  }

  const onNextWeekPressed = () => {
    const nextWeek = new Date(currentWeek);
    nextWeek.setDate(nextWeek.getDate() + 7)
    const nextMonday = new Date(getMonday(nextWeek));
    setSelectedDay(nextMonday);
    setCurrentWeek(nextMonday.toISOString().split('T')[0]);
  }

  useEffect(() => {
    if (filterValue == FilterOption.MINE) {
      getTrainingsForWeek(selectedDay, user?.id);
    } else {
      getTrainingsForWeek(selectedDay, null);
    }
    }, [currentWeek]);

  useEffect(() => {
    let [filteredUpcoming, filteredFinished] = getTrainingsByDate(trainings, selectedDay);
    setUpcomingTrainings(filteredUpcoming);
    setFinishedTrainings(filteredFinished);
    setCurrentWeek(currentWeek);
  }, [trainings, selectedDay]);


  const getTrainingsForWeek = (date, userId) => {
    const monday = getMonday(date);
    monday.setHours(0);
    monday.setMinutes(0);
    monday.setSeconds(0);
    const sunday = getSunday(date);
    sunday.setHours(23);
    sunday.setMinutes(59);
    sunday.setSeconds(59);
    dispatch(getHomeTrainings.request(monday, sunday, userId));
  }

  const handleCardPressed = (training) => {
    setCurrentTraining(training);
    setIsTrainingDialogVisible(true);
  }

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
  }

  const handleFilterValueChanged = (value) => {
    if (value != filterValue) {
      setFilterValue(value);
      if (value == FilterOption.MINE) {
        getTrainingsForWeek(selectedDay, user?.id);
      } else {
        getTrainingsForWeek(selectedDay, null);
      }
    }
  }

  useEffect(() => {
    {user.currentFitnessCenter &&
      dispatch(findUsersByFitnessCenter.request(user.currentFitnessCenter.id));
    }
  },[]);

  return (
    <View style={themeStyles.root}>
      <ScrollView>
        <WeeklyCalendarComponent
          week={currentWeek}
          onDayPressed={onDayPressed}
          currentDay={selectedDay}
          onNextWeekPressed={onNextWeekPressed}
          onPreviousWeekPressed={onPreviousWeekPressed}
        />
        <View style={themeStyles.mainContent}>
          <View>
            <View style={themeStyles.headerContainer}>
              {isSearchVisible && (
                <DropDownInput
                  label={"Prikaz"}
                  isDropdownVisible={showDropDown}
                  toggleDropDown={setShowDropDown}
                  value={filterValue}
                  onValueChanged={(value) => handleFilterValueChanged(value)}
                  valuesList={filterList}
                />
              )}
              <View style={themeStyles.searchButton}>
                <IconButton icon={faSearch} onPress={toggleSearch} iconSize={25}/>
              </View>
            </View>
            <SectionHeader text=''/>
          </View>
          {
            (upcomingTrainings?.length === 0 && finishedTrainings?.length === 0) ? (
              filterValue === FilterOption.MINE ? (
                <SectionHeader text='Nemate zakazanih treninga za odabrani datum' />
              ) : (
                <SectionHeader text='Nema zakazanih treninga za odabrani datum' />
              )
            ) : null
          }

          {upcomingTrainings?.map((item, index) => (
            <TrainingTimeCard
              client={item?.client}
              key={item.id}
              trainingNumber={item?.numberOfTrainings}
              trainingTime={getTime(item?.date)}
              onCardPressed={() => {handleCardPressed(item)}}
              trainerName={`${item?.trainingExecutor?.personalName} ${item?.trainingExecutor?.familyName}`}
              isShowTrainer={filterValue == FilterOption.ALL}
            />
            ))
          }
          {finishedTrainings?.map((item, index) => (
            <TrainingTimeCard
              client={item?.client}
              key={item.id}
              trainingNumber={item?.numberOfTrainings}
              trainingTime={getTime(item?.date)}
              isFinished={true}
              onCardPressed={() => { handleCardPressed(item); } }
              isShowTrainer={filterValue == FilterOption.ALL}
              trainerName={`${item?.trainingExecutor?.personalName} ${item?.trainingExecutor?.familyName}`}
              />
          ))}
        </View>
      </ScrollView>
      <BottomMenu navigation={navigation} currentScreen='Home'/>
      <ShowTrainingDialog
          isDialogVisible={isTrainingDialogVisible}
          previousTrainingNumber={0}
          training={currentTraining}
          navigation={navigation}
          onDismiss={() => setIsTrainingDialogVisible(false)}
        />
    </View>
  );
}

export default withTheme(Home);


const styles = theme => StyleSheet.create({
  root: {
    flex: 1,
  },
  trainingsHeader: {
    fontFamily: theme.fontFamilies.primaryFont,
    color: theme.colors.primaryText,
    fontSize: 20,
    fontWeight: '600',
    marginBottom: 10,
  },
  trainingsHeaderContainer: {
    // paddingBottom: 20
  },
  mainContent: {
    margin: 20,
    marginBottom: 80,
  },
  saveButton: {
    backgroundColor: theme.colors.primaryText,
    color: theme.colors.lightText,
    fontFamily: theme.fontFamilies.primaryFont,
    marginTop: 20,
    marginBottom: 10,
  },
  headerContainer: {
    flexDirection: 'row',
    alignContent: 'flex-end',
    alignSelf: 'flex-end',
    height: 40,
  },
  searchButton: {
    justifyContent: 'center',
    alignItems: 'center'
  },
})
