import { IActivity, IExerciseGroup, IUserSettings } from '../models/Models';
import { produce } from 'immer';
import { USER_SETTINGS } from '../actions/UserSettingsActions';

export interface IUserSettingsState {
  userSettings: IUserSettings;
}

const initialState: IUserSettingsState = {
  userSettings: {
    isDarkThemeOn: false,
  }
};

const userSettingsReducer = (state = initialState, action: IActivity): IUserSettingsState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case USER_SETTINGS.GET.SUCCESS:
        draft.userSettings = payload;
        break;
      case USER_SETTINGS.PUT:
        draft.userSettings = payload;
        break;
      case USER_SETTINGS.POST.SUCCESS:
        draft.userSettings = payload; //.push({...payload, trainingCount: 0});
        break;
      default:
        return draft;
    }
  });

export default userSettingsReducer;
