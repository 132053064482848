import { IUser, IUserFitnessCenter } from "../models/Models";

export function login(username, password) {
  return `{
    userName: "${username}"
    password: "${password}"
    role: ""
  }`;
};


export function createUserFitnessCenter(userFitnessCenter: IUserFitnessCenter) {
  return `mutation {
    createUserFitnessCenter (
      fitnessCenterId: ${userFitnessCenter?.fitnessCenter?.id}
      userId: ${userFitnessCenter?.user?.id}
      authorities: "${userFitnessCenter?.authorities}"
    ){
      isRequestSuccessful
      message
    }
  }`;
};

export function deleteUserFitnessCenter(fitnessCenterId: number, id: number) {
  return `mutation {
    deleteUserFitnessCenter (
      id: ${id}
      fitnessCenterId: ${fitnessCenterId}
    ){
      isRequestSuccessful
      message
    }
  }`;
};
