import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { Button } from "react-native-paper";
import { View } from "react-native";

interface IButtonProps {
    width?: number,
    iconSize?: number,
    icon: any,
    onPress: () => void;
}

const IconButton = ({width, iconSize, icon, onPress}: IButtonProps) => {
  return (
    <View style={{ width: width }}>
      <Button
        icon={({ size }) => (
          <FontAwesomeIcon icon={icon} size={iconSize}/>
        )}
        onPress={onPress}
      />
    </View>
  )
}


export default IconButton;
