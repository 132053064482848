import React, { useEffect, useState } from 'react';
import { View, StyleSheet, } from 'react-native';
import { Button, withTheme } from 'react-native-paper';
import { DropDownInput, OutlinedTextInput, TextArea} from '../components/InputComponents';
import { IExercise, IExerciseGroup } from '../../core/models/Models';
import { useDispatch, useSelector } from 'react-redux';
import { getExerciseGroups } from '../../core/actions/ExerciseGroupActions';
import { getExerciseGroupsSelector } from '../../core/selectors/ExerciseGroupSelectors';
import { MultiSelect } from 'react-native-element-dropdown';
import AntDesign from '@expo/vector-icons/AntDesign';
import { userSelector } from '../../core/selectors/UserSelector';

interface Props {
    theme: any;
    exercise?: IExercise;
    onSavePressed: (any) => void;
    onCancelPressed: () => void;
}

const formatExerciseGroups = (exerciseGroups: IExerciseGroup[]) => {
  return exerciseGroups?.map((exerciseGroup) => {
    return {
      label: exerciseGroup?.name,
      value: exerciseGroup?.id
    }
  });
}

const CreateEditExercise = ({theme, exercise, onSavePressed, onCancelPressed}: Props) => {

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [exerciseGroupId, setExerciseGroupId] = useState(-1);
  const exerciseGroups = useSelector(getExerciseGroupsSelector);
  const formattedGroups = formatExerciseGroups(exerciseGroups);
  const dispatch = useDispatch();
  const themeStyles = styles(theme)
  const [exerciseGroupCollection, setExerciseGroupCollection] = useState<IExerciseGroup[]>([]);
  const [selected, setSelected] = useState([]);
  const user = useSelector(userSelector);
  

  useEffect(() => {
    if (!exerciseGroups?.length) {
      dispatch(getExerciseGroups.request());
    }
    const idArray = [];
      for(let i=0; i < exercise?.exerciseGroups.length; i++){
        idArray.push(exercise?.exerciseGroups[i]?.id);
    }
    setSelected(idArray);

  }, [exercise]);

  useEffect(() => {
    setName(exercise?.name);
    setDescription(exercise?.description);
    setExerciseGroupId(exercise?.exerciseGroups[0]?.id);
  }, [exercise]);

  const handleCacnel = () => {
    setName('');
    setDescription('');
    setExerciseGroupId(-1);
    onCancelPressed();
  }

  const handleSave = () => {
    const exerciseGroup = exerciseGroups.find((exerciseGroup) => exerciseGroup?.id == exerciseGroupId);

    for(let i=0; i < selected.length; i++){
      const exerciseGroup1 = exerciseGroups.find((exerciseGroup) => exerciseGroup?.id == Number(selected[i]));
      exerciseGroupCollection.push(exerciseGroup1);
    }

    onSavePressed({id: exercise?.id, name: name, description: description, exerciseGroup: exerciseGroup, exerciseGroups: exerciseGroupCollection});
  }

  const isInputValid = () => name && name?.trim() != '' && selected.length > 0;

  return (
    <View>
      <View>
        <View style={themeStyles.inputContainer}>
          <OutlinedTextInput
            label='Naziv'
            value={name}
            onChangeText={(text) => setName(text)}
          />
        </View>
        <View style={themeStyles.inputContainer}>
        <MultiSelect
          style={themeStyles.dropdown}
          placeholderStyle={themeStyles.placeholderStyle}
          selectedTextStyle={themeStyles.selectedTextStyle}
          inputSearchStyle={themeStyles.inputSearchStyle}
          iconStyle={themeStyles.iconStyle}
          search
          data={formattedGroups}
          labelField="label"
          valueField="value"
          placeholder="Izbor"
          searchPlaceholder=" Pretraga..."
          value={selected}
          onChange={item => {
            setSelected(item);
          }}
/*          renderLeftIcon={() => (
            <AntDesign
              style={styles.icon}
              color="black"
              name="Safety"
              size={20}
            />
          )}*/
          selectedStyle={themeStyles.selectedStyle}
        />
      </View>
        <View style={themeStyles.descriptionContainer}>
          <TextArea value={description} onChangeText={(text) => setDescription(text)} label="Opis"/>
        </View>
        <View style={themeStyles.buttonContainer}>
          <Button uppercase={true} textColor={theme.colors.lightText} disabled={!isInputValid()} style={themeStyles.saveButton} onPress={handleSave}>Snimi</Button>
          <Button uppercase={true} textColor={theme.colors.primaryText} style={themeStyles.cancelButton} onPress={handleCacnel}>Otkaži</Button>
        </View>
      </View>
  </View>
  )
}


const styles = theme => StyleSheet.create({
  root: {
    flex: 1,
  },
  horizontalContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  fullWidthInput: {
    width: '100%'
  },
  numberContainer: {
    width: 100,
  },
  dateContainer: {
    width: 120,
  },
  row: {
    flexDirection: "row",
    marginRight: 20,
    flexWrap: 'wrap',
  },
  inputBorderStyle: {
    borderRadius: 5,
    borderColor: theme.colors.primaryText
  },
  inputContainer: {
    marginBottom: 10,
    marginRight: 15
  },
  descriptionContainer: {
    marginTop: 20,
    marginBottom: 10,
  },
  buttonContainer: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 20,
    paddingBottom: 30
  },
  saveButton: {
    backgroundColor: theme.colors.tertiaryBackground,
    fontFamily: theme.fontFamilies.primaryFont,
    marginTop: 20,
    marginBottom: 10,
  },
  cancelButton: {
    backgroundColor: theme.colors.lightText,
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    borderWidth: 1,
    borderColor: theme.colors.primaryText,
  },
  container: { padding: 16 },
  dropdown: {
    height: 50,
    backgroundColor: theme.colors.primaryBackground,
    borderRadius: 12,
    padding: 12,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,

    elevation: 2,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 14,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 17,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectedStyle: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 14,
    color: theme.colors.primaryText,
    textShadowColor: theme.colors.primaryText,
    backgroundColor: theme.colors.primaryBackground,
    shadowColor: '#000',
    marginTop: 8,
    marginRight: 12,
    paddingHorizontal: 12,
    paddingVertical: 8,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,

    elevation: 2,
  },
  textSelectedStyle: {
    marginRight: 5,
    fontSize: 16,
  },
})


export default withTheme(CreateEditExercise);

