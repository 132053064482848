import { IActivity, IExercise } from '../models/Models';
import { produce } from 'immer';
import { EXERCISE, EXERCISES } from '../actions/ExerciseActions';

export interface IExerciseState {
  exercises: IExercise[];
}


const initialState: IExerciseState = {
  exercises: [
  ]
};

const exerciseReducer = (state = initialState, action: IActivity): IExerciseState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case EXERCISES.GET.SUCCESS:
        draft.exercises = payload;
        break;
      case EXERCISE.DELETE.SUCCESS:
          draft.exercises = state.exercises.filter((e) => e.id !== payload);
          break;
      case EXERCISE.POST.SUCCESS:
          draft.exercises.push(payload)
          break;
      case EXERCISE.PUT.SUCCESS:
        draft.exercises = state.exercises.map((e) => {
          return e?.id == payload?.id ? payload : e;
        });
      default:
        return draft;
    }
  });

export default exerciseReducer;
