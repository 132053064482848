import * as React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faDumbbell, faHouse, faPeopleGroup, faPersonRunning, faUser } from '@fortawesome/free-solid-svg-icons';
import CreateTrainingContainer from '../training/CreateTrainingContainer';
import { Button } from 'react-native-paper';
import { StyleSheet, Pressable, Text } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '../../core/selectors/UserSelector';

const Tab = createBottomTabNavigator();


const BigAddButton = (props) => {

  return (
    <Pressable onPress={() => {
        props.navigation.navigate('CreateTrainingContainer');
      }}
      style={styles.buttonStyle}
    >
      <Text style={styles.buttonLabelStyle}>+</Text>
    </Pressable>
  )
}

const EmptyComponent = () => {
  return null
}


export default function BottomMenu(props) {
  const navigation = props.navigation;
  const user = useSelector(userSelector);
  const currentScreen = props.currentScreen;

  return (
      <Tab.Navigator
        initialRouteName="Home"
        screenOptions={{
          tabBarStyle: {
            backgroundColor: '#222725',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
          },
          tabBarLabelPosition: 'below-icon',
          tabBarActiveTintColor: '#ffffff',
          tabBarInactiveTintColor: 'rgba(255, 255, 255, 0.74)'
        }}
      >
        <Tab.Screen
          name="Home"
          component={EmptyComponent}
          options={{
            tabBarLabel: 'Dom',
            headerShown: false,
            tabBarIcon: ({ color, size }) => (
              <FontAwesomeIcon color={currentScreen == 'Home' ? '#ffffff': 'rgba(255, 255, 255, 0.74)'} icon={faHouse} size={size}/>
            ),
          }}
          listeners={{
            tabPress: e => {
              e.preventDefault();
              navigation.navigate('Home')
            },
          }}
        />
        {user?.currentFitnessCenter ? (
          <Tab.Screen
            name="Clients"
            component={EmptyComponent}
            options={{
              headerShown: false,
              tabBarLabel: 'Klijenti',
              tabBarIcon: ({ color, size }) => (
                <FontAwesomeIcon color={currentScreen == 'Clients' ? '#ffffff': 'rgba(255, 255, 255, 0.74)'} icon={faPeopleGroup} size={size}/>
              )
            }}
            listeners={{
              tabPress: e => {
                e.preventDefault();
                navigation.navigate('ViewClients')
              },
            }}
          />
        ): (
          <Tab.Screen
          name="Profile"
          component={EmptyComponent}
          options={{
            headerShown: false,
            tabBarLabel: 'Profil',
            tabBarIcon: ({ color, size }) => (
              <FontAwesomeIcon color={currentScreen == 'Profile' ? '#ffffff': 'rgba(255, 255, 255, 0.74)'} icon={faUser} size={size}/>
            )
          }}
          listeners={{
            tabPress: e => {
              e.preventDefault();
              navigation.navigate('AppSettings')
            },
          }}
        />
        )}
        <Tab.Screen name='NewTraining' component={CreateTrainingContainer} options={{
          tabBarButton: () => (<BigAddButton navigation={navigation}/>),
        }} />
        <Tab.Screen
          name="Trainings"
          component={EmptyComponent}
          options={{
            headerShown: false,
            tabBarLabel: 'Treninzi',
            tabBarIcon: ({ color, size }) => (
              <FontAwesomeIcon color={currentScreen == 'Trainings' ? '#ffffff': 'rgba(255, 255, 255, 0.74)'}  icon={faPersonRunning} size={size}/>
            ),
          }}
          listeners={{
            tabPress: e => {
              e.preventDefault();
              navigation.navigate('ViewTrainings')
            },
          }}
        />
        <Tab.Screen
          name="Exercises"
          component={EmptyComponent}
          options={{
            headerShown: false,
            tabBarLabel: 'Vežbe',
            tabBarIcon: ({ color, size }) => (
              <FontAwesomeIcon color={currentScreen == 'Exercises' ? '#ffffff': 'rgba(255, 255, 255, 0.74)'}  icon={faDumbbell} size={size}/>
            ),
          }}
          listeners={{
            tabPress: e => {
              e.preventDefault();
              navigation.navigate('ViewExercises')
            },
          }}
        />
      </Tab.Navigator>
  );
}

const styles = StyleSheet.create({
  buttonStyle: {
    width: 60, // Diameter of the round button
    height: 60, // Diameter of the round button
    borderRadius: 30, // Half of the width/height to make it round
    backgroundColor: '#899878', // Background color of the button
    justifyContent: 'center', // Center the '+' vertically
    alignItems: 'center', // Center the '+' horizontally
    marginTop: -30,
  },
  buttonLabelStyle: {
    fontSize: 40, // Size of the '+' sign
    color: '#ffffff', // Color of the '+' sign
  },
});