export function deleteExerciseGroup(id) {
  return `mutation {
    deleteExerciseGroup (
      id: ${id}
    ) {
      id
    }
  }`;
}

export function createExerciseGroup(exerciseGroup) {
  return `mutation {
    createExerciseGroup (
      name: "${exerciseGroup.name}"
    ) {
      message {
        isRequestSuccessful
	      message
	      id
      }
      exerciseGroup {
        id
        name
      }
    }
  }`;
}

export function updateExerciseGroup(exerciseGroup) {
  return `mutation {
    updateExerciseGroup (
      id: ${exerciseGroup.id}
      name: "${exerciseGroup.name}"
    ) {
      message {
        isRequestSuccessful
	      message
	      id
      }
      exerciseGroup {
        id
        name
      }
    }
  }`;
}
