import { action, createRequestTypes } from '../../utils/ReduxHelpers';

import { DELETE, GET, POST, PUT } from '../../utils/Constants';
import { IClient } from '../models/Models';

export const CLIENTS = createRequestTypes('CLIENTS', [GET]);
export const CLIENT = createRequestTypes('CLIENT', [GET, POST, PUT, DELETE]);
export const ADD_CLIENTS_TRAINING = 'ADD_CLIENTS_TRAINING';
export const REMOVE_CLIENTS_TRAINING = 'REMOVE_CLIENTS_TRAINING';


export const addClientsTraining = (data: number) => action(ADD_CLIENTS_TRAINING, data);
export const removeClientsTraining = (data: number) => action(REMOVE_CLIENTS_TRAINING, data);


export const getClients = {
    request: (fitnessCenterId: number) => action(CLIENTS.GET.REQUEST, { fitnessCenterId }),
    success: (payload: Array<IClient>) => action(CLIENTS.GET.SUCCESS, payload),
    failure: (error: string) => action(CLIENTS.GET.FAILURE, error)
}

export const getClient = {
    request: () => action(CLIENT.GET.REQUEST),
    success: (payload: IClient) => action(CLIENT.GET.SUCCESS, payload),
    failure: (error: string) => action(CLIENT.GET.FAILURE, error)
  }
  
  export const createClient = {
    request: (data: IClient) => action(CLIENT.POST.REQUEST, { data }),
    success: (payload: any) => action(CLIENT.POST.SUCCESS, payload),
    failure: (error: string) => action(CLIENT.POST.FAILURE, error)
  };
  
  export const updateClient = {
    request: (data: any) => action(CLIENT.PUT.REQUEST, { data }),
    success: (payload: any) => action(CLIENT.PUT.SUCCESS, payload),
    failure: (error: string) => action(CLIENT.PUT.FAILURE, error)
  };
  
  export const deleteClient = {
    request: (id: number) => action(CLIENT.DELETE.REQUEST, { id }),
    success: (payload: any) => action(CLIENT.DELETE.SUCCESS, payload),
    failure: (error: string) => action(CLIENT.DELETE.FAILURE, error)
  };
