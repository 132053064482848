import React from "react";
import { PropsWithChildren, useState } from "react";
import { View, TouchableOpacity, StyleSheet, Pressable } from "react-native";
import IconButton from "./IconButton";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Paragraph, withTheme } from "react-native-paper";

type AccordionItemPros = PropsWithChildren<{
    theme: any;
    title: string;
  }>;
  
const AccordionItem = ({ children, theme, title }: AccordionItemPros) => {
    const [ expanded, setExpanded ] = useState(false);

    const themeStyles = styles(theme);
  
    function toggleItem() {
      setExpanded(!expanded);
    }
  
    const body = <View style={themeStyles.accordBody}>{ children }</View>;
  
    return (
      <View style={themeStyles.accordContainer}>
        <Pressable style={themeStyles.accordHeader} onPress={ toggleItem }>
          <Paragraph style={themeStyles.accordTitle}>{ title }</Paragraph>
          <IconButton icon={ expanded ? faChevronUp : faChevronDown} iconSize={20} onPress={toggleItem} />
        </Pressable>
        { expanded && body }
      </View>
    );
  }


  const styles = theme => StyleSheet.create({
    container: {
      flex: 1
    },
    accordContainer: {
      paddingBottom: 4
    },
    accordHeader: {
      padding: 12,
      flex: 1,
      flexDirection: 'row',
    //   justifyContent:'space-between'
    },
    accordTitle: {
      fontSize: 20,
      color: theme.colors.primaryText,
      fontFamily: theme.fontFamilies.primaryFont,
    },
    accordBody: {
      padding: 12
    },
    textSmall: {
      fontSize: 16
    },
    seperator: {
      height: 12
    }
  });

export default withTheme(AccordionItem);