import { faXmark } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { View, StyleSheet, Text, ScrollView } from 'react-native';
import Modal from 'react-native-modal/dist/modal';
import { Button, Portal, withTheme } from 'react-native-paper';
import { useDispatch } from 'react-redux';
import { updateTrainingAction } from '../../../core/actions/TrainingActions';
import { ITraining } from '../../../core/models/Models';
import { formatDateFullMonthName } from '../../../utils/utils';
import IconButton from '../IconButton';
import S3PDFDisplay from '../S3PDFDisplay';


interface Props {
  theme: object;
  navigation: any;
  previousTrainingNumber: number;
  training?: ITraining
  isDialogVisible: boolean;
  onDismiss: () => void;
  isHideOpenButton?: boolean;
}


const ShowTrainingDialog = ({theme, isHideOpenButton, navigation, training, previousTrainingNumber, isDialogVisible, onDismiss}: Props) => {
  const themeStyles = styles(theme)
  const colorStyle = previousTrainingNumber == 1 ? themeStyles.redBackground :
    previousTrainingNumber == 2 ? themeStyles.orangeBackground :
    previousTrainingNumber == 3 ? themeStyles.yellowBackground :
    themeStyles.regularBackground;
  const dispatch = useDispatch();


  const openTraining = () => {
    onDismiss();
    dispatch(updateTrainingAction({...training}, 'edit'));
    navigation.navigate('EditTrainingContainer')
  }


  return (
      <Modal isVisible={isDialogVisible} onDismiss={onDismiss}>
        <View style={themeStyles.modalContent}>
          <View style={[themeStyles.header, colorStyle]}>
            <View style={themeStyles.buttonHeaderContainer}>
              <IconButton width={10} icon={faXmark} iconSize={20} onPress={onDismiss}/>
            </View>
            <View style={themeStyles.headerTextContainer}>
              <Text style={themeStyles.headerText}>{formatDateFullMonthName(training?.date)}</Text>
            </View>
          </View>
          <ScrollView style={themeStyles.mainContent}>
          {training?.pdfURL && training?.pdfURL != 'undefined' && training?.pdfURL != 'null' ? (
            <S3PDFDisplay pdfUrl={training.pdfURL} />
          ) : (
            <>
              {training?.rounds?.length === 0 && (
                <View style={themeStyles.round}>
                  <Text style={themeStyles.emptyTrainingText}>Vežbe nisu popunjene</Text>
                </View>
              )}
              {training?.rounds?.map((round, roundIndex) => (
                <View key={roundIndex} style={themeStyles.round}>
                  <View style={themeStyles.roundHeader}>
                    <Text style={themeStyles.roundHeaderText}>Grupa {round?.roundSequenceNumber}</Text>
                  </View>
                  {round?.roundExerciseInRound?.map((exerciseInRound, exerciseIndex) => (
                    <View key={exerciseIndex} style={themeStyles.roundContainer}>
                      <View style={themeStyles.roundExerciseContainer}>
                        <Text style={themeStyles.roundExerciseText}>
                          {exerciseInRound?.exercise?.name} {exerciseInRound?.difficulty}
                          {exerciseInRound?.numberOfRepetitions && 'X '}
                          {exerciseInRound?.numberOfRepetitions}
                        </Text>
                        {exerciseInRound?.exercise?.exerciseGroups?.map((group, groupIndex) => (
                          <Text key={groupIndex} style={themeStyles.roundExerciseGroupText}>
                            {group.name}
                          </Text>
                        ))}
                      </View>
                    </View>
                  ))}
                </View>
              ))}
            </>
          )}
            {!isHideOpenButton &&
              <View style={themeStyles.buttonContainer}>
                <Button uppercase={true} textColor={'white'} style={themeStyles.openButton} onPress={openTraining}>Otvori ceo trening</Button>
              </View>
            }
          </ScrollView>
        </View>
      </Modal>
  )
}

const styles = theme => StyleSheet.create({
  redBackground: {
    backgroundColor: theme.colors.redMarker,
  },
  orangeBackground: {
    backgroundColor: theme.colors.orangeMarker,
  },
  yellowBackground: {
    backgroundColor: theme.colors.yellowMarker,
  },
  regularBackground: {
    backgroundColor: theme.colors.tertiaryBackground,
  },
  header: {
    flexDirection: 'row',
    height: 70,
    width: '100%',
    borderBottomColor: theme.colors.primaryText,
    borderBottomWidth: 1,
  },
  buttonHeaderContainer: {
    alignSelf: 'center',
    width: 50,
  },
  headerText: {
    color: theme.colors.primaryText,
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 22,
    fontWeight: '600',
  },
  headerTextContainer: {
    flexGrow: 1,
    alignSelf: 'center',
    marginLeft: -20,
  },
  containerStyle: {
    padding: 20,
  },
  modalContent: {
    borderRadius: 10,
    overflow: 'hidden',
    flex: 1,
  },
  mainContent: {
    flexDirection: 'column',
    backgroundColor: theme.colors.primaryBackground,
  },
  round: {
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10,
  },
  roundHeader: {
    marginBottom: 10,
  },
  roundHeaderText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 16,
    fontWeight: '600',
  },
  roundContainer: {

  },
  roundExerciseText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 5,
  },
  roundExerciseGroupText: {
    color: theme.colors.secondaryText,
    fontFamily: theme.fontFamilies.secondaryFont,
    fontSize: 10,
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  roundExerciseContainer: {
    borderTopColor: theme.colors.primaryText,
    borderTopWidth: 1,
    paddingTop: 10,
    paddingBottom: 10,
  },
  emptyTrainingText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 20,
    fontWeight: '600',
  },
  openButton: {
    backgroundColor: theme.colors.primaryText,
    color: theme.colors.lightText,
    fontFamily: theme.fontFamilies.primaryFont,
  },
  buttonContainer: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 30,
    marginBottom: 10
  }
})

export default withTheme(ShowTrainingDialog);
