import { combineReducers } from 'redux';
import errorReducer from './ErrorReducer';
import loadingReducer from './LoadingReducer';
import exerciseGroupReducer from './ExerciseGroupReducer';
import exerciseReducer from './ExerciseReducer';
import clientReducer from './ClientReducer';
import userSettingsReducer from './UserSettingsReducer';
import trainingReducer from './TrainingReducer';
import userReducer from './UserReducer';
import fitnessCenterReducer from './FitnessCenterReducer';
import authReducer from './AuthReducer';


console.log("combine reducers");
const rootReducer = combineReducers({
  loading: loadingReducer,
  error: errorReducer,
  auth: authReducer,
  exerciseGruops: exerciseGroupReducer,
  exercises: exerciseReducer,
  clients: clientReducer,
  userSettings: userSettingsReducer,
  trainings: trainingReducer,
  user: userReducer,
  fitnessCenters: fitnessCenterReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
