import { action, createRequestTypes } from '../../utils/ReduxHelpers';

import { DELETE, GET, POST, PUT } from '../../utils/Constants';
import { IUser } from '../models/Models';

export const LOGIN = createRequestTypes('LOGIN', [POST]);
export const AUTH = createRequestTypes('AUTH', [POST, PUT, DELETE]);
export const CHANGEPASSWORD = createRequestTypes('CHANGEPASSWORD', [POST]);

export const LOGOUT = 'LOGOUT';


export const login = {
  request: (emailOrUsername: string, password: string) => action(LOGIN.POST.REQUEST, { emailOrUsername, password }),
  success: (payload: any) => action(LOGIN.POST.SUCCESS, payload),
  failure: (error: string) => action(LOGIN.POST.FAILURE, error)
};


export const signUp = {
  request: (user: IUser) => action(AUTH.POST.REQUEST, { user }),
  success: (payload: any) => action(AUTH.POST.SUCCESS, payload),
  failure: (error: string) => action(AUTH.POST.FAILURE, error)
};

export const changePassword = {
  request: (oldPassword: string, password: string, username: string) => action(CHANGEPASSWORD.POST.REQUEST, { oldPassword, password, username }),
  success: (payload: any) => action(CHANGEPASSWORD.POST.SUCCESS, payload),
  failure: (error: string) => action(CHANGEPASSWORD.POST.FAILURE, error)
};

export const logout = () => action(LOGOUT, {});

