export function createFitnessCenter(name, address, phoneNumber, emailAddress, userId) {
    return `mutation {
      createFitnessCenter (
        name: "${name}"
        address: "${address}"
        phoneNumber: "${phoneNumber}"
        emailAddress: "${emailAddress}"
        usersWorkingInFitnessCenterId: "${userId}"
      ) {
        message {
          isRequestSuccessful
            message
            id
        }
        fitnessCenter {
          id
          name
          address
          phoneNumber
          emailAddress
          userFitnessCenterList {
            authorities
            fitnessCenter {
              id
              name
              address
              phoneNumber
              emailAddress
            }
          }
        }
      }
    }`;
  }

  export function updateFitnessCenter(id, name, address, phoneNumber, emailAddress, userId) {
    return `mutation {
      updateFitnessCenter (
        id: "${id}"
        name: "${name}"
        address: "${address}"
        phoneNumber: "${phoneNumber}"
        emailAddress: "${emailAddress}"
        usersWorkingInFitnessCenterId: "${userId}"
      ) {
        message {
          isRequestSuccessful
            message
            id
        }
        fitnessCenter {
          id
          name
          address
          phoneNumber
          emailAddress
          userFitnessCenterList {
            authorities
          }
        }
      }
    }`;
  }