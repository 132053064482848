import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, withTheme, Paragraph } from 'react-native-paper';
import { OutlinedPasswordInput } from './InputComponents';
import { ErrorMessage } from './MessageComponents';
import { passwordValidation } from './util/PasswordUtil';

interface IProps {
  theme: any
  password: string,
  repeatPassword: string,
  setPassword: (string) => void;
  setRepeatPassword: (string) => void;
}

const PasswordChangesComponent = ({theme, password, repeatPassword, setPassword, setRepeatPassword }:IProps) => {
    const [passwordError, setPasswordError] = useState('');
    const [errors, setErrors] = useState({
        password: false,
        repeatPassword: false,
        passwordsMatch: false,
      })

  const validatePassword = () => {
    const errMsg = passwordValidation(password);
    setPasswordError(errMsg);
    console.log(errMsg);
    let passwordsMatch = true;
    if (repeatPassword) {
      passwordsMatch = repeatPassword == password;
    }
    setErrors({...errors, password: errMsg != '', passwordsMatch: !passwordsMatch});
  }

  const onPasswordChange = (text) => {
    const errMsg = passwordValidation(text);
    console.log(errMsg)
    let passwordsMatch = true;
    if (repeatPassword) {
      passwordsMatch = repeatPassword == text;
    }
    setPasswordError(errMsg);
    setErrors({...errors, passwordsMatch: !isPasswordsMatch(text, repeatPassword)});
    setPassword(text);
  }

  const isPasswordsMatch = (password1, password2) => {
    let passwordsMatch = true;
    if (password2) {
      passwordsMatch = password1 == password2;
    }
    return passwordsMatch;
  }
  const validateRepeatedPassword = () => {
    setErrors({...errors, passwordsMatch: !isPasswordsMatch(password, repeatPassword)});
  }
  const onRepeatedPasswordChange = (text) => {
    setErrors({...errors, passwordsMatch: !isPasswordsMatch(password, text)});
    setRepeatPassword(text);
  }
  
  const themeStyles = styles(theme);

    return (
        <View>
            <View style={themeStyles.inputContainer}>
                <OutlinedPasswordInput
                    label='Lozinka'
                    value={password}
                    onChangeText={(text) => onPasswordChange(text)}
                    onBlur={validatePassword}
                    />
                <Paragraph>
                    {passwordError && <ErrorMessage text={passwordError}/>}
                </Paragraph>
            </View>
            <View style={themeStyles.inputContainer}>
                <OutlinedPasswordInput
                    label='Ponovljena lozinka'
                    value={repeatPassword}
                    onChangeText={(text) => onRepeatedPasswordChange(text)}
                    onBlur={validateRepeatedPassword}
                    />
                    <Paragraph>
                      {errors.passwordsMatch && <ErrorMessage text='Lozinke nisu iste'/>}
                    </Paragraph>
            </View>
        </View>
    )
}
    const styles = theme => StyleSheet.create({
        inputContainer: {
          marginBottom: 10,
          marginRight: 15
        }
      });

export const PasswordChanges = withTheme(PasswordChangesComponent);