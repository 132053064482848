import { IUser } from '../models/Models';
import { AppState } from '../reducers/RootReducer';
import { createSelector } from 'reselect';

const getUser = (state: AppState): IUser => state.user.user;

const getFilteredUsers = (state: AppState): [IUser] => state.user.filteredUsers;

const getFitnessCenterUsers = (state: AppState): [IUser] => state.user.fitnessCenterUsers;

const getUsersInFitnessCenter = (state: AppState): IUser[] => state.user.usersInFitnessCenter;

export const userSelector = createSelector(getUser, (user) => user);

export const filteredUsersSelector = createSelector(getFilteredUsers, (filteredUsers) => filteredUsers);

export const fitnessCenterUsersSelector = createSelector(getFitnessCenterUsers, (fitnessCenterUsers) => fitnessCenterUsers);

export const usersInFitnessCenterSelector = createSelector(getUsersInFitnessCenter, (usersInFitnessCenter) => usersInFitnessCenter);