import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import Modal from 'react-native-modal';
import { Button, withTheme } from 'react-native-paper';
import IconButton from '../IconButton'; // Adjust the import path as needed
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const FilePickerDialog = ({ theme, isVisible, onDismiss, onFileSelected }) => {
  const themeStyles = styles(theme);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onFileSelected(file);
      onDismiss();
    }
  };

  return (
    <Modal isVisible={isVisible} onDismiss={onDismiss}>
      <View style={themeStyles.modalContent}>
        <View style={themeStyles.header}>
          <View style={themeStyles.buttonHeaderContainer}>
            <IconButton width={10} icon={faXmark} iconSize={20} onPress={onDismiss} />
          </View>
          <View style={themeStyles.headerTextContainer}>
            <Text style={themeStyles.headerText}>Upload PDF-a</Text>
          </View>
        </View>
        <View style={themeStyles.mainContent}>
          <input
            type="file"
            onChange={handleFileChange}
            style={themeStyles.fileInput}
            accept=".pdf" // You can adjust the accepted file types here
          />
        </View>
      </View>
    </Modal>
  );
};

const styles = theme => StyleSheet.create({
  header: {
    flexDirection: 'row',
    height: 70,
    width: '100%',
    borderBottomColor: theme.colors.primaryText,
    borderBottomWidth: 1,
    backgroundColor: theme.colors.tertiaryBackground, // Adjust as needed
  },
  buttonHeaderContainer: {
    alignSelf: 'center',
    width: 50,
  },
  headerText: {
    color: theme.colors.primaryText,
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 20,
    fontWeight: '600',
  },
  headerTextContainer: {
    flexGrow: 1,
    alignSelf: 'center',
    marginLeft: -20,
  },
  modalContent: {
    borderRadius: 10,
    overflow: 'hidden',
    // flex: 1,
    backgroundColor: theme.colors.primaryBackground, // Adjust as needed
  },
  mainContent: {
    padding: 20,
  },
  fileInput: {
    width: '100%',
    padding: 10,
    margin: 20,
  },
});

export default withTheme(FilePickerDialog);
