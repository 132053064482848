export function removeTime(date) {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }

export function daysEqual(date1, date2) {
    return date1.getFullYear() == date2.getFullYear() &&
        date1.getMonth() == date2.getMonth() && date1.getDate() == date2.getDate()
}

export function getTime(date) {
   return date.toLocaleTimeString('en-GB').substring(0,5)
}

export function getMonday(date) {
  const monday = new Date(date.getTime());
  const day = date.getDay()
  if (day > 0){
      monday.setDate(monday.getDate() - day + 1);
      console.log(monday)
      return monday
    }
  monday.setDate(date.getDate() - 6);
  return monday
}


export function getSunday(date) {
  const sunday = new Date(date.getTime());
  const day = date.getDay()
  if (day > 0){
      sunday.setDate(sunday.getDate()  - day + 7);
      console.log(sunday)
      return sunday
  }
  return sunday
}

export function tomorrow(date) {
  const tomorrow =  new Date(date.getTime())
  tomorrow.setDate(date.getDate() + 1)
  return tomorrow;
}

export function formatDateFullMonthName(date) {
  if (!date) {
    return '';
  }
  return `${date.getDate()}. ${getLocalMonth(date)} ${date.getFullYear()}.`
}

export function formatDateLocale(date){
  if (!date){
    return '';
  }
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}.`;
}

export function formatTime(date) {
  if (!date){
    return '';
  }
  return `${date.getHours()}:${addLeadingZero(date.getMinutes())}`
}

function addLeadingZero(number) {
  return number < 10 ? '0' + number : number + ''
}

export function formatDateTimeDatabase(date){
  const hours = addLeadingZero(date.getHours());
  const minutes = addLeadingZero(date.getMinutes());

  return `${formatDateDatabase(date)}T${hours}:${minutes}:00`;
}

export function formatDateDatabase(date) {
  const month = addLeadingZero(date.getMonth() + 1);
  const day = addLeadingZero(date.getDate());
  return `${date.getFullYear()}-${month}-${day}`;
}

function getLocalMonth(date){
  //serbian locale returns cyrillic names
  const daysDict = {
    0: 'januar',
    1: 'februar',
    2: 'mart',
    3: 'april',
    4: 'maj',
    5: 'jun',
    6: 'jul',
    7: 'avgust',
    8: 'septembar',
    9: 'oktobar',
    10: 'novembar',
    11: 'decembar'
  }
  return daysDict[date.getMonth()]
}



export function isNumeric(value) {
  return /^-?\d+$/.test(value);
}
