import React, { useEffect, useState } from 'react';
import { Text, View, StyleSheet, Pressable } from 'react-native';
//import { useDispatch } from 'react-redux';
import { withTheme } from 'react-native-paper';
import {DropDownInput, OutlinedTextInput} from '../components/InputComponents';
import IconButton from '../components/IconButton';
import { faCalendarDays, faCheck, faPen, faSearch, faX } from '@fortawesome/free-solid-svg-icons';
import ClientsDialog from '../components/trainings/ClientsDialog';
import { IClient, ITraining, IUser, TrainingStatus } from '../../core/models/Models';
import { formatDateLocale, formatTime } from '../../utils/utils';
import CalendarDialog from '../components/CalendarDialog';
import { TimePickerModal } from 'react-native-paper-dates';
import { SectionHeader } from '../components/Headers';
import { userSelector } from '../../core/selectors/UserSelector';
import { useSelector } from 'react-redux';


interface Props {
  theme: any;
  clients?: IClient[];
  training: ITraining;
  onTrainingUpdated: (any) => void;
  isEditModeInitiallyOn: boolean;
  isClientInputEditable?: boolean;
  fitnessCenterUsers: IUser[];
}

const statusList = [
  {
    label: "U pripremi",
    value: TrainingStatus.DRAFT,
  },
  {
    label: "Spreman",
    value: TrainingStatus.READY,
  },
  {
    label: "Završen",
    value: TrainingStatus.DONE,
  },
{
    label: "Otkazan",
    value: TrainingStatus.CANCELED,
  },
];


const allTrainersList = (fitnessCenterUsers: IUser[]) => {
  const usersList = fitnessCenterUsers?.map((user) => {
    return {
      label: `${user?.personalName} ${user?.familyName}`,
      value: user?.id
  }})
  if (!usersList) {
    return [];
  }
  return usersList;
}

const CreateTrainingInfo = ({theme, fitnessCenterUsers, clients, training, onTrainingUpdated, isEditModeInitiallyOn, isClientInputEditable=true}: Props) => {


  const [showDropDown, setShowDropDown] = useState(false);
  const [showTrainerDropDown, setShowTrainerDropDown] = useState(false);
  const themeStyles = styles(theme);
  const [isShowClientsDialog, setIsShowClientsDialog] = useState(false);
  const [isShowDateDialog, setIsShowDateDialog] = useState(false);
  const [client, setClient] = useState(training?.client);
  const [numberOfTrainings, setNumberOfTrainings] = useState(training?.numberOfTrainings);
  const [date, setDate] = useState(training?.date);
  const [status, setStatus] = useState(training?.status);
  const [isEditModeOn, setIsEditModeOn] = useState(isEditModeInitiallyOn);
  const [isShowTimePicker, setIsShowTimePicker] = useState(false);
  const [hours, setHours] = useState(training?.date?.getHours());
  const [minutes, setMinutes] = useState(training?.date?.getMinutes());
  const [time, setTime] = useState(formatTime(training?.date))
  const user = useSelector(userSelector);
  const [executor, setExecutor] = useState(null);
  const trainersValues = allTrainersList(fitnessCenterUsers);
  const [isIndependentUser, setIsIndependentUser] = useState(false);

  useEffect(() => {
    const date = training?.date || new Date();
    setNumberOfTrainings(training?.numberOfTrainings);
    setDate(date);
    setStatus(training?.status);
    setHours(date?.getHours());
    setMinutes(date?.getMinutes())
    setTime(formatTime(date));
    setClient(training?.client);
    setExecutor(training?.trainingExecutor?.id || user?.id);
    if(user?.isIndependentUser){ // If free user then he is the executor.
      setExecutor(user?.id);
      setIsIndependentUser(true);
    }
  }, [training]);


  const findLabel = (status) => {
    return statusList.find((item) => item.value == status)?.label;
  }

  const toggleEditMode = () => {
    setIsEditModeOn(!isEditModeOn);
  }

  const openClientsDialog = () => {
    setIsShowClientsDialog(true);
  }

  const openDateDialog = () => {
    setIsShowDateDialog(true);
  }

  const closeClientsDialog = () => {
    console.log("zatvori")
    setIsShowClientsDialog(false);
  }

  const closeDateDialog = () => {
    setIsShowDateDialog(false);
  }

  const openTimePicker = () => {
    setIsShowTimePicker(true);
  };

  const hideTimePicker = () => {
    setIsShowTimePicker(false);
  };


  const handleSelectedClient = (client) => {
    setClient(client);
  }

  const handleSelectedDate = (date) => {
    const newDate = new Date(date.dateString)
    newDate.setHours(hours);
    newDate.setMinutes(minutes);
    setDate(newDate);
    setIsShowDateDialog(false);
  }

  const cancelEdits = () => {
    setClient(training?.client);
    setNumberOfTrainings(training?.numberOfTrainings);
    setDate(training?.date);
    setStatus(training?.status);
    toggleEditMode()
  }

  const saveEdits = () => {
    const trainingExecutor = fitnessCenterUsers?.find((user) => user?.id == executor);
    onTrainingUpdated(
      {client: client, numberOfTrainings: numberOfTrainings, date: date, status: status, trainingExecutor: trainingExecutor}
    )
    toggleEditMode()
  }

  const handleTimeSelected = ({hours, minutes}) => {
    setHours(hours);
    setMinutes(minutes);
    setIsShowTimePicker(false);
    const hoursStr = hours >= 10 ? hours + '' : '0' + hours;
    const minutesStr = minutes >= 10 ? minutes + '' : '0' + minutes;
    setTime(`${hoursStr}:${minutesStr}`);
    const newDate = new Date(date)
    newDate.setHours(hours);
    newDate.setMinutes(minutes);
    setDate(newDate);
  }


  return (
    <View>
      <SectionHeader text='Opšte informacije' buttonIcon={faPen} onButtonPressed={toggleEditMode}/>
      {!isEditModeOn ? (
        <View style={themeStyles.viewContainer}>
          {!isIndependentUser ? (
          <View style={themeStyles.trainingInfoText}>
            <Text style={themeStyles.viewModeLabel}>Klijent</Text>
            <Text style={themeStyles.viewModeText}>{client?.name} {client?.familyName}</Text>
          </View>) : null
          }
          <View style={themeStyles.trainingInfoText}>
            <Text style={themeStyles.viewModeLabel}>Broj treninga</Text>
            <Text style={themeStyles.viewModeText}>{numberOfTrainings}</Text>
          </View>
          <View style={themeStyles.trainingInfoText}>
            <Text style={themeStyles.viewModeLabel}>Datum</Text>
            <Text style={themeStyles.viewModeText}>{formatDateLocale(date)}</Text>
          </View>
          <View style={themeStyles.trainingInfoText}>
            <Text style={themeStyles.viewModeLabel}>Status</Text>
            <Text style={themeStyles.viewModeText}>{findLabel(status)}</Text>
          </View>
          {user?.currentFitnessCenter &&
          <View style={themeStyles.trainingInfoText}>
            <Text style={themeStyles.viewModeLabel}>Izvršilac</Text>
            <Text style={themeStyles.viewModeText}>{training?.trainingExecutor?.personalName} {training?.trainingExecutor?.familyName}</Text>
          </View>
          }
        </View>
      ) : (
      <View>
        <View style={[themeStyles.row]}>
          <View style={themeStyles.inputContainer}>
            <View style={themeStyles.numberContainer}>
              <OutlinedTextInput
                label='Rbr.'
                value={numberOfTrainings?.toString()}
                inputType="numeric"
                onChangeText={(text) => setNumberOfTrainings(text)}
              />
            </View>
          </View>
          <View style={themeStyles.inputContainer}>
            <Pressable style={themeStyles.dateContainer}  onPress={openDateDialog}>
              <OutlinedTextInput
                label="Datum"
                value={formatDateLocale(date)}
                editable={false}
              />
            </Pressable>
          </View>
          <View style={themeStyles.inputContainer}>
            <Pressable style={themeStyles.dateContainer}  onPress={openTimePicker}>
              <OutlinedTextInput
                label="Vreme"
                value={time}
                editable={false}
              />
            </Pressable>
          </View>
        </View>
        {user?.isIndependentUser ? (<View></View>) : (
        <View style={themeStyles.inputContainer}>
          {isClientInputEditable ? (
            <Pressable style={themeStyles.fullWidthInput} onPress={openClientsDialog}>
              <OutlinedTextInput
                label="Klijent"
                value={client ? `${client?.name} ${client?.familyName}` : ''}
                editable={false}
              />
            </Pressable>
          ) : (
          <OutlinedTextInput
            label="Klijent"
            value={client ? `${client?.name} ${client?.familyName}` : ''}
            editable={false}
          />
          )}
        </View>)}
        <View style={themeStyles.inputContainer}>
          <DropDownInput
            label={"Status treninga"}
            isDropdownVisible={showDropDown}
            toggleDropDown={setShowDropDown}
            value={status}
            onValueChanged={(value) => setStatus(value)}
            valuesList={statusList}
          />
        </View>
        { user?.isIndependentUser ? (<View></View>):(
        <View style={themeStyles.inputContainer}>
          <DropDownInput
            label={"Izvršilac"}
            isDropdownVisible={showTrainerDropDown}
            toggleDropDown={setShowTrainerDropDown}
            value={executor}
            onValueChanged={(value) => setExecutor(value)}
            valuesList={trainersValues}
          />
        </View>)}
        <View style={themeStyles.editButtonContainer}>
          {isEditModeOn &&
            <View style={themeStyles.buttonsContainer}>
              <IconButton iconSize={25} width={50} icon={faCheck} onPress={saveEdits}/>
              <IconButton iconSize={25} width={50} icon={faX} onPress={cancelEdits}/>
            </View>
          }
        </View>
        <ClientsDialog clients={clients || []} isDialogVisible={isShowClientsDialog} onClientSelected={handleSelectedClient} onDismiss={closeClientsDialog} />
        <CalendarDialog isDialogVisible={isShowDateDialog} onDateSelected={handleSelectedDate} hideDialog={closeDateDialog} />
        <TimePickerModal
          visible={isShowTimePicker}
          onDismiss={hideTimePicker}
          onConfirm={(time) => handleTimeSelected(time)}
          hours={hours}
          minutes={minutes}
          locale={'de'}
          label={''}
          cancelLabel={'Otkaži'}
          confirmLabel={'Potvrdi'}
        />
      </View>
    )}
  </View>
  )
}


const styles = theme => StyleSheet.create({
  root: {
    flex: 1,
  },
  horizontalContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  fullWidthInput: {
    width: '100%'
  },
  numberContainer: {
    width: 100,
  },
  dateContainer: {
    width: 120,
  },
  row: {
    flexDirection: "row",
    marginRight: 20,
    flexWrap: 'wrap',
  },
  inputBorderStyle: {
    borderRadius: 5,
    borderColor: theme.colors.primaryText
  },
  inputContainer: {
    marginBottom: 10,
    marginRight: 15
  },
  buttonsContainer: {
    flexDirection: 'row',
    alignContent: 'flex-end',
  },
  viewModeText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 15,
    fontWeight: '600',
    marginTop: 5,
    marginBottom: 5,
  },
  viewModeLabel: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.secondaryFont,
    fontSize: 12,
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  editButtonContainer: {
    alignItems: 'flex-end',
    marginTop: 10,
    marginBottom: 10,
  },
  viewContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 10,
    flexWrap: 'wrap',
  },
  updateButton: {
    backgroundColor: theme.colors.primaryText,
    color: theme.colors.lightText,
    fontFamily: theme.fontFamilies.primaryFont,
  },
  buttonIcon: {
    alignContent: 'center',
    justifyContent: 'center',
    height: 'auto',
  },
  trainingInfoText: {
    marginTop: 10,
    marginRight: 10,
  }

})

export default withTheme(CreateTrainingInfo);

