import { faXmark } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text, ScrollView,  } from 'react-native';
import Modal from 'react-native-modal/dist/modal';
import { Button, List, withTheme } from 'react-native-paper';
import { IUser, IUserFitnessCenter } from '../../../core/models/Models';
import IconButton from '../IconButton';
import { IconTextInput } from '../InputComponents';
import { createUserFitnessCenter, findUsersByEmail } from '../../../core/actions/UserActions';
import { useDispatch, useSelector } from 'react-redux';
import { filteredUsersSelector } from '../../../core/selectors/UserSelector';


interface Props {
  theme: any;
  user: IUser;
  isDialogVisible: boolean;
  onUserSelected: (any) => void;
  onDismiss: () => void;
}


const roles = [
  {'name': 'Admin', 'description': 'Pun pristup svim funkcionalnostima'},
  {'name': 'Trener', 'description': 'Mogućnost kreiranja treninga bez prava na ažuriranje studija'}
]

const AddUserToCenterDialog = ({theme, user, isDialogVisible, onUserSelected, onDismiss}: Props) => {
  const themeStyles = styles(theme);
  const [searchQuery, setSearchQuery] = useState('');
  const foundUsers = useSelector(filteredUsersSelector);
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchQuery) {
        dispatch(findUsersByEmail.request(searchQuery));
      }
    }, 1500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchQuery])


  useEffect(() => {
    setSelectedUser(null);
    setSelectedRole(null);
  }, [foundUsers]);


  const removeSelectedUser = () => {
    setSelectedUser(null);
    setSelectedRole(null);
  }

  const handleCancel = () => {
    onDismiss();
  }

  const handleSave = () => {
    const role = selectedRole?.name == 'Admin' ? 'ADMIN' : 'TRAINER';
    const userFitnessCenter: IUserFitnessCenter = {
      authorities: role,
      fitnessCenter: user?.currentFitnessCenter,
      user: selectedUser
    }
    dispatch(createUserFitnessCenter.request(userFitnessCenter));
    onDismiss();
  }

  return (
    <Modal isVisible={isDialogVisible} onDismiss={onDismiss}>
      <View style={themeStyles.modalContent}>
        <View style={[themeStyles.header, themeStyles.regularBackground]}>
          <View style={themeStyles.buttonHeaderContainer}>
            <IconButton width={10} icon={faXmark} iconSize={20} onPress={onDismiss}/>
          </View>
          <View style={themeStyles.headerTextContainer}>
            <Text style={themeStyles.headerText}>Dodavanje korisnika u {user?.currentFitnessCenter?.name}</Text>
          </View>
        </View>
        <ScrollView style={themeStyles.mainContent}>
          <View style={themeStyles.mainContentContainer}>
            {!selectedUser ? (
              <View>
                <IconTextInput
                  label="Korisničko ime korisnika"
                  value={searchQuery}
                  onChangeText={(text) => setSearchQuery(text)}
                  icon={'magnify'}
                />
                <View style={themeStyles.contentContainer}>
                  {foundUsers?.map((user) =>
                    <List.Item
                      title={`${user.personalName} ${user.familyName} [${user.userName}]`}
                      onPress={() => setSelectedUser(user)}
                      right={props => <List.Icon {...props} icon='plus'/>}
                      titleStyle={themeStyles.listItem}
                    />
                  )}
                </View>
              </View>
            ) : (
              <View style={themeStyles.contentContainer}>
                <List.Item
                  title={`${selectedUser.personalName} ${selectedUser.familyName}`}
                  titleStyle={themeStyles.listItem}
                  onPress={removeSelectedUser}
                  right={props => <List.Icon {...props} icon='close'/>}
                  style={themeStyles.selectedUser}
                />
                <View style={themeStyles.contentContainer}>
                  <View style={themeStyles.rolesHeading}>
                    <Text style={themeStyles.rolesHeadingText}>
                      Uloga korisnika
                    </Text>
                  </View>
                  {roles?.map((role) =>
                    <List.Item
                      title={role['name']}
                      description={role['description']}
                      onPress={() => setSelectedRole(role)}
                      left={props => <List.Icon {...props} icon={selectedRole == role ? 'circle': 'circle-outline'}/>}
                      titleStyle={themeStyles.listItem}
                    />
                  )}
                </View>
              </View>
            )
            }
            <View style={themeStyles.buttonContainer}>
              <Button disabled={selectedRole == null} uppercase={true} textColor={theme.colors.lightText} style={themeStyles.saveButton} onPress={handleSave}>Snimi</Button>
              <Button uppercase={true} textColor={theme.colors.primaryText} style={themeStyles.cancelButton} onPress={handleCancel}>Otkaži</Button>
            </View>
          </View>
        </ScrollView>
      </View>
    </Modal>
  )
}

const styles = theme => StyleSheet.create({
  regularBackground: {
    backgroundColor: theme.colors.tertiaryBackground,
  },
  header: {
    flexDirection: 'row',
    height: 70,
    width: '100%',
    borderBottomColor: theme.colors.primaryText,
    borderBottomWidth: 1,
  },
  listItem: {
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 16,
  },
  descriptionStyle: {
    fontFamily: theme.fontFamilies.secondaryFont,
    fontSize: 14,
  },
  selectedUser: {
    backgroundColor: '#ccd3c4',
    borderColor: theme.colors.tertiaryBackground,
    borderWidth: 2,
    borderRadius: 15,
  },
  contentContainer: {
    marginTop: 20,
  },
  rolesHeading: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
    borderBottom: theme.colors.primaryText,
    borderBottomWidth: 1,
  },
  rolesHeadingText: {
    fontFamily: theme.fontFamilies.secondaryFont,
    fontColor: theme.colors.primaryFont,
    paddingBottom: 10,
    fontSize: 16,
  },
  buttonHeaderContainer: {
    alignSelf: 'center',
    width: 50,
  },
  mainContentContainer: {
    margin: 15,
  },
  headerText: {
    color: theme.colors.primaryText,
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 20,
    fontWeight: '600',
    flexWrap: 'wrap',
  },
  headerTextContainer: {
    flexGrow: 1,
    alignSelf: 'center',
    marginLeft: -20,
    flex: 1,
  },
  containerStyle: {
    padding: 20,
  },
  modalContent: {
    borderRadius: 10,
    overflow: 'hidden',
  },
  mainContent: {
    flexDirection: 'column',
    backgroundColor: theme.colors.primaryBackground,
  },
  buttonContainer: {
    marginTop: 20,
    paddingBottom: 30
  },
  saveButton: {
    backgroundColor: theme.colors.tertiaryBackground,
    color: theme.colors.lightText,
    fontFamily: theme.fontFamilies.primaryFont,
    marginTop: 20,
    marginBottom: 10,
  },
  cancelButton: {
    backgroundColor: theme.colors.lightText,
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    borderWidth: 1,
    borderColor: theme.colors.primaryText,
  },
})

export default withTheme(AddUserToCenterDialog);
