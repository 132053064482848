import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { useState } from 'react';
import { View, StyleSheet, Text, Pressable } from 'react-native';
import { Card, withTheme } from 'react-native-paper';
import { IExerciseInRound } from '../../../core/models/Models';


interface Props {
  theme: object;
  exerciseInRound: IExerciseInRound;
  onCardPressed: () => void;
}

// TODO on click on exercise, show full
const ExerciseInRoundCard = ({theme, exerciseInRound, onCardPressed}: Props) => {

  const [isShowNotes, setIsShowNotes] = useState(false);

  const toggleNotes = () => {
    setIsShowNotes(!isShowNotes);
  }

  const themeStyles = styles(theme);
  return (
    <Pressable onPress={onCardPressed} key={`exercise_round_${exerciseInRound?.exercise?.name}`}>
      <Card style={themeStyles.card}>
        <Card.Content style={themeStyles.trainingCard}>
          <View style={themeStyles.exerciseInRoundContainer}>
            <View style={themeStyles.textInContainer}>
              <Text style={themeStyles.exerciseGroupText}>{exerciseInRound?.exercise?.exerciseGroup?.name}</Text>
              <Text style={themeStyles.exerciseText}>{exerciseInRound?.exercise?.name} {exerciseInRound?.difficulty} {exerciseInRound?.numberOfRepetitions && ('X '+ exerciseInRound?.numberOfRepetitions)}</Text>
            </View>
            {(!isShowNotes && exerciseInRound?.note) ? (
              <Pressable style={themeStyles.toggleInfo} onPress={toggleNotes}>
                <FontAwesomeIcon size={10} icon={faChevronDown} style={themeStyles.toggleIcon}/>
                <Text style={themeStyles.showInstructions}>Prikaži instrukcije</Text>
              </Pressable>
            ) : (exerciseInRound?.note ? (
                <Pressable onPress={toggleNotes}>
                  <View style={themeStyles.toggleInfo}>
                    <FontAwesomeIcon size={10} icon={faChevronUp} style={themeStyles.toggleIcon} onPress={toggleNotes}/>
                    <Text style={themeStyles.showInstructions}>Skloni instrukcije</Text>
                  </View>
                  <View style={themeStyles.textInContainer}>
                    <Text>
                      {exerciseInRound?.note}
                    </Text>
                  </View>
                </Pressable>
            ) : null
            )}
          </View>
        </Card.Content>
      </Card>
    </Pressable>
  )
}

export default withTheme(ExerciseInRoundCard);


const styles = theme => StyleSheet.create({


  card: {
    margin: 10
  },
  textInContainer: {
    flex: 1,
  },
  exerciseInRoundContainer: {
    // marginBottom: 10,
    // marginTop: 5,
    // flex: 1,
    // flexWrap: 'wrap',
    flex: 1,
  },
  toggleInfo: {
    marginTop: 5,
    marginBottom: 5,
    marginRight: 5,
    flexDirection: 'row',
    // justifyContent: 'center',
    alignItems: 'center',
  },
  toggleIcon: {
    marginTop: 3,
    color: theme.colors.tertiaryBackground,
    marginRight: 3,
  },
  exerciseGroupText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.secondaryFont,
    fontSize: 11,
    fontWeight: '400',
    textTransform: 'uppercase',
    flexShrink: 1,
    flexWrap: 'wrap',
  },
  exerciseText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 20,
    fontWeight: '600',
    // marginTop: 5,
    // marginBottom: 5,
    flexShrink: 1,
    flexWrap: 'wrap',
  },
  noteText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.secondaryFont,
    fontSize: 12,
    fontWeight: '400',
    borderBottomWidth: 5,
  },
  showInstructions: {
    marginTop: 5,
    color: theme.colors.tertiaryBackground,
    fontFamily: theme.fontFamilies.secondaryFont,
    fontSize: 12,
    fontWeight: '700',
  },
  trainingCard: {
    minHeight: 100,
    backgroundColor: theme.colors.primaryBackground,
    boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row'
  }
})
