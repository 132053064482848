import React, { useEffect, useState } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { withTheme } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { createExercise } from '../../core/actions/ExerciseActions';
import BottomMenu from '../components/BottomMenu';
import CreateEditExerciseInfo from './CreateEditExerciseInfo';
import { userSelector } from '../../core/selectors/UserSelector';


const CreateExerciseContainer = ({theme, navigation}) => {

  const themeStyles = styles(theme);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const onSaveExercise = (exercise) => {
    dispatch(createExercise.request(exercise, user?.currentFitnessCenter?.id));
    navigation.navigate('ViewExercises');
  }

  const onCancel = () => {
    navigation.navigate('ViewExercises');
  }


  return (
    <View style={themeStyles.root}>
      <ScrollView style={themeStyles.mainContent}>
        <CreateEditExerciseInfo onSavePressed={onSaveExercise} onCancelPressed={onCancel}/>
      </ScrollView>
      <BottomMenu navigation={navigation} currentScreen='Exercises'/>
    </View>
  )
}

const styles = theme => StyleSheet.create({
  mainContent: {
    backgroundColor: '#ffffff',
    margin: 20,
  },
  root: {
    flex: 1
  }
});

export default withTheme(CreateExerciseContainer);

