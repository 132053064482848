import React from "react";
import { View } from "react-native";
import IconFA from 'react-native-vector-icons/FontAwesome';

export const IconInCircle = ({ circleSize, borderWidth = 2, borderColor = 'black', ...props}) => (
    <CircleBorder
      size={circleSize}
      borderWidth={borderWidth}
      borderColor={borderColor}
    >
      <IconFA {...props} />
    </CircleBorder>
  );
  
  const CircleBorder = ({ size, borderWidth, borderColor, children }) => (
    <View
      style={{
        width: size,
        height: size,
        borderRadius: 0.5 * size,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor,
        borderWidth,
      }}>
      {children}
    </View>
  );