import React, { useState, useEffect } from 'react';
import { View, StyleSheet, ScrollView, Platform } from 'react-native';
import { Button, TouchableRipple, withTheme  } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { createTraining, getClientsPreviousTrainings, updateTrainingAction, getIndependentUsersPreviousTrainings} from '../../core/actions/TrainingActions';
import { useRoute } from '@react-navigation/native';

import { getNewTrainingClientsPreviousTrainingsSelector, getNewTrainingSelector} from '../../core/selectors/TrainingSelector';
import {getExerciseGroups} from '../../core/actions/ExerciseGroupActions';
import {getExercises} from '../../core/actions/ExerciseActions';
import ConfirmationDialog from '../components/ConfirmationDialog';
import { faArrowUpRightFromSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../components/IconButton';
import { SectionHeader } from '../components/Headers';
import PreviousTrainingCard from '../components/trainings/PreviousTrainingCard';
import RoundCard from '../components/trainings/RoundCard';
import CreateTrainingInfo from './CreateTrainingInfo';
import BottomMenu from '../components/BottomMenu';
import { getExercisesSelector } from '../../core/selectors/ExerciseSelector';
import { getExerciseGroupsSelector } from '../../core/selectors/ExerciseGroupSelectors';
import { getClients } from '../../core/actions/ClientActions';
import { getClientsSelector } from '../../core/selectors/ClientSelector';
import { emptyTraining } from '../../core/reducers/TrainingReducer';
import { fitnessCenterUsersSelector, userSelector, usersInFitnessCenterSelector } from '../../core/selectors/UserSelector';
import { findUsersByFitnessCenter } from '../../core/actions/UserActions';
import FilePickerDialog from '../components/trainings/FilePickerDialog';
import S3PDFDisplay from '../components/S3PDFDisplay';


const CreateTrainingContainer = ({theme, navigation}) => {

  const route = useRoute();
  const dispatch = useDispatch();
  const training = useSelector(getNewTrainingSelector);
  const [currentRound, setCurrentRound] = useState(1);
  const [isShowConfirmDialogVisible, setIsShowConfirmDialogVisible] = useState(false);
  const clientsTrainings = useSelector(getNewTrainingClientsPreviousTrainingsSelector);

  const exercises = useSelector(getExercisesSelector);
  const exerciseGroups = useSelector(getExerciseGroupsSelector);
  const clients = useSelector(getClientsSelector);
  const themeStyles = styles(theme);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [isAddRoundEnabled, setIsAddRoundEnabled] = useState(training?.client != undefined);
  const user = useSelector(userSelector);
  const fitnessCenterUsers = useSelector(usersInFitnessCenterSelector);

  const [selectedFile, setSelectedFile] = useState(null);
  const [isPickerVisible, setPickerVisible] = useState(false);

  const handleFileSelected = (file) => {
    if (file) {
      const newTraining = {...training, pdfFile: file};
      dispatch(updateTrainingAction(newTraining, 'create'));
    }

  };

  useEffect(() => {
    if (!fitnessCenterUsers) {
      dispatch(findUsersByFitnessCenter.request(user?.currentFitnessCenter?.id))
    }
  }, [])


  useEffect(() => {
    if (route?.params && "client" in route?.params) {
      const clientParam = route?.params["client"];
      const newTraining = {...emptyTraining, client: clientParam};
      dispatch(updateTrainingAction(newTraining, 'create'));
    }
  }, [route?.params]);


  useEffect(() => {
    if (clientsTrainings?.length) {
      const lastTrainingNumber = clientsTrainings.length ? clientsTrainings[0]?.numberOfTrainings + 1: 1;
      const newTraining = {...training, numberOfTrainings: lastTrainingNumber}
      dispatch(updateTrainingAction(newTraining, 'create'));
    }
  }, [clientsTrainings]);


  useEffect(() => {
    if (training?.client && training?.date) {
      dispatch(getClientsPreviousTrainings.request(training?.client?.id, training?.date, 'create'));
    }
    if (user?.isIndependentUser && training?.date) {
      dispatch(getIndependentUsersPreviousTrainings.request(user?.id, training?.date, 'create'));
    }
  }, [training?.client, training?.date]);

  useEffect(() => {
    if (!exercises?.length) {
      if(!user.isIndependentUser){
        dispatch(getExercises.request(user?.currentFitnessCenter?.id));//TODO add if check for isShared
      } else {
        dispatch(getExercises.request(null)); //If user is independent then
      }
    }
    if (!exerciseGroups?.length) {
      dispatch(getExerciseGroups.request());
    }
    if (!clients?.length) {
      if(!user?.isIndependentUser){
        dispatch(getClients.request(user?.currentFitnessCenter?.id));
      }
    }
  }, []);

  useEffect(() => {
    if(training?.client && !user.isIndependentUser){
      setIsAddRoundEnabled(true);
    } else {
      setIsAddRoundEnabled(user?.currentFitnessCenter == undefined);
    }
    if(!user.isIndependentUser){
    const isValid = training?.client && training?.date && training?.numberOfTrainings > 0 &&
     (training?.rounds.length > 0 || training?.pdfFile != null);
    setIsSaveButtonEnabled(isValid);
    } else {
     const isValid = (user.isIndependentUser) && training?.date && training?.numberOfTrainings > 0 &&
     (training?.rounds.length > 0 || training?.pdfFile != null);
    setIsSaveButtonEnabled(isValid);
  }
  }, [training]);

  const onTrainingUpdated = (valueProp) => {

    const newTraining = {...training, ...valueProp}
    dispatch(updateTrainingAction(newTraining, 'create'));
  }

  const removeRound = (round) => {
    const newRounds = training?.rounds.filter((item) => item?.roundSequenceNumber != round?.roundSequenceNumber);
    const newTraining = {...training, rounds: newRounds};
    dispatch(updateTrainingAction(newTraining, 'create'));
    setIsShowConfirmDialogVisible(false);
  }

  const onDeletePressed = (round) => {
    setCurrentRound(round);
    setIsShowConfirmDialogVisible(true);
  }

  const openAddUpdateNewRound = () => {
    const roundNumber = training?.rounds ? training.rounds.length + 1 : 1
    const newRound = {
      roundSequenceNumber: roundNumber,
      roundExerciseInRound: []
    }
    const newRounds = [...training.rounds, newRound];
    const newTraining = {...training, rounds: newRounds};
    dispatch(updateTrainingAction(newTraining, 'create'));

    navigation.navigate('AddUpdateRound', {roundNumber: roundNumber, mode: 'create'});
  }

  const openEditAddNewRound = (round) => {
    navigation.navigate('AddUpdateRound', {roundNumber: round?.roundSequenceNumber, mode: 'create'});
  }

  const handleSaveTraining = () => {
    const newTraining = {...training, trainingCreator: user}
    if(!user.isIndependentUser){
      dispatch(createTraining.request(newTraining, user?.currentFitnessCenter?.id));
    } else {
      dispatch(createTraining.request(newTraining, null));
    }

    navigation.navigate('ViewTrainings');
  }

  const openPreviousTrainings = () => {
    navigation.navigate('PreviousTrainings', {mode: 'create'});
  }

  const handleRemovePDF = () => {
    const newTraining = {...training, pdfFile: null};
    dispatch(updateTrainingAction(newTraining, 'create'));
  }

  return (
    <View style={themeStyles.root}>
      <ScrollView style={themeStyles.mainContent}>
        <CreateTrainingInfo fitnessCenterUsers={fitnessCenterUsers} clients={clients} training={training} onTrainingUpdated={onTrainingUpdated} isEditModeInitiallyOn={true}/>
        {clientsTrainings?.length ? (
          <TouchableRipple onPress={openPreviousTrainings}>
            <View style={themeStyles.previousTrainingsHeader}>
              <SectionHeader text='Prethodni treninzi'/>
              <View style={themeStyles.previousTrainingButton}>
                <IconButton icon={faArrowUpRightFromSquare} onPress={openPreviousTrainings}/>
              </View>
            </View>
          </TouchableRipple>
          ): null
        }
        <View style={themeStyles.previousTrainingsContainer}>
          {clientsTrainings.slice(0, 3).map((training, index) =>
            <PreviousTrainingCard isHideOpenButton={true} navigation={navigation} training={training} previousTrainingNumber={index + 1} height={120} key={index}/>
          )}
        </View>


        {training?.pdfFile ? (
          <View>
            <S3PDFDisplay pdfFile={training.pdfFile} />
          </View>
        ) :
          training?.rounds?.map((round) =>
            <View key={round.id}>
              <View style={themeStyles.editRemoveButtons}>
                <IconButton  width={50} icon={faTrash} onPress={() => {onDeletePressed(round)}}/>
              </View>
              <RoundCard round={round} onCardPressed={() => openEditAddNewRound(round)}/>
            </View>
        )}
        {isAddRoundEnabled &&
          <View>
            {!training?.pdfFile ? (
              <View style={themeStyles.buttonContainer}>
                <Button uppercase={true} textColor={'white'} style={themeStyles.addRoundButton} onPress={openAddUpdateNewRound}>Dodaj grupu</Button>
              </View>
            ): (
              <View style={themeStyles.buttonContainer}>
                <Button uppercase={true} textColor={'white'} style={themeStyles.addRoundButton} onPress={handleRemovePDF}>Ukloni PDF</Button>
              </View>
            )}
            { Platform.OS == 'web' && (!training?.rounds || !training?.rounds.length)  && (
               <View style={themeStyles.buttonContainer}>
                 <Button uppercase={true} textColor={'white'} style={themeStyles.addRoundButton} onPress={() => setPickerVisible(true)} >Dodaj PDF</Button>
               </View>
             )}
              <View style={themeStyles.buttonContainerLast}>
                <Button uppercase={true} textColor={'white'} onPress={handleSaveTraining} disabled={!isSaveButtonEnabled} style={themeStyles.saveTrainingButton}>Snimi</Button>
              </View>
          </View>
        }
      </ScrollView>
      <BottomMenu navigation={navigation} currentScreen='Training'/>
      <ConfirmationDialog
        title="Potvrda"
        text={`Da li ste sigurni da želite da izbrišete grupu?`}
        isOpen={isShowConfirmDialogVisible}
        hideDialog={() => setIsShowConfirmDialogVisible(false)}
        onCofirmClicked={() => removeRound(currentRound)}
      />
      <FilePickerDialog
        isVisible={isPickerVisible}
        onDismiss={() => setPickerVisible(false)}
        onFileSelected={handleFileSelected}
      />
    </View>
  )
}


const styles = theme => StyleSheet.create({
  previousTrainingsContainer: {
    flexWrap: 'wrap',
  },
  root: {
    flex: 1,
    backgroundColor: theme.colors.lightText,
  },
  mainContent: {
    backgroundColor: '#ffffff',
    margin: 20,
    marginBottom: 80,
  },
  headerText: {
    fontFamily: theme.fontFamilies.primaryFont,
    color: theme.colors.primaryText,
    fontSize: 18,
    textAlign: 'center'
  },
  hederSaveText: {
    fontFamily: theme.fontFamilies.secondaryFont,
    color: theme.colors.primaryText,
    fontSize: 16,
    textAlign: 'right'
  },
  addRoundButton: {
    backgroundColor: theme.colors.primaryText,
    color: theme.colors.lightText,
    fontFamily: theme.fontFamilies.primaryFont,
  },
  headerContainer: {
    height: 36,
    justifyContent: 'center',
    flex: 8,
  },
  saveButton: {
    marginTop: 5,
    marginLeft: 'auto'
  },
  inputStyle: {
    margin: 5
  },
  buttonContainer: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 20,
  },
  buttonContainerLast: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 20,
    paddingBottom: 30
  },
  saveTrainingButton: {
    backgroundColor: theme.colors.tertiaryBackground,
    fontFamily: theme.fontFamilies.primaryFont,
  },
  editRemoveButtons: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    width: "100%",
    margin: 5,
  },
  previousTrainingsHeader: {
    flexDirection: 'row',
  },
  previousTrainingButton: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: -10,
  },
})

export default withTheme(CreateTrainingContainer);
