import AWS from '../config/awsConfig'; // Adjust the import path
import { v4 as uuidv4 } from 'uuid';


export const uploadTrainingToS3 = async (training, fitnessCenterId, file) => {

  const s3 = new AWS.S3();

  // AWS credentials should be already set in awsConfig
  const randomName = uuidv4();
  const key = `trainings/center/${fitnessCenterId}/client/${training.client.id}/${training.numberOfTrainings}/${randomName}`
  const params = {
    Bucket: 'fitness-users-data',
    Key: key,
    Body: file,
    ContentType: file.type,
    ACL: 'private'
  };

  try {
    const data = await s3.upload(params).promise();
    console.log('File uploaded successfully', data);
    return data;
  } catch (err) {
    console.error('File upload error', err);
    throw err;
  }
};


export const getFileUrl = async (bucket, key) => {
  const s3 = new AWS.S3();
  const params = {
    Bucket: bucket,
    Key: key,
    Expires: 86400 // URL expiration time in seconds
  };

  return new Promise((resolve, reject) => {
    s3.getSignedUrl('getObject', params, (err, url) => {
      if (err) {
        reject(err);
      } else {
        resolve(url);
      }
    });
  });
};
