import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Text, ScrollView } from 'react-native';
import { Button, TouchableRipple, withTheme } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { updateTraining, deleteTraining, updateTrainingAction, createRound, deleteRound, getClientsPreviousTrainings, resetClientsPreviousTrainings, uploadPDF} from '../../core/actions/TrainingActions';

import {getClientsPreviousTrainingsSelector, getCurrentTrainingSelector} from '../../core/selectors/TrainingSelector';
import {getExerciseGroups} from '../../core/actions/ExerciseGroupActions';
import {getExercises} from '../../core/actions/ExerciseActions';
import ConfirmationDialog from '../components/ConfirmationDialog';
import { faArrowUpRightFromSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../components/IconButton';
import { SectionHeader } from '../components/Headers';
import PreviousTrainingCard from '../components/trainings/PreviousTrainingCard';
import RoundCard from '../components/trainings/RoundCard';
import CreateTrainingInfo from './CreateTrainingInfo';
import BottomMenu from '../components/BottomMenu';
import { getExercisesSelector } from '../../core/selectors/ExerciseSelector';
import { getExerciseGroupsSelector } from '../../core/selectors/ExerciseGroupSelectors';
import { getClients } from '../../core/actions/ClientActions';
import { getClientsSelector } from '../../core/selectors/ClientSelector';
import { emptyTraining } from '../../core/reducers/TrainingReducer';
import { fitnessCenterUsersSelector, userSelector, usersInFitnessCenterSelector } from '../../core/selectors/UserSelector';
import { findUsersByFitnessCenter } from '../../core/actions/UserActions';
import FilePickerDialog from '../components/trainings/FilePickerDialog';
import S3PDFDisplay from '../components/S3PDFDisplay';



const EditTrainingContainer = ({theme, navigation}) => {

  const dispatch = useDispatch();
  const training = useSelector(getCurrentTrainingSelector);

  const [currentRound, setCurrentRound] = useState(1);
  const [isShowConfirmDialogVisible, setIsShowConfirmDialogVisible] = useState(false);
  const [isShowDeleteTrainingConfirmDialog, setIsShowDeleteTrainingConfirmDialog] = useState(false);
  const clientsTrainings = useSelector(getClientsPreviousTrainingsSelector);

  const exercises = useSelector(getExercisesSelector);
  const exerciseGroups = useSelector(getExerciseGroupsSelector);
  const clients = useSelector(getClientsSelector);
  const themeStyles = styles(theme);
  const user = useSelector(userSelector);
  const fitnessCenterUsers = useSelector(usersInFitnessCenterSelector);
  const [isPickerVisible, setPickerVisible] = useState(false);

  const handleFileSelected = (file) => {
    console.log(file);
    dispatch(uploadPDF.request(training, user?.currentFitnessCenter?.id, file));
  };


  useEffect(() => {
    if (!fitnessCenterUsers) {
      dispatch(findUsersByFitnessCenter.request(user?.currentFitnessCenter?.id))
    }
  }, [])


  useEffect(() => {
    if (training?.client) {
      dispatch(getClientsPreviousTrainings.request(training?.client?.id, training?.date));
    }
  }, [training?.client, training?.date]);


  useEffect(() => {
    if (!exercises?.length) {
      if(!user.isIndependentUser){
          dispatch(getExercises.request(user?.currentFitnessCenter?.id)); //TODO add if check for isShared
      } else {
          dispatch(getExercises.request(null)); //If user is independent then 
        }
    }
    if (!exerciseGroups?.length) {
      dispatch(getExerciseGroups.request());
    }
    if (!clients?.length) {
      if(!user.isIndependentUser){ //If User has no fitness centers then he can not have clients.
        dispatch(getClients.request(user?.currentFitnessCenter?.id));
      }
    }
  }, []);


  const onTrainingUpdated = (valueProp) => {
    const updatedTraining = {...training, ...valueProp}
    dispatch(updateTraining.request(updatedTraining));
  }

  const removeRound = (round) => {
    dispatch(deleteRound.request(round.id));
    setIsShowConfirmDialogVisible(false);
  }

  const onDeletePressed = (round) => {
    setCurrentRound(round);
    setIsShowConfirmDialogVisible(true);
  }

  const openAddUpdateNewRound = () => {
    const roundNumber = training?.rounds ? training.rounds.length + 1 : 1
    console.log(roundNumber);
    const round = {
      roundSequenceNumber: roundNumber,
      roundExerciseInRound: []
    }
    dispatch(createRound.request(round, training.id));

    navigation.navigate('AddUpdateRound', {roundNumber: roundNumber, mode: 'edit'});
  }

  const openEditAddNewRound = (round) => {
    navigation.navigate('AddUpdateRound', {roundNumber: round?.roundSequenceNumber, mode: 'edit'});
  }

  const removeTraining = () => {
    setIsShowDeleteTrainingConfirmDialog(false);
    dispatch(deleteTraining.request(training))
    navigation.navigate('ViewTrainings');
  }


  const handleCopyTraining = () => {
    const newTraining = {...emptyTraining, rounds: training?.rounds};
    dispatch(updateTrainingAction(newTraining, 'create'));
    dispatch(resetClientsPreviousTrainings());
    navigation.navigate("CreateTrainingContainer")
  }

    const openPreviousTrainings = () => {
    navigation.navigate('PreviousTrainings', {mode: 'edit'});
  }

  return (
    <View style={themeStyles.root}>
      <ScrollView style={themeStyles.mainContent}>
        <CreateTrainingInfo fitnessCenterUsers={fitnessCenterUsers} isClientInputEditable={false} training={training} onTrainingUpdated={onTrainingUpdated} isEditModeInitiallyOn={false}/>
        {clientsTrainings?.length ? (
          <TouchableRipple onPress={openPreviousTrainings}>
            <View style={themeStyles.previousTrainingsHeader}>
              <SectionHeader text='Prethodni treninzi'/>
              <View style={themeStyles.previousTrainingButton}>
                <IconButton icon={faArrowUpRightFromSquare} onPress={openPreviousTrainings}/>
              </View>
            </View>
          </TouchableRipple>
          ): null
        }
        <View style={themeStyles.previousTrainingsContainer}>
          {clientsTrainings.slice(0, 3).map((training, index) =>
            <PreviousTrainingCard navigation={navigation} training={training} key={index} previousTrainingNumber={index + 1} height={120}/>
          )}
        </View>

        {training?.pdfURL && training?.pdfURL != 'undefined' && training?.pdfURL != 'null' ? (
          <View style={themeStyles.pdfView}>
            <S3PDFDisplay pdfUrl={training.pdfURL} />
          </View>
          ) : (
            training?.rounds?.map((round, index) => (
              <View key={index}>
                <View style={themeStyles.editRemoveButtons}>
                  <IconButton width={50} icon={faTrash} onPress={() => onDeletePressed(round)} />
                </View>
                <RoundCard round={round} onCardPressed={() => openEditAddNewRound(round)} />
              </View>
            ))
          )
        }
        <View>
          {!(training?.pdfURL && training?.pdfURL != "undefined" && training?.pdfURL != "null" ) && (
            <View>
              <View style={themeStyles.buttonContainer}>
                <Button uppercase={true} textColor={'white'} style={themeStyles.addRoundButton} onPress={openAddUpdateNewRound}>
                  Dodaj grupu
                </Button>
              </View>
              <View style={themeStyles.copyDeleteContainer}>
                <View style={themeStyles.buttonContainer}>
                  <Button uppercase={true} textColor={'white'} style={themeStyles.addRoundButton} onPress={handleCopyTraining}>
                    Kopiraj trening
                  </Button>
                </View>
              </View>
            </View>
          )}
          {!training?.rounds || !training?.rounds.length  && (
            <View style={themeStyles.buttonContainer}>
              <Button uppercase={true} textColor={'white'} style={themeStyles.addRoundButton} onPress={() => setPickerVisible(true)} >Dodaj PDF</Button>
            </View>
          )}
          <View style={themeStyles.buttonContainerLast}>
            <Button uppercase={true} textColor={'white'} style={themeStyles.deleteTrainingButton} onPress={() => setIsShowDeleteTrainingConfirmDialog(true)}>Obriši trening</Button>
          </View>
        </View>
      </ScrollView>
      <BottomMenu navigation={navigation} currentScreen='Training'/>
      <ConfirmationDialog
        title="Potvrda"
        text={`Da li ste sigurni da želite da izbrišete grupu?`}
        isOpen={isShowConfirmDialogVisible}
        hideDialog={() => setIsShowConfirmDialogVisible(false)}
        onCofirmClicked={() => removeRound(currentRound)}
      />
      <ConfirmationDialog
        title="Potvrda"
        text={`Da li ste sigurni da želite da izbrišete trening?`}
        isOpen={isShowDeleteTrainingConfirmDialog}
        hideDialog={() => setIsShowDeleteTrainingConfirmDialog(false)}
        onCofirmClicked={removeTraining}
      />
      <FilePickerDialog
        isVisible={isPickerVisible}
        onDismiss={() => setPickerVisible(false)}
        onFileSelected={handleFileSelected}
      />
    </View>
  )
}


const styles = theme => StyleSheet.create({
  previousTrainingsContainer: {
    // flexWrap: 'wrap',
    // flexDirection: 'row',
  },
  mainContent: {
    backgroundColor: '#ffffff',
    margin: 20,
    marginBottom: 80,
  },
  pdfView: {
    flex: 1, // Takes up all available space
    padding: 10, // Add padding
  },
  root: {
    flex: 1,
    backgroundColor: theme.colors.lightText,
  },
  headerText: {
    fontFamily: theme.fontFamilies.primaryFont,
    color: theme.colors.primaryText,
    fontSize: 18,
    textAlign: 'center'
  },
  hederSaveText: {
    fontFamily: theme.fontFamilies.secondaryFont,
    color: theme.colors.primaryText,
    fontSize: 16,
    textAlign: 'right'
  },
  headerContainer: {
    height: 36,
    justifyContent: 'center',
    flex: 8,
  },
  saveButton: {
    marginTop: 5,
    marginLeft: 'auto'
  },
  inputStyle: {
    margin: 5
  },
  buttonContainer: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 20,
    // paddingBottom: 30
  },
  copyDeleteContainer: {
    marginTop: 30,
  },
  addRoundButton: {
    backgroundColor: theme.colors.primaryText,
    color: theme.colors.lightText,
    fontFamily: theme.fontFamilies.primaryFont,
  },
  deleteTrainingButton: {
    backgroundColor: theme.colors.redMarker,
    color: theme.colors.lightText,
    fontFamily: theme.fontFamilies.primaryFont,
  },
  editRemoveButtons: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    width: "100%",
    margin: 5,
  },
  buttonContainerLast: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 20,
    paddingBottom: 30
  },
  previousTrainingsHeader: {
    flexDirection: 'row',
  },
  previousTrainingButton: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: -10,
  },
})

export default withTheme(EditTrainingContainer);
