import React, { useState } from "react";
import { Button, TextInput, withTheme, } from "react-native-paper";
import {StyleSheet, Text } from 'react-native';
import DropDown from "react-native-paper-dropdown";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import IconButton from "./IconButton";


interface  IMessageInput {
    theme: any;
    text: string;
}


const ErrorMessageComponent = ({theme, text}: IMessageInput) => {
  return (
    <Text style={styles(theme).errorMessage}>{text}</Text>
  )
}

const styles = theme => StyleSheet.create({
  errorMessage: {
    color: theme.colors.redMarker,
    fontFamily: theme.fontFamilies.primaryFront,
    fontSize: 16,
    margin: 5,
  }
})

export const ErrorMessage = withTheme(ErrorMessageComponent);
