import { action, createRequestTypes } from '../../utils/ReduxHelpers';
import { DELETE, GET, POST, PUT } from '../../utils/Constants';
import { IFitnessCenter } from '../models/Models';

export const FITNESS_CENTER = createRequestTypes('FITNESS_CENTER', [GET, POST, PUT, DELETE]);

  export const getFitnessCenter = {
    request: () => action(FITNESS_CENTER.GET.REQUEST),
    success: (payload: IFitnessCenter) => action(FITNESS_CENTER.GET.SUCCESS, payload),
    failure: (error: string) => action(FITNESS_CENTER.GET.FAILURE, error)
  };

export const createFitnessCenter = {
    request: (name: string, address: string, phoneNumber: string, emailAddress: string, userId: number) => action(FITNESS_CENTER.POST.REQUEST, { name, address, phoneNumber, emailAddress, userId }),
    success: (payload: any) => action(FITNESS_CENTER.POST.SUCCESS, payload),
    failure: (error: string) => action(FITNESS_CENTER.POST.FAILURE, error)
  };

  export const updateFitnessCenter = {
    request: (id: number, name: string, address: string, phoneNumber: string, emailAddress: string, userId: number) => action(FITNESS_CENTER.PUT.REQUEST, { id, name, address, phoneNumber, emailAddress, userId }),
    success: (payload: any) => action(FITNESS_CENTER.PUT.SUCCESS, payload),
    failure: (error: string) => action(FITNESS_CENTER.PUT.FAILURE, error)
  };

  export const deleteFitnessCenter = {
    request: (id: number) => action(FITNESS_CENTER.DELETE.REQUEST, { id }),
    success: (payload: any) => action(FITNESS_CENTER.DELETE.SUCCESS, payload),
    failure: (error: string) => action(FITNESS_CENTER.DELETE.FAILURE, error)
  };