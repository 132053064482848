import * as Alert from '../../utils/Alerts';
import axios from "axios";
import { all, put, takeLatest } from 'redux-saga/effects';
import { authEndpoint, graphQLEndpoint } from '../settings';
import { AUTH, LOGIN, LOGOUT, login, signUp, CHANGEPASSWORD } from '../actions/AuthActions';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getHeaders } from './Helper';
import { clearUser, getUser } from '../actions/UserActions';
import { getUser as getUserQuery } from '../graphql/queries';

export function *loginSaga({payload: {emailOrUsername, password}}) {
  try {
      let body = JSON.stringify({
        "emailOrUsername": emailOrUsername,
        "password": password
      });
      let headers = {
      headers: {
        'Content-Type': 'application/json'
        }
      }

      let response = yield axios.post(
        `${authEndpoint}/login`,
        body,
        headers
      )
      console.log(response)
      if (response?.data?.errors) {
       throw new Error(response?.data?.errors[0]?.message);
      }

      yield AsyncStorage.setItem("@token", response?.data?.token);

      const query = getUserQuery();
      body = JSON.stringify({query});
      headers = yield getHeaders();

      response = yield axios.post(
        graphQLEndpoint,
        body,
        headers
      )
      console.log(response)
      if (response?.data?.errors) {
        throw new Error(response?.data?.errors);
      }
      yield put(getUser.success(response?.data?.data?.getUser));

      yield Alert.setSuccessAlert("Uspešno logovanje");
  } catch (e) {
      console.log(e)
      yield put(login.failure(e));
      yield Alert.setErrorAlert("Neuspešno logovanje");
    }
  }

  export function *logoutSaga() {
    try {
      yield AsyncStorage.removeItem("@token");
      yield put(clearUser());
      yield Alert.setSuccessAlert("Odjavljeni ste");
    } catch (e) {
      console.log(e)
      yield Alert.setErrorAlert("Neuspešan pokušaj odjave");
    }
    }


export function *signUpSaga({ payload: { user } }) {
  try {
      const body = JSON.stringify(user);
      const headers = {
      headers: {
        'Content-Type': 'application/json'
        }
      }

      const response = yield axios.post(
        `${authEndpoint}/signup`,
        body,
        headers
      )
      console.log(response)
      if (response?.data?.errors) {
        throw new Error(response?.data?.errors[0]?.message);
      }

      yield put(signUp.success(response?.data?.data?.createUser));
      yield Alert.setSuccessAlert("Korisnik je uspešno kreiran");
  } catch (e) {
      console.log(e)
      yield put(signUp.failure(e));
      yield Alert.setErrorAlert("Desila se greška pri kreiranju korisnika");
    }
  }

export function *changePasswordSaga({payload: {oldPassword, password, username}}) {
  try {
      let body = JSON.stringify({
        "oldPassword": oldPassword,
        "password": password,
        "userName": username
      });
      let headers = {
      headers: {
        'Content-Type': 'application/json'
        }
      }

      let response = yield axios.post(
        `${authEndpoint}/changePassword`,
        body,
        headers
      )
      console.log(response)
      if (response?.data?.errors) {
       throw new Error(response?.data?.errors[0]?.message);
      }

      yield AsyncStorage.setItem("@token", response?.data?.token);

      yield Alert.setSuccessAlert("Uspešna promena lozinke");
  } catch (e) {
      console.log(e)
      yield put(login.failure(e));
      yield Alert.setErrorAlert("Neuspešna promena lozinke");
    }
  }

function* authSaga() {
  yield all([
    takeLatest(LOGIN.POST.REQUEST, loginSaga),
    takeLatest(AUTH.POST.REQUEST, signUpSaga),
    takeLatest(LOGOUT, logoutSaga),
    takeLatest(CHANGEPASSWORD.POST.REQUEST, changePasswordSaga)
  ])
}

export default authSaga;
