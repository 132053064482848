import { IExerciseGroup } from "../models/Models";
import { stringify } from "./utils";

export function deleteExercise(id) {
    return `mutation {
      deleteExercise (
        id: ${id}
      ) {
        id
      }
    }`;
  }
  
  export function createExercise(exercise, currentFitnessCenterId) {
    /*const formattedRounds = training.rounds.map((round: IRound) => {
      const exercisesInRound = round?.roundExerciseInRound.map((exerciseInRound: IExerciseInRound) => {
        return {
          exerciseId: exerciseInRound.exercise.id,
          numberOfRepetitions: `${exerciseInRound.numberOfRepetitions}`,
          difficulty: exerciseInRound.difficulty,
          note: exerciseInRound.note
        }
      });
      return {
        id: 1,
        roundSequenceNumber: round.roundSequenceNumber,
        exercisesInRound: exercisesInRound
      }
    });*/
    console.log("NJEE  "+ currentFitnessCenterId)
    const formattedExerciseGroups = exercise.exerciseGroups.map((exerciseGroup: IExerciseGroup) => {
      return{
        id: exerciseGroup.id,
        name: exerciseGroup.name
      }
    });
    return `mutation {
      createExercise (
        name: "${exercise.name}"
        description: "${exercise?.description || ''}"
        exerciseGroups: ${stringify(formattedExerciseGroups)}
        currentFitnessCenterId: ${currentFitnessCenterId}
      ) {
        message {
          isRequestSuccessful
          message
          id
        }
        exercise {
          id
          name
          description
          exerciseGroups {
            id,
            name
          }
        }
      }
    }`;
  }
  
  export function updateExercise(exercise, currentFitnessCenterId) {
    const formattedExerciseGroups = exercise.exerciseGroups.map((exerciseGroup: IExerciseGroup) => {
      return{
        id: exerciseGroup.id,
        name: exerciseGroup.name
      }
    });
    return `mutation {
      updateExercise (
        id: ${exercise.id}
        name: "${exercise.name}"
        description: "${exercise?.description || ''}"
        exerciseGroups: ${stringify(formattedExerciseGroups)}
        currentFitnessCenterId: ${currentFitnessCenterId}
      ) {
        message {
          isRequestSuccessful
          message
          id
        }
        exercise {
          id
          name
          description
          exerciseGroups {
            id,
            name
          }
        }
      }
    }`;
  }