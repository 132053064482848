import React, { useEffect, useState } from 'react';
import { View, StyleSheet, } from 'react-native';
//import { useDispatch } from 'react-redux';
import { Button, withTheme } from 'react-native-paper';
import { OutlinedTextInput, TextArea} from '../components/InputComponents';
import { IClient } from '../../core/models/Models';
import { useSelector } from 'react-redux';
import { userSelector } from '../../core/selectors/UserSelector';


interface Props {
    theme: any;
    client?: IClient;
    onSavePressed: (any) => void;
    onCancelPressed: () => void;
}

const CreateEditClient = ({theme, client, onSavePressed, onCancelPressed}: Props) => {

  const [name, setName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const user = useSelector(userSelector);
  const themeStyles = styles(theme);


  useEffect(() => {
    setName(client?.name ?? '');
    setFamilyName(client?.familyName ?? '');
    setEmail(client?.email ?? '');
    setPhoneNumber(client?.phoneNumber ?? '');
    setAdditionalInfo(client?.additionalInfo ?? '');
  }, [client]);

  const handleCacnel = () => {
    setName('');
    setFamilyName('');
    setEmail('');
    setPhoneNumber('');
    setAdditionalInfo('');
    onCancelPressed();
  }

  const handleSave = () => {
    onSavePressed({
      name:name, familyName: familyName, email: email,
      phoneNumber: phoneNumber, additionalInfo: additionalInfo,
      fitnessCenter: user?.currentFitnessCenter
    });
  }

  const isInputValid = () => name?.trim() != '' && familyName?.trim() != '';


  return (
    <View>
      <View>
        <View style={themeStyles.inputContainer}>
          <OutlinedTextInput
            label='Ime'
            value={name}
            onChangeText={(text) => setName(text)}
          />
        </View>
      <View style={themeStyles.inputContainer}>
        <OutlinedTextInput
          label='Prezime'
          value={familyName}
          onChangeText={(text) => setFamilyName(text)}
        />
          </View>
        <View style={themeStyles.inputContainer}>
          <OutlinedTextInput
            label="Email"
            value={email}
            onChangeText={(text) => setEmail(text)}
          />
        </View>
        <View style={themeStyles.inputContainer}>
        <OutlinedTextInput
            label="Broj telefona"
            value={phoneNumber}
            onChangeText={(text) => setPhoneNumber(text)}
          />
        </View>
        <View style={themeStyles.descriptionContainer}>
          <TextArea value={additionalInfo} onChangeText={(text) => setAdditionalInfo(text)} label="Dodatne informacije"/>
          </View>
        <View style={themeStyles.buttonContainer}>
          <Button uppercase={true} textColor={theme.colors.lightText} disabled={!isInputValid()} style={themeStyles.saveButton} onPress={handleSave}>Snimi</Button>
          <Button uppercase={true} textColor={theme.colors.primaryText} style={themeStyles.cancelButton} onPress={handleCacnel}>Otkaži</Button>
        </View>
      </View>
  </View>
  )
}


const styles = theme => StyleSheet.create({
  root: {
    flex: 1,
  },
  horizontalContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  fullWidthInput: {
    width: '100%'
  },
  numberContainer: {
    width: 100,
  },
  dateContainer: {
    width: 120,
  },
  row: {
    flexDirection: "row",
    marginRight: 20,
    flexWrap: 'wrap',
  },
  inputBorderStyle: {
    borderRadius: 5,
    borderColor: theme.colors.primaryText
  },
  inputContainer: {
    marginBottom: 10,
    marginRight: 15
  },
  descriptionContainer: {
    marginTop: 20,
    marginBottom: 10,
  },
  buttonContainer: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 20,
    paddingBottom: 30
  },
  saveButton: {
    backgroundColor: theme.colors.tertiaryBackground,
    color: theme.colors.lightText,
    fontFamily: theme.fontFamilies.primaryFont,
    marginTop: 20,
    marginBottom: 10,
  },
  cancelButton: {
    backgroundColor: theme.colors.lightText,
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    borderWidth: 1,
    borderColor: theme.colors.primaryText,
  },

})

export default withTheme(CreateEditClient);

