import { faAngleLeft, faTriangleExclamation, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text, ScrollView, Button, TouchableOpacity } from 'react-native';
import Modal from 'react-native-modal/dist/modal';
import { withTheme } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { getClientsPreviousExerciseInRound } from '../../core/actions/TrainingActions';
import { IExercise, IExerciseInRound, IPreviousExercises, ITraining } from '../../core/models/Models';
import { getCurrentTrainingSelector, getLastExerciseInRoundSelector, getNewTrainingSelector } from '../../core/selectors/TrainingSelector';
import { isNumeric } from '../../utils/utils';
import { ScreenHeader } from '../components//Headers';
import { CustomTextImput, NumberInput, TextArea } from '../components//InputComponents';
import IconButton from '../components/IconButton';


interface Props {
  theme: any;
  exercise?: IExercise;
  isDialogVisible: boolean;
  isLastExercise: boolean;
  exerciseInRound?: IExerciseInRound;
  currentTraining: ITraining;
  onDismiss: () => void;
  onSave: (arg0: number, arg1: string, arg2: string ) => void;
  previousExercises: IPreviousExercises;
}


const ExerciseInfoDialog = ({theme, exercise, exerciseInRound, previousExercises, isDialogVisible, isLastExercise, currentTraining, onDismiss, onSave}: Props) => {
  const themeStyles = styles(theme)
  const [openExercise, setOpenExercise] = useState(exercise);
  const [numberOfRepetition, setNumberOfRepetitions] = useState(1);
  const [measurementUnits, setMeasurementUnits] = useState('');
  const [notes, setNotes] = useState('');
  const previousExerciseInRound = useSelector(getLastExerciseInRoundSelector);
  const [isPreviousLoaded, setIsPreviousLoaded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setNumberOfRepetitions(0);
    setMeasurementUnits('');
    setNotes('');
    setOpenExercise(exercise);
    setIsPreviousLoaded(false);
    if (exercise && !exerciseInRound) {
      const clientId = currentTraining?.client?.id;
      const exerciseId = exercise?.id;
      dispatch(getClientsPreviousExerciseInRound.request(clientId, exerciseId));
    }
  }, [exercise]);

  useEffect(() => {
    if (exerciseInRound) {
      setNumberOfRepetitionsIfValid(exerciseInRound?.numberOfRepetitions || '0');
      setMeasurementUnits(exerciseInRound?.difficulty);
      setNotes(exerciseInRound?.note);
    }
  }, [exerciseInRound]);

  useEffect(() =>  {
    if (!isPreviousLoaded && previousExerciseInRound && !exerciseInRound) {
      setIsPreviousLoaded(true);
      setNumberOfRepetitionsIfValid(previousExerciseInRound?.numberOfRepetitions || '0');
      setMeasurementUnits(previousExerciseInRound?.difficulty);
      setNotes(previousExerciseInRound?.note);
    }
  }, [previousExerciseInRound]);


  const setNumberOfRepetitionsIfValid = (text) => {
    if (isNumeric(text)) {
      setNumberOfRepetitions(parseInt(text));
    }
    else if (!text.length) {
      setNumberOfRepetitions(0);
    }
  }


  return (
    <Modal isVisible={isDialogVisible} onDismiss={onDismiss}>
      <View style={themeStyles.modalContent}>
      <View style={[themeStyles.header, themeStyles.regularBackground]}>
        <IconButton
          icon={faXmark}
          iconSize={20}
          onPress={onDismiss}
        />
        <View style={themeStyles.rightContainer}>
          <TouchableOpacity
            onPress={() => onSave(numberOfRepetition, measurementUnits, notes)}
            style={themeStyles.touchableButton}>
            <Text style={themeStyles.headerButtonText}>
              {isLastExercise ? 'Snimi' : 'Dalje'}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
        <ScrollView style={themeStyles.mainContent}>
          <View style={themeStyles.innerContent}>
            <View>
              <View style={themeStyles.exerciseContainer}>
                <View style={themeStyles.exerciseTexts}>
                  <Text style={themeStyles.itemExerciseText}>{openExercise?.name}</Text>
                </View>
                {previousExercises?.previous1?.includes(exercise?.id) ? (
                  <View style={themeStyles.warningIconContainer}>
                    <FontAwesomeIcon size={20} color={theme.colors.redMarker} icon={faTriangleExclamation} />
                  </View>
                ) : previousExercises?.previous2?.includes(exercise?.id) ? (
                  <View style={themeStyles.warningIconContainer}>
                    <FontAwesomeIcon size={20} color={theme.colors.orangeMarker} icon={faTriangleExclamation} />
                  </View>
                  ) : previousExercises?.previous3?.includes(exercise?.id) ? (
                    <View style={themeStyles.warningIconContainer}>
                      <FontAwesomeIcon size={20} color={theme.colors.yellowMarker} icon={faTriangleExclamation} />
                    </View>
                  ): null}
              </View>
              <View>
                {previousExercises?.previous1?.includes(exercise?.id) ? (
                  <Text style={themeStyles.warningText}>*Vežba je rađena tokom prethodnog treninga</Text>
                ) : previousExercises?.previous2?.includes(exercise?.id) ? (
                  <Text style={themeStyles.warningText}>*Vežba je rađena pre 2 treninga</Text>
                ) : previousExercises?.previous3?.includes(exercise?.id) ? (
                  <Text style={themeStyles.warningText}>*Vežba je rađena pre 3 treninga</Text>
                ): null}
              </View>
              <View style={themeStyles.repetitionsContainer}>
                <Text style={themeStyles.repetitionsText}>Broj ponavljanja</Text>
              </View>
              <View style={themeStyles.repetitionsInputContainer}>
                <NumberInput
                  value={numberOfRepetition + ''} onChangeText={(text) => setNumberOfRepetitionsIfValid(text)} label={''}
                  autoFocus={true}
                />
              </View>
              <View style={themeStyles.repetitionsContainer}>
                <Text style={themeStyles.repetitionsText}>Težine/trajanje</Text>
              </View>
              <View style={themeStyles.repetitionsInputContainer}>
                <CustomTextImput value={measurementUnits} onChangeText={(text) => setMeasurementUnits(text)} label={''}/>
              </View>
              <View style={themeStyles.descriptionContainer}>
                <TextArea value={notes} onChangeText={(text) => setNotes(text)} label="Dodatni opis"/>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </Modal>
  )
}


const styles = theme => StyleSheet.create({
  innerContent: {
    margin: 20
  },
  container: {
    alignItems: 'flex-end', // Right align the button
  },
  button: {
    backgroundColor: 'transparent', // No background
    // Add other styling as needed
  },
  buttonText: {
    fontSize: 20,
  },
  itemExerciseText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 20,
    fontWeight: '600',
    marginBottom: 5,
    flexShrink: 1,
  },
  itemExerciseGroupText: {
    color: theme.colors.secondaryText,
    fontFamily: theme.fontFamilies.secondaryFont,
    fontSize: 12,
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  exerciseTexts: {
    marginRight: 5,
    flex: 1,
  },
  exerciseContainer: {
    flex: 6,
    flexDirection: 'row',
  },
  warningText: {
    color: theme.colors.secondaryText,
    fontFamily: theme.fontFamilies.secondaryFont,
    fontSize: 14,
    fontWeight: '400',
  },
  containerStyle: {
    padding: 20,
  },
  modalContent: {
    borderRadius: 10,
    flex: 1,
    overflow: 'hidden',
  },
  mainContent: {
    flexDirection: 'column',
    backgroundColor: theme.colors.lightText,
  },
  header: {
    flexDirection: 'row',
    height: 70,
    width: '100%',
    borderBottomColor: theme.colors.primaryText,
    borderBottomWidth: 1,
    alignItems: 'center', // Center items vertically
  },
  iconButton: {
    // Style for the icon button (left aligned by default)
  },
  rightContainer: {
    flex: 1,
    alignItems: 'flex-end', // Align touchable to the right
    marginRight: 15,
  },
  touchableButton: {
    // Style for the touchable button
  },
  headerButtonText: {
    // Style for the text inside the button
    color: theme.colors.primaryText,
    fontWeight: '600',
    fontSize: 20,
    // Add other text styles
  },
  headerTextContainer: {
    flexGrow: 1,
    alignSelf: 'center',
    marginLeft: -20,
  },
  headerText: {
    color: theme.colors.primaryText,
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 20,
    fontWeight: '600',
  },
  regularBackground: {
    backgroundColor: theme.colors.tertiaryBackground,
  },
  buttonHeaderContainer: {
    alignSelf: 'center',
    width: 50,
  },
  warningIconContainer: {
    alignContent: 'center',
    justifyContent: 'center',
  },
  round: {
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10,
  },
  roundHeader: {
    marginBottom: 10,
  },
  roundHeaderText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 16,
    fontWeight: '600',
  },
  roundExerciseText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 5,
  },
  roundExerciseGroupText: {
    color: theme.colors.secondaryText,
    fontFamily: theme.fontFamilies.secondaryFont,
    fontSize: 12,
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  roundExerciseContainer: {
    borderTopColor: theme.colors.primaryText,
    borderTopWidth: 1,
    paddingTop: 10,
    paddingBottom: 10,
  },
  repetitionsContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  repetitionsText: {
    fontFamily: theme.fontFamilies.primartyFont,
    color: theme.colors.primaryText,
    fontSize: 18,
    fontWeight: '500',
  },
  repetitionsInputContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  descriptionContainer: {
    marginTop: 10,
  }
})

export default withTheme(ExerciseInfoDialog);
