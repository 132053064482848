import React, { useEffect, useState } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { withTheme } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { createClient } from '../../core/actions/ClientActions';
import BottomMenu from '../components/BottomMenu';
import CreateEditClient from './CreateEditClientInfo';
import { userSelector } from '../../core/selectors/UserSelector';


const CreateClientContainer = ({theme, navigation}) => {

  const themeStyles = styles(theme);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const onSaveClient = (client) => {
    dispatch(createClient.request(client, user?.currentFitnessCenter?.id));
    navigation.navigate('ViewClients');
  }

  const onCancel = () => {
    navigation.navigate('ViewClients');
  }


  return (
    <View style={themeStyles.root}>
      <ScrollView style={themeStyles.mainContent}>
        <CreateEditClient onSavePressed={onSaveClient} onCancelPressed={onCancel}/>
      </ScrollView>
      <BottomMenu navigation={navigation} currentScreen='Clients'/>
    </View>
  )
}

const styles = theme => StyleSheet.create({
  mainContent: {
    margin: 20,
  },
  root: {
    flex: 1
  }
});

export default withTheme(CreateClientContainer);

