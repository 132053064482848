interface IProps {
    password: string,
    repeatPassword: string,
    errors: string,
    setPasswordError: (string) => void;
    passwordValidation: (string) => void;
  }

const passwordValidation = (passwordInputValue) => {
    const uppercaseRegExp   = /(?=.*?[A-Z])/;
    const lowercaseRegExp   = /(?=.*?[a-z])/;
    const digitsRegExp      = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-_])/;
    const minLengthRegExp   = /.{8,}/;
    const passwordLength =      passwordInputValue.length;
    const uppercasePassword =   uppercaseRegExp.test(passwordInputValue);
    const lowercasePassword =   lowercaseRegExp.test(passwordInputValue);
    const digitsPassword =      digitsRegExp.test(passwordInputValue);
    const specialCharPassword = specialCharRegExp.test(passwordInputValue);
    const minLengthPassword =   minLengthRegExp.test(passwordInputValue);
    let errMsg ="";
    if(passwordLength===0){
      errMsg="Unesite lozinku";
    }else if(!uppercasePassword){
      errMsg='Bar jedno veliko slovo';
    }else if(!lowercasePassword){
      errMsg='Bar jedno malo slovo';
    }else if(!digitsPassword){
      errMsg='Bar jedna cifra';
    }else if(!specialCharPassword){
      errMsg='Bar jedan specijalni karakter';
    }else if(!minLengthPassword){
      errMsg='Bar 8 karaktera';
    }else{
      errMsg='';
    }
      return errMsg;
    }

    const isNotEmpty = (text) => {
        return text.trim() != '';
      }

    const isPasswordsMatch = (password1, password2) => {
        let passwordsMatch = true;
        if (password2) {
          passwordsMatch = password1 == password2;
        }
        return passwordsMatch;
      }

    const validateAllInputs = (password, repeatPassword, oldPassword) => {
        console.log(password);
        console.log(repeatPassword);
        console.log(oldPassword);
        return passwordValidation(password) == '' && isNotEmpty(repeatPassword) && isNotEmpty(oldPassword) && isPasswordsMatch(password, repeatPassword)
      }

export {validateAllInputs,
        passwordValidation
}