import React, { useState } from 'react';
import { View, StyleSheet, ScrollView, Pressable, Text, Image } from 'react-native';
import { Button, withTheme } from 'react-native-paper';
import { OutlinedPasswordInput, OutlinedTextInput } from './components/InputComponents';
import { ErrorMessage } from './components/MessageComponents';
import { useDispatch } from 'react-redux';
import { login } from '../core/actions/AuthActions';
import { ImagesAssets } from '../assets/ImagesAssets';

interface Props {
    theme: any;
    navigation: any; // Add navigation type
}

const Login = ({ theme, navigation }: Props) => {
    const [emailOrUsername, setEmailOrUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const dispatch = useDispatch();

    const handleLogin = () => {
        dispatch(login.request(emailOrUsername, password));
    };

    const navigateToSignUp = () => {
        navigation.navigate('SignUpScreen');
    };

    return (
      <View style={styles(theme).root}>
        <View style={styles(theme).header}>
          <Image  resizeMode="contain"  source={ImagesAssets.logoBig} style={styles(theme).headerImage} />
          <View style={styles(theme).headerTextContainer}>
            <Text style={styles(theme).headerText}>Logovanje</Text>
          </View>
        </View>
        <ScrollView style={styles(theme).mainContent}>
          <View style={styles(theme).inputContainer}>
            <OutlinedTextInput
              label='Korisničko ime ili email'
              value={emailOrUsername}
              onChangeText={setEmailOrUsername}
            />
          </View>
          <View style={styles(theme).inputContainer}>
            <OutlinedPasswordInput
                label='Lozinka'
                value={password}
                onChangeText={setPassword}
            />
            {passwordError ? <ErrorMessage text={passwordError} /> : null}
          </View>
          <View style={styles(theme).buttonContainer}>
            <Button
                onPress={handleLogin}
                style={styles(theme).loginButton}
                labelStyle={styles(theme).buttonLabel}
            >
              Prijava
            </Button>
          </View>
        </ScrollView>
        <View style={styles(theme).footer}>
          <Text style={styles(theme).footerText}>Nemate nalog?</Text>
          <Pressable onPress={navigateToSignUp}>
            <Text style={styles(theme).registerLink}>Registrujte se</Text>
          </Pressable>
        </View>
      </View>
    );
};

const styles = theme => StyleSheet.create({
    root: {
      flex: 1,
      justifyContent: 'space-between',
    },
    header: {
      backgroundColor:  theme.colors.tertiaryBackground, // Light gray for the header background
      paddingTop: 20,
      paddingBottom: 20,
      alignItems: 'center',
      justifyContent: 'center',
    },
    headerImage: {
        width: 150,
        height: 150,
    },
    headerTextContainer: {
      alignSelf: 'flex-start',
      width: '100%',
      paddingLeft: 20,  // Adjust the padding as needed
  },
    headerText: {
      color: theme.colors.primaryText, // Color from theme for the text
      fontSize: 24,
      marginTop: 10,
      fontWeight: '600',
      fontFamily: theme.fontFamilies.primaryFont,
    },
    mainContent: {
      flex: 1,
      backgroundColor: '#F5F5F5',
    },
    inputContainer: {
        margin: 10,
    },
    buttonContainer: {
      marginTop: 5,
      marginBottom: 20,
      alignItems: 'center',
      marginRight: 10,
      marginLeft: 10,
    },
    loginButton: {
      fontFamily: theme.fontFamilies.secondaryFont,
      backgroundColor: theme.colors.primaryText,
      width: '100%',
    },
    footer: {
      paddingBottom: 20,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 10,
      backgroundColor: '#F5F5F5',
  },
    footerText: {
      fontSize: 18,
      marginRight: 5,
      fontFamily: theme.fontFamilies.primaryFont,
    },
    registerLink: {
      fontSize: 18,
      color: theme.colors.primaryText,
      fontWeight: 'bold',
      textDecorationLine: 'none',
      fontFamily: theme.fontFamilies.primaryFont,
    },
    buttonLabel: {
      color: '#ffffff', // Set text color to white
      textTransform: 'uppercase',
  },
});

export default withTheme(Login);
