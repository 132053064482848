import * as Alert from '../../utils/Alerts';
import axios from "axios";
import { all, put, takeLatest } from 'redux-saga/effects';
import { getExercises as getExercisesQuery } from '../graphql/queries';
import {
  deleteExercise as deleteExerciseMutation,
  createExercise as createExerciseMutation,
  updateExercise as updateExerciseMutation,
} from '../graphql/exerciseMutations';
import { deleteExercise, EXERCISE, EXERCISES, getExercises, createExercise, updateExercise } from '../actions/ExerciseActions';
import { graphQLEndpoint } from '../settings';
import { getHeaders } from './Helper';



export function* getExercisesSaga({ payload: { currentFitnessCenterId } }) {
    try {
      const query = getExercisesQuery(currentFitnessCenterId);
      const body = JSON.stringify({query});
      const headers = yield getHeaders();
  
      const response = yield axios.post(
        graphQLEndpoint,
        body,
        headers
      )
      console.log(response)
      if (response?.data?.errors) {
        throw new Error(response?.errors[0]?.message);
      }
      yield put(getExercises.success(response?.data?.data?.allExercise.exerciseList));
    } catch (e) {
      yield put(getExercises.failure(e));
      yield Alert.setErrorAlert("Desila se greška pri učitavanju vežbi");
    }
  }

  export function *deleteExerciseSaga({ payload: { id } }) {
    try {
      const query = deleteExerciseMutation(id);
      const body = JSON.stringify({query});
      const headers = yield getHeaders();
  
      const response = yield axios.post(
        graphQLEndpoint,
        body,
        headers
      )
      console.log(response)
      if (response?.data?.errors) {
        throw new Error(response?.data?.errors[0]?.message);
      }
  
      yield put(deleteExercise.success(id));
      yield Alert.setSuccessAlert("Vežba je uspešno izbrisana");
    } catch (e) {
      console.log(e)
      yield put(deleteExercise.failure(e));
      yield Alert.setErrorAlert("Desila se greška pri brisanju vežbi");
    }
  }

  export function *createExerciseSaga({ payload: { data, currentFitnessCenterId } }) {
    try {
      const query = createExerciseMutation(data, currentFitnessCenterId);
      const body = JSON.stringify({query});
      const headers = yield getHeaders();
  
      const response = yield axios.post(
        graphQLEndpoint,
        body,
        headers
      )
      console.log(response)
      if (response?.data?.errors) {
        throw new Error(response?.data?.errors[0]?.message);
      }
  
      yield put(createExercise.success(response?.data?.data?.createExercise.exercise));
      yield Alert.setSuccessAlert("Vežba je uspešno kreirana");
    } catch (e) {
      console.log(e)
      yield put(createExercise.failure(e));
      yield Alert.setErrorAlert("Desila se greška pri kreiranju vežbe");
    }
  }

  export function *updateExerciseSaga({ payload: { data, currentFitnessCenterId } }) {
    try {
      const query = updateExerciseMutation(data, currentFitnessCenterId);
      const body = JSON.stringify({query});
      const headers = yield getHeaders();
  
      const response = yield axios.post(
        graphQLEndpoint,
        body,
        headers
      )
      console.log(response)
      if (response?.data?.errors) {
        throw new Error(response?.data?.errors[0]?.message);
      }
  
      yield put(updateExercise.success(response?.data?.data?.updateExercise.exercise));
      yield Alert.setSuccessAlert("Vežbe je uspešno izmenjena");
    } catch (e) {
      console.log(e)
      yield put(updateExercise.failure(e));
      yield Alert.setErrorAlert("Desila se greška pri izmeni vežbe");
    }
  }

function* exerciseSaga() {
    yield all([
      takeLatest(EXERCISES.GET.REQUEST, getExercisesSaga),
      takeLatest(EXERCISE.POST.REQUEST, createExerciseSaga),
      takeLatest(EXERCISE.DELETE.REQUEST, deleteExerciseSaga),
      takeLatest(EXERCISE.PUT.REQUEST, updateExerciseSaga),
    ])
  }
    
  export default exerciseSaga;