import { IActivity, IClient } from '../models/Models';
import { produce } from 'immer';
import { ADD_CLIENTS_TRAINING, CLIENT, CLIENTS, REMOVE_CLIENTS_TRAINING } from '../actions/ClientActions';
import { Item } from 'react-native-paper/lib/typescript/components/Drawer/Drawer';

export interface IClientState {
    clients: IClient[];
}

const initialState : IClientState = {
    clients: [
    ]
};

const clientReducer = (state = initialState, action: IActivity ): IClientState => 
    produce(state, (draft)  => {
        const { type, payload } = action;
        switch (type) {
            case CLIENTS.GET.SUCCESS: {
                draft.clients = payload.map((item) => {return {
                    ...item.client,  trainingCount: item.count
                }});
            }
                break;
            case CLIENT.DELETE.SUCCESS:
                draft.clients = state.clients.filter((cl) => cl.id !== payload);
                break;
            case CLIENT.POST.SUCCESS:
                draft.clients.push({...payload, trainingCount: 0});
                break;
            case CLIENT.PUT.SUCCESS:
                draft.clients = state.clients.map((cl) => {
                    return cl?.id == payload?.id ? {...payload, trainingCount: cl?.trainingCount} : cl;
                });
                break;
            case ADD_CLIENTS_TRAINING:
                draft.clients = state.clients.map((cl) => {
                    return cl?.id == payload ? {...cl, trainingCount: cl?.trainingCount + 1}  : cl
                    }
                );
                break;
            case REMOVE_CLIENTS_TRAINING:
                draft.clients = state.clients.map((cl) => {
                    return cl?.id == payload ? {...cl, trainingCount: cl?.trainingCount - 1}  : cl
                    }
                );
                break
            default:
                return draft;
        }
    });

export default clientReducer;