import * as Alert from '../../utils/Alerts';
import axios from "axios";
import { all, put, takeLatest } from 'redux-saga/effects';
import {
  getUser as getUserQuery,
  findUsersByEmail as findUsersByEmailQuery,
  findUsersByFitnessCenter as findUsersByFitnessCenterQuery
} from '../graphql/queries';
import {createUserFitnessCenter as createUserFitnessCenterMutation,
        deleteUserFitnessCenter as deleteUserFitnessCenterMutation
} from '../graphql/userMutation';
import { graphQLEndpoint } from '../settings';
import { getHeaders } from './Helper';
import { FIND_USERS, FITNESS_CENTER_USERS, USER, USER_FITNESS_CENTER, createUserFitnessCenter, deleteUserFitnessCenter, findUsersByEmail, findUsersByFitnessCenter, getUser } from '../actions/UserActions';


export function* getUserSaga() {
  try {
    const query = getUserQuery();
    const body = JSON.stringify({query});
    const headers = yield getHeaders();

    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.data?.errors);
    }
    yield put(getUser.success(response?.data?.data?.getUser));
  } catch (e) {
    yield put(getUser.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri učitavanju korisnika");
  }
}


export function* findUsersByEmailSaga({payload: { email } }) {
  try {
    const query = findUsersByEmailQuery(email);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();

    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.data?.errors);
    }
    yield put(findUsersByEmail.success(response?.data?.data?.getUsersByUsernameLike));
  } catch (e) {
    yield put(findUsersByEmail.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri učitavanju korisnika");
  }
}

export function* findUsersByFitnessCenterSaga({payload: { fitnessCenterId } }) {
  try {
    const query = findUsersByFitnessCenterQuery(fitnessCenterId);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();

    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.data?.errors);
    }
    yield put(findUsersByFitnessCenter.success(response?.data?.data?.getUsersByFitnessCenter));
  } catch (e) {
    yield put(findUsersByFitnessCenter.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri učitavanju korisnika");
  }
}

export function* createUserFitnessCenterSaga({payload: { userFitnessCenter } }) {
  try {
    const query = createUserFitnessCenterMutation(userFitnessCenter);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();

    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.data?.errors);
    }
    yield put(createUserFitnessCenter.success(response?.data?.data?.createUserFitnessCenterMutation));
    yield Alert.setSuccessAlert("Korisnik je uspešno dodat u studio");
  } catch (e) {
    yield put(createUserFitnessCenter.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri učitavanju korisnika");
  }
}

export function *deleteUserFitnessCenterSaga({ payload: {fitnessCenterId, id } }) {
  try {
    const query = deleteUserFitnessCenterMutation(fitnessCenterId, id);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();

    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.data?.errors[0]?.message);
    }

    yield put(deleteUserFitnessCenter.success(id));
    yield Alert.setSuccessAlert("Vežba je uspešno izbrisana");
  } catch (e) {
    console.log(e)
    yield put(deleteUserFitnessCenter.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri brisanju vežbi");
  }
}

function* userSaga() {
  yield all([
    takeLatest(USER.GET.REQUEST, getUserSaga),
    takeLatest(FIND_USERS.GET.REQUEST, findUsersByEmailSaga),
    takeLatest(USER_FITNESS_CENTER.POST.REQUEST, createUserFitnessCenterSaga),
    takeLatest(USER_FITNESS_CENTER.DELETE.REQUEST, deleteUserFitnessCenterSaga),
    takeLatest(FITNESS_CENTER_USERS.GET.REQUEST, findUsersByFitnessCenterSaga)
  ])
}
    
export default userSaga;