import React, { useEffect, useState } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import { useDispatch,  useSelector } from 'react-redux';
import BottomMenu from '../components/BottomMenu';
import { getTrainings, getIndependentUserTrainings, updateTrainingAction } from '../../core/actions/TrainingActions';
import { getIndependentUserTrainingsSelector, getTrainingsSelector } from '../../core/selectors/TrainingSelector';
import { formatDateLocale } from '../../utils/utils';
import ContainerCard from '../components/ContainerCard';
import { Button, withTheme } from 'react-native-paper';
import { userSelector } from '../../core/selectors/UserSelector';
import LoadingIndicator from '../components/LoadingContainer';
import { ITraining } from '../../core/models/Models';


const TrainingsContainer = ({navigation, theme}) => {
  const dispatch = useDispatch();
  const trainings = useSelector(getTrainingsSelector);
  const independentUserTrainings = useSelector(getIndependentUserTrainingsSelector);
  const [page, setPage] = useState(0);
  const [filteredTrainings, setFilteredTrainings] = useState(trainings);
  const user = useSelector(userSelector);
  const count = 10;
  const [isLoading, setIsLoading] = useState(false);

const themeStyles = styles(theme);

  useEffect(() => {
    if (((trainings || [])?.length) < count ) {
      console.log("FuGGETLEN : " + user?.isIndependentUser);
      if(user?.isIndependentUser){
        dispatch(getIndependentUserTrainings.request(user?.id, 0, count));
        setPage(count)
      } else {
        dispatch(getTrainings.request(0, count, user?.currentFitnessCenter?.id));//TODO add alternate rout for independent user
        setPage(count)
      }
    }
  }, []);

  const loadMoreTrainings = () => {
    if(user?.isIndependentUser){
      dispatch(getIndependentUserTrainings.request(user?.id, page, count));
    } else {
      dispatch(getTrainings.request(page , count, user?.currentFitnessCenter?.id)); // TODO add alternate rout for independent user
    }
    setPage(page + count);
  }

  useEffect(() => {
    setIsLoading(false);
  }, [trainings]);

  const onCardPress = (training) => {
    dispatch(updateTrainingAction({...training}, 'edit'));
    navigation.navigate('EditTrainingContainer', {training: training})
    console.log('open training')
  }

  const onScroll = ({ nativeEvent }) => {
    if (isCloseToBottom(nativeEvent)) {
      loadMoreTrainings()
    }
  };

  const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
    const paddingToBottom = 300;
    return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
  };

  const getCardText = (training: ITraining) =>
    training?.client ? `${training?.client?.name} ${training?.client?.familyName}` : `Trening ${training?.numberOfTrainings}`;


  return (
    <View style={themeStyles.root}>
      <ScrollView style={themeStyles.mainContent} onScroll={onScroll} scrollEventThrottle={16}>
        <View style={themeStyles.cardsContainer}>
          {trainings?.map((training) => (
            <ContainerCard key={training?.id} bottomText={formatDateLocale(training?.date)} cardText={getCardText(training)} onCardPress={() => onCardPress(training)}/>
          ))}
        </View>
      </ScrollView>
      {isLoading && (
        <LoadingIndicator/>
      )}
      <BottomMenu navigation={navigation} currentScreen='Clients'/>
    </View>
  );
}

const styles = theme => StyleSheet.create({
  root: {
    flex: 1,
  },
  cardsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  mainContent: {
    backgroundColor: '#ffffff',
    margin: 20,
    marginBottom: 80,
  },
  loadMoreButton: {
    backgroundColor: theme.colors.primaryText,
    color: theme.colors.lightText,
    fontFamily: theme.fontFamilies.primaryFont,
    width: 120,
    alignSelf: 'center',
    margin: 20,
  },
})

export default withTheme(TrainingsContainer);
