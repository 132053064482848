import { IActivity } from '../models/Models';
import { produce } from 'immer';
import { AUTH } from '../actions/AuthActions';


export interface IAuthState {
  signupCompleted: boolean;
}

const initialState: IAuthState = {
  signupCompleted: false
};

const authReducer = (state = initialState, action: IActivity): IAuthState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case AUTH.POST.SUCCESS: {
        draft.signupCompleted = true
        }
      }
    });

export default authReducer;
