
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { Button, withTheme } from 'react-native-paper';
import { OutlinedTextInput, TextArea} from '../components/InputComponents';
import { IFitnessCenter } from '../../core/models/Models';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '../../core/selectors/UserSelector';
import { getFitnessCenterSelector } from '../../core/selectors/FitnessCenterSelector';

interface Props {
    theme: any;
    fitnessCenter?: IFitnessCenter;
    onSavePressed: (any) => void;
    onUpdatePressed: (any) => void;
    onCancelPressed: () => void;
}


const CreateEditFitnessCenterInfo = ({theme, fitnessCenter, onSavePressed, onUpdatePressed, onCancelPressed}: Props) => {

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const user = useSelector(userSelector);
  const fitnessCenterUpdated = useSelector(getFitnessCenterSelector);
  const themeStyles = styles(theme);

//  user?.currentFitnessCenter?.name

useEffect(() => {
    setName(fitnessCenterUpdated?.name ?? user?.currentFitnessCenter?.name ?? '');
    setAddress(fitnessCenterUpdated?.address ?? user?.currentFitnessCenter?.address ?? '');
    setPhoneNumber(fitnessCenterUpdated?.phoneNumber ?? user?.currentFitnessCenter?.phoneNumber ?? '');
    setEmailAddress(fitnessCenterUpdated?.emailAddress ?? user?.currentFitnessCenter?.emailAddress ?? '');
  }, [user]);

//Atributi iz java fajla Fitness Center

  const handleCacnel = () => {
    console.log('Cancel pressed.');
    setName('');
    setAddress('');
    setPhoneNumber('');
    setEmailAddress('');
    onCancelPressed();
  }

  const handleSave = (fitnessCenter) => {
    onSavePressed({name:name, address:address, phoneNumber:phoneNumber, emailAddress:emailAddress, userId:user?.id});
    setName('');
    setAddress('');
    setPhoneNumber('');
    setEmailAddress('');
  }

  const handleUpdate = (fitnessCenter) => {
    onUpdatePressed({id: user?.currentFitnessCenter?.id, name:name, address:address, phoneNumber:phoneNumber, emailAddress:emailAddress, userId:user?.id});
    setName('');
    setAddress('');
    setPhoneNumber('');
    setEmailAddress('');
  }

  return (
    <View>
      <View>
        <View style={themeStyles.inputContainer}>
          <OutlinedTextInput
            label='Naizv'
            value={name}
            onChangeText={(text) => setName(text)}
          />
        </View>
        <View style={themeStyles.inputContainer}>
          <OutlinedTextInput
            label='Adresa'
            value={address}
            onChangeText={(text) => setAddress(text)}
          />
        </View>
        <View style={themeStyles.inputContainer}>
          <OutlinedTextInput
            label='Broj telefona'
            value={phoneNumber}
            onChangeText={(text) => setPhoneNumber(text)}
          />
        </View>
        <View style={themeStyles.inputContainer}>
          <OutlinedTextInput
            label='Email adresa'
            value={emailAddress}
            onChangeText={(text) => setEmailAddress(text)}
          />
        </View>
        <View style={themeStyles.buttonContainer}>
          {user?.currentFitnessCenter?.name == null && <Button uppercase={true} textColor={theme.colors.lightText} style={themeStyles.saveButton} onPress={handleSave}>Snimi</Button>}
          {user?.currentFitnessCenter?.name != null && <Button uppercase={true} textColor={theme.colors.lightText} style={themeStyles.saveButton} onPress={handleUpdate}>Izmeni</Button>}
          <Button uppercase={true} textColor={theme.colors.primaryText} style={themeStyles.cancelButton} onPress={handleCacnel}>Otkaži</Button>
        </View>
      </View>
  </View>
  )
}


const styles = theme => StyleSheet.create({
  root: {
    flex: 1,
  },
  mainContent: {
    backgroundColor: '#ffffff',
    margin: 20,
  },
  horizontalContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  fullWidthInput: {
    width: '100%'
  },
  numberContainer: {
    width: 100,
  },
  dateContainer: {
    width: 120,
  },
  row: {
    flexDirection: "row",
    marginRight: 20,
    flexWrap: 'wrap',
  },
  inputBorderStyle: {
    borderRadius: 5,
    borderColor: theme.colors.primaryText
  },
  inputContainer: {
    marginBottom: 10,
    marginRight: 15
  },
  descriptionContainer: {
    marginTop: 20,
    marginBottom: 10,
  },
  buttonContainer: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 20,
    paddingBottom: 30
  },
  saveButton: {
    backgroundColor: theme.colors.primaryText,
    color: theme.colors.lightText,
    fontFamily: theme.fontFamilies.primaryFont,
    marginTop: 20,
    marginBottom: 10,
  },
  cancelButton: {
    backgroundColor: theme.colors.lightText,
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    borderWidth: 1,
    borderColor: theme.colors.primaryText,
  },

})

export default withTheme(CreateEditFitnessCenterInfo);
