import { AppState } from '../reducers/RootReducer';
import { createSelector } from 'reselect';
import { IExerciseInRound, ITraining } from '../models/Models';

const getTrainings = (state: AppState): Array<ITraining> => state.trainings.trainings;
const getClientsTrainings = (state: AppState): Array<ITraining> => state.trainings.clientsTrainings;
const getIndependentUserTrainings = (state: AppState): Array<ITraining> => state.trainings.trainings;
const getCurrentTraining = (state: AppState): ITraining => state.trainings.currentTraining;
const getNewTraining = (state: AppState): ITraining => state.trainings.newTraining;
const getHomeClientsTrainings = (state: AppState): Array<ITraining> => state.trainings.homeTrainings;
const getLastExerciseInRound = (state: AppState): IExerciseInRound => state.trainings.lastExerciseInRound;
const getClientsPreviousTrainings = (state: AppState): Array<ITraining> => state.trainings.clientsPreviousTrainings;
const getNewTrainingClientsPreviousTrainings = (state: AppState): Array<ITraining> => state.trainings.newTrainingClientsPreviousTrainings;
export const getTrainingsSelector = createSelector(getTrainings, (trainings) => trainings);
export const getClientsTrainingsSelector = createSelector(getClientsTrainings, (trainings) => trainings);
export const getIndependentUserTrainingsSelector = createSelector(getIndependentUserTrainings, (trainings) => trainings);
export const getCurrentTrainingSelector = createSelector(getCurrentTraining, (trainings) => trainings);
export const getNewTrainingSelector = createSelector(getNewTraining, (trainings) => trainings);
export const getHomeTrainingsSelector = createSelector(getHomeClientsTrainings, (trainings) => trainings);
export const getLastExerciseInRoundSelector = createSelector(getLastExerciseInRound, (trainings) => trainings);
export const getClientsPreviousTrainingsSelector = createSelector(getClientsPreviousTrainings, (trainings) => trainings);
export const getNewTrainingClientsPreviousTrainingsSelector = createSelector(getNewTrainingClientsPreviousTrainings, (trainings) => trainings);


const getTrainingsByDateAndTrainer = (state: AppState, trainerId: number, date: Date): Array<ITraining> =>
  state?.trainings?.trainings?.filter((t) => t?.trainingCreator?.id === trainerId && t?.date === date);

const getClientsTrainingById = (state: AppState, clientId: number): Array<ITraining> =>
  state?.trainings?.clientsTrainings?.filter((item) => item?.client?.id == clientId);

export const getTrainingsByDateAndTrainerSelector = (trainerId: number, date: Date) =>
  createSelector(
    (state: AppState) => getTrainingsByDateAndTrainer(state, trainerId, date),
    (trainings) => trainings
  );

export const getClientsTrainingByIdSelector = (clientId: number) =>
  createSelector(
    (state: AppState) => getClientsTrainingById(state, clientId),
    (trainings) => trainings
  );
