import React, { useState } from 'react';
import { View } from 'react-native';
import { Button, Paragraph, Dialog, Portal, Provider } from 'react-native-paper';


interface Props {
  title: string;
  text: string;
  isOpen: boolean;
  hideDialog: () => void;
  onCofirmClicked?: () => void;
}


const ConfirmationDialog = ({title, text, isOpen, hideDialog, onCofirmClicked} : Props) => {

  return (
    <View>
      <Portal>
        <Dialog visible={isOpen} onDismiss={hideDialog}>
          <Dialog.Title>{title}</Dialog.Title>
          <Dialog.Content>
            <Paragraph>{text}</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={hideDialog}>Otkaži</Button>
            <Button onPress={onCofirmClicked}>Potvrdi</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </View>
  );
};

export default ConfirmationDialog;
