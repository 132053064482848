import { Platform } from 'react-native';
import AWS from 'aws-sdk';
import Config from 'react-native-config';

// Configuration for web
const setupConfig = () => {
  const webConfig = require('../config.js').default; // Adjust the path as needed
  AWS.config.update({
    accessKeyId: webConfig.AWS_ACCESS_KEY_ID,
    secretAccessKey: webConfig.AWS_SECRET_ACCESS_KEY,
    region: webConfig.AWS_REGION
  });
};


// Conditional setup based on the platform
setupConfig()

export default AWS;
