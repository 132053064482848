export function deleteClient(id) {
    return `mutation {
      deleteClient (
        id: ${id}
      ) {
        isRequestSuccessful
        message
        id
      }
    }`;
  }
  
  export function createClient(client) {
    return `mutation {
      createClient (
        name: "${client.name}"
        familyName: "${client?.familyName || ''}"
        email: "${client?.email || ''}"
        phoneNumber: "${client?.phoneNumber || ''}"
        additionalInfo: "${client?.additionalInfo || ''}"
        fitnessCenterId: ${client?.fitnessCenter?.id}
      ) {
        message {
          isRequestSuccessful
          message
          id
        }
        client {
          id
          name
          familyName
          email
          phoneNumber
          additionalInfo
        }
      }
    }`;
  }
  
  export function updateClient(client) {
    return `mutation {
      updateClient (
        id: ${client.id}
        name: "${client.name}"
        familyName: "${client?.familyName || ''}"
        email: "${client?.email || '' }"
        phoneNumber: "${client?.phoneNumber || ''}"
        additionalInfo: "${client?.additionalInfo || ''}"
      ) {
        message {
          isRequestSuccessful
          message
          id
        }
        client {
          id
          name
          familyName
          email
          phoneNumber
          additionalInfo
        }
      }
    }`;
  }
  