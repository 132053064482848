import { all, spawn } from 'redux-saga/effects';
import exerciseGroupSaga from './ExerciseGroupSaga';
import exerciseSaga from './ExerciseSaga';
import clientSaga from './ClientSaga';
import trainingSaga from './TrainingSaga';
import authSaga from './AuthSaga';
import userSaga from './UserSaga';
import fitnessCenterSaga from './FitnessCenterSaga';


export function* startWatchers() {
  yield all([
    spawn(exerciseGroupSaga),
    spawn(exerciseSaga),
    spawn(clientSaga),
    spawn(trainingSaga),
    spawn(authSaga),
    spawn(userSaga),
    spawn(fitnessCenterSaga),
  ]);
}

export default function* rootSaga() {
  yield all([startWatchers()]);
}
