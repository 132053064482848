import { IActivity, IExerciseInRound, ITraining, TrainingStatus } from '../models/Models';
import { produce } from 'immer';
import { CLIENTS_TRAININGS, CLIENTS_TRAININGS_PAGINATION, EXERCISE_IN_ROUND, INDEPENDENT_USER_TRAININGS, HOME_TRAININGS, PREVIOUS_EXERCISE_IN_ROUND, RESET_CLIENTS_PREVIOUS_TRAININGS, RESET_CLIENTS_TRAININGS, ROUND, SWAP_EXERCISES_IN_ROUND, TRAINING, TRAININGS, UPDATE_TRAINING, UPLOAD_PDF, INDEPENDENT_USERS_TRAININGS } from '../actions/TrainingActions';


export const emptyTraining = {
  date: null,
  numberOfTrainings: 1,
  status: TrainingStatus.DRAFT,
  rounds: []
}

const emptyExerciseInRound = {
  numberOfRepetitions: '0',
  difficulty: '',
  note: '',
}

// TODO clients trainings - filter trainings, only load if not there
// cache
export interface ITrainingState {
  trainings: ITraining[];
  homeTrainings: ITraining[];
  clientsTrainings: ITraining[];
  currentTraining: ITraining;
  newTraining: ITraining;
  clientsPreviousTrainings: ITraining[];
  newTrainingClientsPreviousTrainings: ITraining[];
  lastExerciseInRound: IExerciseInRound;
}

const initialState: ITrainingState = {
  trainings: [],
  homeTrainings: [],
  clientsTrainings: [],
  clientsPreviousTrainings: [],
  newTrainingClientsPreviousTrainings: [],
  lastExerciseInRound: emptyExerciseInRound,
  currentTraining: emptyTraining,
  newTraining: emptyTraining,
};


const updateTrainings = (draft, updateTraining) => {
  draft.currentTraining = updateTraining;
  draft.trainings = draft.trainings?.map(
    (item) => item?.id != updateTraining?.id ? item : updateTraining
  );
  draft.homeTrainings = draft.homeTrainings?.map(
    (item) => item?.id != updateTraining?.id ? item : updateTraining
  );
}


const formatTraining = (training) => {
  return {...training, date: new Date(training?.date), status: TrainingStatus[training.status]}
}

const trainingReducer = (state = initialState, action: IActivity): ITrainingState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case TRAININGS.GET.SUCCESS:
        const formattedTrainings =  payload?.trainings?.map((item) => {return formatTraining(item)})
        if (payload?.page == 0) {
          draft.trainings = formattedTrainings;
        } else {
          draft.trainings = [...draft.trainings, ...formattedTrainings];
        }
        break;
      case HOME_TRAININGS.GET.SUCCESS:
        draft.homeTrainings = payload?.map((item) => {return formatTraining(item)});
        break;
      case TRAINING.POST.SUCCESS: {
        const newTraining = formatTraining(payload);
        draft.trainings = [newTraining, ...draft?.trainings];
        draft.newTraining = {...emptyTraining};
        draft.newTrainingClientsPreviousTrainings = [];
        draft.homeTrainings = [newTraining, ...draft.homeTrainings];
        break;
      }
      case TRAINING.DELETE.SUCCESS: {
        draft.trainings = draft?.trainings?.filter((training) => training?.id != payload)
        draft.clientsTrainings = draft?.clientsTrainings?.filter((training) => training?.id != payload)
        draft.homeTrainings = draft?.homeTrainings?.filter((training) => training?.id != payload)
        break;
      }
      case TRAINING.PUT.SUCCESS:
        draft.trainings = draft.trainings?.map(
          (item) => item?.id != payload?.id ? item : formatTraining(payload)
        )
        draft.currentTraining =  {...payload, date: new Date(payload?.date)}
        draft.clientsTrainings = draft.clientsTrainings?.map(
          (item) => item?.id != payload?.id ? item : formatTraining(payload)
        )
        break;
      case CLIENTS_TRAININGS.GET.SUCCESS: {
        const formattedTrainings =  payload?.trainings?.map((item) => {return formatTraining(item)})
        const newClientsTrainings = [...formattedTrainings]
        if (payload?.source == 'edit') {
          draft.clientsPreviousTrainings = newClientsTrainings;
        } else {
          draft.newTrainingClientsPreviousTrainings = newClientsTrainings;
        }
        break;
      }
      case INDEPENDENT_USERS_TRAININGS.GET.SUCCESS: {
        const formattedTrainings =  payload?.trainings?.map((item) => {return formatTraining(item)})
        const newIndependentUserTrainings = [...formattedTrainings]
        if (payload?.source == 'edit') {
          draft.clientsPreviousTrainings = newIndependentUserTrainings;
        } else {
          draft.newTrainingClientsPreviousTrainings = newIndependentUserTrainings;
        }
        break;
      }
      case CLIENTS_TRAININGS_PAGINATION.GET.SUCCESS: {
        const formattedTrainings =  payload?.trainings?.map((item) => {return formatTraining(item)})
        if (payload?.page == 0) {
          draft.clientsTrainings = formattedTrainings;
        } else {
          draft.clientsTrainings = [...draft.clientsTrainings, ...formattedTrainings];
        }
        break;
      }
      case INDEPENDENT_USER_TRAININGS.GET.SUCCESS: {
        const formattedTrainings =  payload?.trainings?.map((item) => {return formatTraining(item)})
        if (payload?.page == 0) {
          draft.trainings = formattedTrainings;
        } else {
          draft.trainings = [...draft.trainings, ...formattedTrainings];
        }
        break;
      }
      case UPDATE_TRAINING: {
        if (payload?.source == 'create') {
          draft.newTraining = payload?.training;
        } else {
          draft.currentTraining = payload?.training
        }
        break;
      }
      case RESET_CLIENTS_TRAININGS:
        draft.clientsTrainings = [];
        break;
      case RESET_CLIENTS_PREVIOUS_TRAININGS:
        draft.newTrainingClientsPreviousTrainings = [];
        break;
      case ROUND.POST.SUCCESS: {
        const currentRoundsNumber = draft.currentTraining.rounds.length + 1;
        const newRound = {
          roundSequenceNumber: currentRoundsNumber,
          roundExerciseInRound: [],
          id: payload,
        }
        const newRounds = [...draft.currentTraining.rounds, newRound];
        const newTraining = {...draft.currentTraining, rounds: newRounds};
        updateTrainings(draft, newTraining);
        break;
      }
      case ROUND.PUT.SUCCESS: {
        const newRounds = draft.currentTraining.rounds?.map((round) =>
          round?.id != payload.id ? round : payload
        )
        const updateTraining = {...draft.currentTraining, rounds: newRounds};
        updateTrainings(draft, updateTraining);
        break;
      }
      case ROUND.DELETE.SUCCESS: {
        const newRounds = draft.currentTraining.rounds?.filter((round) =>
          round?.id != payload
        )
        const updateTraining = {...draft.currentTraining, rounds: newRounds};
        updateTrainings(draft, updateTraining);
        break;
      }
      case EXERCISE_IN_ROUND.PUT.SUCCESS: {
        const newRounds = draft.currentTraining.rounds?.map((round) => {
          const newExercisesInRound = round?.roundExerciseInRound?.map((item) => item?.id == payload.id ? payload : item);
          const newRound = {...round, roundExerciseInRound: newExercisesInRound};
          return newRound;
        })
        const updateTraining = {...draft.currentTraining, rounds: newRounds};
        updateTrainings(draft, updateTraining);
        break;
      }
      case EXERCISE_IN_ROUND.DELETE.SUCCESS: {
        const newRounds = draft.currentTraining.rounds?.map((round) => {
          const newExercisesInRound = round?.roundExerciseInRound?.filter((item) => item?.id != payload);
          const newRound = {...round, roundExerciseInRound: newExercisesInRound};
          return newRound;
        })
        const updateTraining = {...draft.currentTraining, rounds: newRounds};
        updateTrainings(draft, updateTraining);
        break;
      }
      case PREVIOUS_EXERCISE_IN_ROUND.GET.SUCCESS: {
        if (payload) {
          draft.lastExerciseInRound = payload;
        } else {
          draft.lastExerciseInRound = {
            difficulty: '',
            note: '',
            numberOfRepetitions: '0',
          }
        }
        break;
      }
      case SWAP_EXERCISES_IN_ROUND.POST.SUCCESS: {
        const round = draft.currentTraining.rounds?.find((round) => round?.id == payload?.roundId);
        const index = round?.roundExerciseInRound.findIndex((exerciseInRound) => exerciseInRound?.id == payload?.exerciseInRoundId2);
        const exerciseInRound1 =  round?.roundExerciseInRound[index-1];
        const exerciseInRound2 =  round?.roundExerciseInRound[index];
        const newExercisesInRound = [...round?.roundExerciseInRound]
        newExercisesInRound[index-1] = exerciseInRound2;
        newExercisesInRound[index] = exerciseInRound1;
        const newRound = {...round, roundExerciseInRound: newExercisesInRound};

        const newRounds = draft.currentTraining.rounds?.map((round) =>
          round?.id == payload?.roundId ? newRound : round
        )

        const updateTraining = {...draft.currentTraining, rounds: newRounds};
        updateTrainings(draft, updateTraining);
        break;
      }
      default:
        return draft;
    }
  });

export default trainingReducer;
