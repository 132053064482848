import { IActivity, IExerciseGroup } from '../models/Models';
import { EXERCISE_GROUP, EXERCISE_GROUPS } from '../actions/ExerciseGroupActions';
import { produce } from 'immer';

export interface IExerciseGroupState {
  exerciseGroups: IExerciseGroup[];
}

const initialState: IExerciseGroupState = {
  exerciseGroups: []
};

const exerciseGroupReducer = (state = initialState, action: IActivity): IExerciseGroupState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case EXERCISE_GROUPS.GET.SUCCESS:
        draft.exerciseGroups = payload;
        break;
      case EXERCISE_GROUP.DELETE.SUCCESS:
        draft.exerciseGroups = state.exerciseGroups.filter((eg) => eg.id !== payload);
        break;
      case EXERCISE_GROUP.POST.SUCCESS:
        draft.exerciseGroups.push(payload)
        break;
      case EXERCISE_GROUP.PUT.SUCCESS:
        draft.exerciseGroups = state.exerciseGroups.map((eg) => {
          return eg?.id == payload?.id ? payload : eg;
        });
      default:
        return draft;
    }
  });

export default exerciseGroupReducer;
