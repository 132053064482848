import React, { useState } from "react";
import { Button, TextInput, withTheme, } from "react-native-paper";
import {StyleSheet, TouchableOpacity, View } from 'react-native';
import DropDown from "react-native-paper-dropdown";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

interface IInputProps {
  theme: any;
  label: string;
  value: any;
  onChangeText?: (string) => void;
  inputType?: string;
  disabled?: boolean;
  editable?: boolean;
  autoFocus?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onSubmitEditing?: (any) => void;
}

interface IIconInputProps {
  theme: any;
  label: string;
  value: any;
  onChangeText: (string) => void;
  icon: any;
  autoFocus?: boolean;
  onSubmitEditing?: (any) => void;
}


interface IDropdownProps {
  theme: any;
  label: string;
  value: any;
  onValueChanged: (string) => void;
  isDropdownVisible: boolean;
  toggleDropDown: (boolean) => void;
  valuesList: any[];
}

interface ITextInputProps {
  theme: any;
  label: string;
  value: any;
  onChangeText: (string) => void;
  autoFocus?: boolean;
  onSubmitEditing?: (any) => void;
}


const OutlinedTextInputComponent = ({theme, onBlur, onFocus, label, value, onChangeText, disabled, editable, inputType, autoFocus}: IInputProps) => {

  const keyboardType = inputType ? 'numeric': 'default';
  return (
    <TextInput
      disabled={disabled}
      editable={editable}
      label={label}
      value={value}
      onChangeText={(text) => onChangeText(text)}
      outlineStyle={styles(theme).inputBorderStyle}
      mode={'outlined'}
      keyboardType={keyboardType}
      theme={inputTheme(theme)}
      autoFocus={autoFocus}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  )
}


const OutlinedPasswordInputComponent = ({theme, label, value, onChangeText, onBlur, onFocus}: IInputProps) => {

  const [passwordVisibility, setPasswordVisibility] = useState(true);
  const [rightIcon, setRightIcon] = useState(faEye);


  const handlePasswordVisibility = () => {
    if (passwordVisibility) {
      setRightIcon(faEyeSlash);
      setPasswordVisibility(!passwordVisibility);
    } else if (!passwordVisibility) {
      setRightIcon(faEye);
      setPasswordVisibility(!passwordVisibility);
    }
  };


  return (
    <View style={styles(theme).inputContainer}>
      <TextInput
        theme={inputTheme(theme)}
        label={label}
        style={styles(theme).inputField}
        autoCorrect={false}
        textContentType="newPassword"
        secureTextEntry={passwordVisibility}
        value={value}
        enablesReturnKeyAutomatically
        onChangeText={text => onChangeText(text)}
        mode={'outlined'}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      <TouchableOpacity  onPress={handlePasswordVisibility} style={styles(theme).eyeContainer}>
        <FontAwesomeIcon icon={rightIcon} size={25}/>
      </TouchableOpacity>
  </View>

  );
}

const IconTexInputContainer = ({theme, label, value, onChangeText, icon, autoFocus}: IIconInputProps) => {

  const onTetxtUpdate = (text) => {
    onChangeText(text);
    // focus();
  }
  return (
    <TextInput
      label={label}
      value={value}
      onChangeText={(text) => onTetxtUpdate(text)}
      outlineStyle={styles(theme).inputBorderStyle}
      theme={inputTheme(theme)}
      mode={'outlined'}
      left={<TextInput.Icon icon={icon} />}
      autoFocus={autoFocus}
    />
  )
}

const DropdownInputComponent = ({theme, label, value, onValueChanged, isDropdownVisible, toggleDropDown, valuesList}: IDropdownProps) => {
  return (
    <DropDown
      label={label}
      value={value}
      inputProps={{
        outlineStyle: styles(theme).inputBorderStyle
      }}
      mode={'outlined'}
      theme={inputTheme(theme)}
      visible={isDropdownVisible}
      showDropDown={() => toggleDropDown(true)}
      onDismiss={() => toggleDropDown(false)}
      setValue={onValueChanged}
      list={valuesList}
      dropDownItemStyle={styles(theme).dropdownItemStyle}
      dropDownStyle={styles(theme).dropdownItemStyle}
    />
  )
  // TODO: set dropdown background color
}


const NumberInputContainer = ({theme, value, label, onChangeText, autoFocus}: ITextInputProps) => {
  function incrementCount() {
    onChangeText((parseInt(value) + 1) + '')
  }
  function decrementCount() {
    onChangeText((parseInt(value) - 1) + '')
  }
  const themeStyles = styles(theme);
  return (
      <View style={themeStyles.numericContainer}>
        <Button labelStyle={themeStyles.numericButtonLabel} style={[themeStyles.numericButton, themeStyles.leftButton]}onPress={incrementCount}>+</Button>
        <TextInput
          style={themeStyles.numericInput}keyboardType={'numeric'}
          value={value} onChangeText={(text) => onChangeText(text)}
          theme={inputTheme(theme)}
          autoFocus={autoFocus}
        />
        <Button labelStyle={themeStyles.numericButtonLabel} style={[themeStyles.numericButton, themeStyles.rightButton]} onPress={decrementCount}>-</Button>
      </View>
  )
}

const CustomTextInputContainer = ({theme, value, label, onChangeText}: ITextInputProps) => {
  return (
    <TextInput
      label={label}
      style={styles(theme).customInput} value={value} onChangeText={(text) => onChangeText(text)} theme={inputTheme(theme)}/>
  )
}


const TextAreaContainer = ({theme, value, label, onChangeText}: ITextInputProps) => {
  return (
    <TextInput
      multiline={true}
      label={label}
      numberOfLines={5}
      style={styles(theme).textArea} value={value} onChangeText={(text) => onChangeText(text)} theme={inputTheme(theme)}/>
  )
}


const inputTheme = (theme) => {
  return (
    {colors: {
      placeholder: theme.colors.primaryText,
      primary: theme.colors.primaryText,
      text: theme.colors.primaryText,
      color: theme.colors.primaryText,
      onSurface: theme.colors.primaryText,
      background : theme.colors.primaryBackground}
    }
  )
}


const styles = theme => StyleSheet.create({
  inputBorderStyle: {
    borderRadius: 10,
    borderColor: theme.colors.primaryText
  },
  dropdownItemStyle: {
    backgroundColor: theme.colors.primaryBackground
  },
  numericContainer: {
    flexDirection: 'row',
  },
  numericButton: {
    backgroundColor: theme.colors.primaryText,
    borderWidth: 1,
    borderRadius: 100,
  },
  numericButtonLabel: {
    color: theme.colors.lightText,
    fontSize: 20,
    textAlign: 'center',
    paddingTop: 10,
  },
  leftButton: {
    marginRight: -30,
    zIndex: 1,
  },
  rightButton: {
    marginLeft: -30,
    zIndex: 1
  },
  numericInput: {
    textAlign: 'center',
    width: 120,
    backgroundColor: theme.colors.primaryBackground,
    borderBottomWidth: 0,
  },
  customInput: {
    textAlign: 'center',
    width: 400,
    backgroundColor: theme.colors.primaryBackground,
    borderBottomWidth: 0,
  },
  textArea: {
    backgroundColor: theme.colors.primaryBackground,
  },
  inputContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputField: {
    width: '100%'
  },
  eyeContainer: {
    position: 'absolute',
    right: 10,
  }
})

export const OutlinedTextInput = withTheme(OutlinedTextInputComponent);
export const DropDownInput = withTheme(DropdownInputComponent);
export const IconTextInput = withTheme(IconTexInputContainer);
export const NumberInput = withTheme(NumberInputContainer);
export const CustomTextImput = withTheme(CustomTextInputContainer);
export const TextArea = withTheme(TextAreaContainer);
export const OutlinedPasswordInput = withTheme(OutlinedPasswordInputComponent);
