import React from 'react';
import { View, ActivityIndicator, StyleSheet } from 'react-native';
import { withTheme } from 'react-native-paper';

const LoadingIndicator = ({ theme }) => {
  const themeStyles = styles(theme);

  return (
    <View style={themeStyles.loadingContainer}>
      <ActivityIndicator size="large" />
    </View>
  );
};

const styles = theme => StyleSheet.create({
  loadingContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 80,
    alignItems: 'center',
    justifyContent: 'center',
    height: 50, // Adjust the height as needed
    backgroundColor: 'rgba(0, 0, 0, 0.2)', // Semi-transparent background
    zIndex: 1, // Ensure it's on top
  },
});

export default withTheme(LoadingIndicator);
