import { faXmark } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { View, StyleSheet, Text, ScrollView } from 'react-native';
import Modal from 'react-native-modal/dist/modal';
import { List, withTheme } from 'react-native-paper';
import { IUser } from '../../../core/models/Models';
import IconButton from '../IconButton';


interface Props {
  theme: any;
  user: IUser;
  isDialogVisible: boolean;
  onCenterSelected: (any) => void;
  onDismiss: () => void;
}


const getCentersList = (user: IUser) => {
  const centersList = user.userFitnessCenterList?.map((centerRole) => {
    const currentCenter = {}
    currentCenter["label"] = centerRole.fitnessCenter.name;
    currentCenter["value"] = centerRole.fitnessCenter.id;
    return currentCenter;
  });
  centersList.push({label: "Samostalno", value: 0})
  return centersList;
}

const FitnessCenterSelectionDialog = ({theme, user, isDialogVisible, onCenterSelected, onDismiss}: Props) => {
  const themeStyles = styles(theme);
  const centersList = getCentersList(user);
  const currentCenterId = user?.currentFitnessCenter?.id || 0;

  return (
    <Modal isVisible={isDialogVisible} onDismiss={onDismiss}>
      <View style={themeStyles.modalContent}>
        <View style={[themeStyles.header, themeStyles.regularBackground]}>
          <View style={themeStyles.buttonHeaderContainer}>
            <IconButton width={10} icon={faXmark} iconSize={20} onPress={onDismiss}/>
          </View>
          <View style={themeStyles.headerTextContainer}>
            <Text style={themeStyles.headerText}>Selekcija fitnes centra</Text>
          </View>
        </View>
        <ScrollView style={themeStyles.mainContent}>
          <View style={themeStyles.mainContentContainer}>
            {centersList.map((center) =>
              <List.Item
                title={center['label']}
                onPress={() => onCenterSelected(center["value"])}
                right={props => <List.Icon {...props} icon={currentCenterId == center['value'] ? "checkbox-marked-circle-outline" : 'checkbox-blank-circle-outline'} />}
                titleStyle={themeStyles.listItem}
            />
            )}
          </View>
        </ScrollView>
      </View>
    </Modal>
  )
}

const styles = theme => StyleSheet.create({
  regularBackground: {
    backgroundColor: theme.colors.tertiaryBackground,
  },
  header: {
    flexDirection: 'row',
    height: 70,
    width: '100%',
    borderBottomColor: theme.colors.primaryText,
    borderBottomWidth: 1,
  },
  listItem: {
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 18,
    paddingBottom: 10,
  },
  buttonHeaderContainer: {
    alignSelf: 'center',
    width: 50,
  },
  mainContentContainer: {
    margin: 15,
  },
  headerText: {
    color: theme.colors.primaryText,
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 20,
    fontWeight: '600',
  },
  headerTextContainer: {
    flexGrow: 1,
    alignSelf: 'center',
    marginLeft: -20,
  },
  containerStyle: {
    padding: 20,
  },
  modalContent: {
    borderRadius: 10,
    overflow: 'hidden',
  },
  mainContent: {
    flexDirection: 'column',
    backgroundColor: theme.colors.primaryBackground,
  },
  buttonContainer: {
    marginTop: 20,
    paddingBottom: 30
  },
  saveButton: {
    backgroundColor: theme.colors.primaryText,
    color: theme.colors.lightText,
    fontFamily: theme.fontFamilies.primaryFont,
    marginTop: 20,
    marginBottom: 10,
  },
  cancelButton: {
    backgroundColor: theme.colors.lightText,
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    borderWidth: 1,
    borderColor: theme.colors.primaryText,
  },
})

export default withTheme(FitnessCenterSelectionDialog);
