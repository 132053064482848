import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, ScrollView, ActivityIndicator } from 'react-native';
import { Chip, withTheme } from 'react-native-paper';
// import { AlphabetList } from "react-native-section-alphabet-list";
import { useDispatch, useSelector } from 'react-redux';
import { deleteExercise, getExercises } from '../../core/actions/ExerciseActions';
import { getExerciseGroups } from '../../core/actions/ExerciseGroupActions';
import { getExerciseGroupsSelector } from '../../core/selectors/ExerciseGroupSelectors';
import { getExercisesSelector } from '../../core/selectors/ExerciseSelector';
import BottomMenu from '../components/BottomMenu';
import ConfirmationDialog from '../components/ConfirmationDialog';
import IconButton from '../components/IconButton';
import { IconTextInput } from '../components/InputComponents';
import { userSelector } from '../../core/selectors/UserSelector';
import AlphabetList from '../components/AlphabetList';
import LoadingIndicator from '../components/LoadingContainer';


interface Props {
  theme: any;
  navigation: any;
}


const preparateExercisesData = (exercises) => {
  return exercises.map((item) => {
    return {
      key: item?.id,
      value: `${item.name}`,
      exerciseGroups: (item.exerciseGroups).map((item) =>
       {
        return {
          groupId: item?.id,
          groupName: item?.name
        }
      }
      )
  }})
}


const ExercisesContainer = ({navigation, theme}: Props) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedChips, setSelectedChips] = useState([]);
  const [isAllGroupsSelected, setIsAllGroupsSelected] = useState(true);
  const [exercisesData, setExercisesData] = useState([]);
  const [filteredExercisesData, setFilteredExercisesData] = useState([]);
  const exercises = useSelector(getExercisesSelector);
  const exerciseGroups = useSelector(getExerciseGroupsSelector);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState(undefined);
  const themeStyles = styles(theme)
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const ITEMS_PER_PAGE = 50;
  const [displayCount, setDisplayCount] = useState(ITEMS_PER_PAGE);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (!exercises?.length) {
      if(!user.isIndependentUser){
        dispatch(getExercises.request(user?.currentFitnessCenter?.id));
      } else {
        dispatch(getExercises.request(null)); //If user is independent then 
      }
    }
    if (!exerciseGroups?.length) {
      dispatch(getExerciseGroups.request());
    }
  }, []);

  useEffect(() => {
    if(exercises != null){
      const formattedExercises = preparateExercisesData(exercises)
      setExercisesData(formattedExercises);
      setFilteredExercisesData(formattedExercises);
      } else {
        navigation.navigate('Home');
      }
  }, [exercises]);


  const checkIfContained = (newSelectedChips, exercise) => {
    return newSelectedChips.some(chip =>
      exercise.exerciseGroups.some(group => chip === group.groupId)
    );
  };

  const toggleChipSelection = (chipId) => {
    let newSelectedChips = [];
    if (selectedChips.includes(chipId)) {
      newSelectedChips = selectedChips.filter((element) => element != chipId);
      setSelectedChips(newSelectedChips);
    } else {
      setIsAllGroupsSelected(false);
      newSelectedChips = [...selectedChips, chipId]
      setSelectedChips(newSelectedChips);
    }
    const newFilteredExercises = exercisesData.filter((exercise) =>
    checkIfContained(newSelectedChips, exercise) && exercise.value.toLowerCase().includes(searchQuery.toLowerCase()));
    setFilteredExercisesData(newFilteredExercises);
  }

  const toggleAllSelected = () => {
    const newIsAllGroupsSelected = !isAllGroupsSelected;
    setIsAllGroupsSelected(newIsAllGroupsSelected);
    if (newIsAllGroupsSelected) {
      const newFilteredExercises  = exercisesData.filter((exercise) => exercise.value.toLowerCase().includes(searchQuery.toLowerCase()));
      setFilteredExercisesData(newFilteredExercises);
      setSelectedChips([]);
    }
    else {
      const newFilteredExercises = exercisesData.filter((exercise) =>
      checkIfContained(selectedChips, exercise) && exercise.value.toLowerCase().includes(searchQuery.toLowerCase()));
      setFilteredExercisesData(newFilteredExercises);
    }
  }

  const isChipSelected = (chipId) => {
    return selectedChips.includes(chipId);
  }


  const handleSearchExercises = (query) => {
    const newFilteredExercises = exercisesData.filter((exercise) =>
      (isAllGroupsSelected || checkIfContained(selectedChips, exercise)) /*selectedChips?.includes(exercise?.groupId))*/ && exercise.value.toLowerCase().includes(query.toLowerCase()));
    setFilteredExercisesData(newFilteredExercises);
    setSearchQuery(query);
  }

  const handleDeleteExercise = () => {
    setSelectedExercise(undefined);
    setIsDeleteDialogOpen(false);
    dispatch(deleteExercise.request(selectedExercise.key));
  }

  const openDeleteExerciseDialog = (item) => {
    setSelectedExercise(item);
    setIsDeleteDialogOpen(true);
  }

  const hideDeleteExerciseDialog = () => {
    setIsDeleteDialogOpen(false);
  }

  const openCreateExercise = () => {
    navigation.navigate('CreateExercise')
  }

  const openEditExercise = (exerciseItem) => {
    navigation.navigate('EditExercise', {exerciseId:  exerciseItem?.key})
  }

  const groupNames = (item) => {
    return item?.exerciseGroups?.map(group => group.groupName).join(", ") || "";
  };

  const onScroll = ({ nativeEvent }) => {
    if (isCloseToBottom(nativeEvent) && displayCount < filteredExercisesData.length) {
      setDisplayCount(currentCount => currentCount + ITEMS_PER_PAGE);
      setIsLoading(true);
      setTimeout(() => {
        // Here you would load or render your items
        setIsLoading(false);
      }, 500); // 300 milliseconds dela
    }
  };

  const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
    const paddingToBottom = 300;
    return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
  };


  const renderListItem = (item: any) => {
    return (
      <View style={themeStyles.itemContainer} key={`item${item.id}`}>
        <View style={themeStyles.itemExerciseContainer}>
          <TouchableOpacity style={themeStyles.exerciseContainer} onPress={() => openEditExercise(item)}>
            <View style={themeStyles.exerciseTexts}>
              <Text style={themeStyles.itemExerciseText}>{item.value}</Text>
              <Text style={themeStyles.itemExerciseGroupText}>{groupNames(item)}</Text>
            </View>
            <View>
          </View>
          </TouchableOpacity>

          {!user.isIndependentUser && (
            <IconButton icon={faTrash} onPress={() => openDeleteExerciseDialog(item)} iconSize={20}/>
          )}
        </View>
      </View >
    );
  };


  const renderHeader = () => {
    return (
      <View>
        {/* <ScreenHeader buttonText='Dalje' onButtonPressed={openExercisesInfoDialog}/> */}
        <View style={themeStyles.inputContainer}>
          <View style={themeStyles.row}>
            <View style={themeStyles.searchContainer}>
              <IconTextInput
                label="Pretraga"
                value={searchQuery}
                onChangeText={(text) => handleSearchExercises(text)}
                icon={'magnify'}
              />
            </View>
            {!user.isIndependentUser && (
              <View style={themeStyles.buttonContainer}>
                <IconButton icon={faPlus} onPress={openCreateExercise} iconSize={35}/>
              </View>
            )}
          </View>
        </View>
        <View style={themeStyles.chipsContainer}>
          <Chip key={'all'} selected={isAllGroupsSelected} style={themeStyles.chip}
              compact onPress={toggleAllSelected}>
              Sve
          </Chip>
          {exerciseGroups?.map((group) => (
            <Chip key={group.id} selected={isChipSelected(group.id)} style={themeStyles.chip}
                compact onPress={() => toggleChipSelection(group.id)}>
              {group.name}
            </Chip>
          ))}
        </View>
        <ConfirmationDialog
          title="Potvrda"
          text={`Da li ste sigurni da želite da izbrišete vežbu "${selectedExercise?.value || ''}"?`}
          isOpen={isDeleteDialogOpen}
          hideDialog={hideDeleteExerciseDialog}
          onCofirmClicked={handleDeleteExercise}
      />
      </View>
    );
  };


  return (
    <View style={themeStyles.root}>
      <ScrollView onScroll={onScroll} style={themeStyles.mainContent} scrollEventThrottle={16}>
        {renderHeader()}
        <View>
          <AlphabetList items={filteredExercisesData} renderListItem={renderListItem} displayCount={displayCount}/>
        </View>
      </ScrollView>
        {isLoading && (
          <LoadingIndicator/>
        )}
      <BottomMenu navigation={navigation} currentScreen='ViewExercises'/>
    </View>
  );
};

const styles = theme => StyleSheet.create({
  inputContainer: {
    margin: 10,
  },
  containerStyle: {
    padding: 20,
  },
  root: {
    flex: 1,
    backgroundColor: theme.colors.lightText,
  },
  chipsContainer: {
    flexDirection: 'row',
    margin: 10,
    flexWrap: 'wrap',
  },
  chip: {
    margin: 5,
    backgroundColor: theme.colors.quaternaryBackground,
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.secondaryFont,
    borderColor: theme.colors.primaryText,
    borderWidth: 1,
    borderRadius: 20,
  },
  mainContent: {
    flexDirection: 'column',
    backgroundColor: theme.colors.lightText,
    marginBottom: 80,
  },
  alphabetList: {
    flex: 1,
  },
  itemContainer: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 0,
    marginTop: 10,
  },
  itemExerciseText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 20,
    fontWeight: '600',
    marginBottom: 5,
  },
  itemExerciseGroupText: {
    color: theme.colors.secondaryText,
    fontFamily: theme.fontFamilies.secondaryFont,
    fontSize: 12,
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  itemExerciseContainer: {
    borderTopColor: theme.colors.primaryText,
    borderTopWidth: 1,
    paddingTop: 10,
    paddingBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  exerciseTexts: {
    marginRight: 5,
    numberOfLines: 3,
    flex: 1,
  },
  exerciseContainer: {
    flex: 6,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  selectedContainer: {
    alignItems: 'flex-end',
    flex: 6,
  },
  indexLetters: {
    color: theme.colors.tertiaryBackground,
    fontSize: 12,
    margin: 10
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 10,
  },
  searchContainer: {
    width: '70%',
    marginRight: 20,
    marginBottom: 20,
  },
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 20,
  },
});



export default withTheme(ExercisesContainer);

