export const trainingsData = `
{
  id
  date
  status
  pdfURL
  client {
    name
    familyName
    id
  }
  numberOfTrainings
  trainingCreator {
    id
  }
  trainingExecutor {
    id
    personalName
    familyName
    userName
  }
  rounds {
    id
    roundSequenceNumber
    roundExerciseInRound {
      id: ExecInRound_Id
      numberOfRepetitions
      difficulty
      note
      exercise {
        id
        name
        exerciseGroups {
          name
        }
      }
    }
  }
}`

export const getExerciseGroups = () => `
  query {
    allExerciseGroup {
        id,
        name,
        exercises {
          id,
          name,
          description,
          exerciseGroups {
            id,
            name
          }
        }
    }
  }
`
//Not finished
export const getExerciseInRound = () => `
  query {
    exerciseInRoundById {
      id,
        {
        ExecInRound_Id,
        numberOfRepetitions,
        difficulty,
        note,
        exerciseID,
        exerciseName,
        exercise {
          id,
          name,
          description,
        }
        round {

        }
      }
    }
  }
`

export const getExercises = (currentFitnessCenterId) => `
  query {
    allExercise(
      currentFitnessCenterId: ${currentFitnessCenterId}
    )
    {
      message {
        isRequestSuccessful
        message
        id
      }
      exerciseList {
        id,
        name,
        description,
        exerciseGroups {
          id,
          name
        }
      }
    }
  }
`

export const getClients = (fitnessCenterId) => `
  query {
    filterClientsByFitnessCenter(
      fitnessCenterId: ${fitnessCenterId}
    ) {
      client {
        id,
        name,
        familyName,
        email,
        phoneNumber,
        additionalInfo
      }
      count
    }
  }
`

export const filterTrainingsByDateAndExecutor = (executorId, startDate, endDate) => {

  const executorStr = executorId ? `executorId: ${executorId}` : '';
  return `
    query {
      getHomepageTrainingByTrainerBetweenDates(
        ${executorStr}
        startDate: "${startDate.toISOString().split('.')[0]}"
        endDate: "${endDate.toISOString().split('.')[0]}"
      ) {
      trainingList
        ${trainingsData}
      }
    }
  `
}


export const getTrainingsPagination = (start, count, fitnessCenterId) => `
  query {
    getTrainingPagination(
      startPosition: ${start}
      count: ${count}
      fitnessCenterId: ${fitnessCenterId}
      )
     {
      trainingList
        ${trainingsData}
    }
  }
`

export const getLast50TrainingsOfClient = (id) => `
  query {
    getLast50TrainingsOfClient (clientId: ${id})
    {
      trainingList
        ${trainingsData}
    }
  }
`

export const getClientsPreviousTrainings = (clientId, startDate, numberOfTrainings) => `
  query {
    getClientTrainingsFromDate(
      clientId: ${clientId}
      startDate: "${startDate.toISOString().split('.')[0]}"
      count: ${numberOfTrainings})
     {
      trainingList
        ${trainingsData}
    }
  }
`

  export const getIndependentUserPreviousTrainings = (userId, startDate, numberOfTrainings) => `
  query {
    getIndependentUserTrainingsFromDate(
      userId: ${userId}
      startDate: "${startDate.toISOString().split('.')[0]}"
      count: ${numberOfTrainings})
     {
      trainingList
        ${trainingsData}
    }
  }
`


export const getClientsTrainingsPagination = (clientId, start, count) => `
  query {
    getTrainingPagination(
      clientId: ${clientId}
      startPosition: ${start}
      count: ${count})
    {
      trainingList
        ${trainingsData}
    }
  }
  `

  export const getIndependentUserTrainingsPagination = (creatorId, start, count) => `
  query {
    getTrainingPagination(
      creatorId: ${creatorId}
      startPosition: ${start}
      count: ${count})
    {
      trainingList
        ${trainingsData}
    }
  }
  `

export const getPreviousExerciseInRoundValue = (clientId, exerciseId) => `
  query {
    getPreviousExerciseInRoundValue(
      clientId: ${clientId}
      exerciseId: ${exerciseId}
    ) {
      exerciseInRound {
        numberOfRepetitions,
        difficulty,
        note,
      }
    }
  }
`

export const getUser = () => `
  query {
    getUser {
      userName,
      personalName,
      familyName,
      email,
      id,
      authorities,
      userFitnessCenterList {
        authorities
        fitnessCenter {
          id
          name
          address
          phoneNumber
          emailAddress
        }
      }
    }
  }
`

export const findUsersByEmail = (username) => `
  query {
    getUsersByUsernameLike (
      username: "${username}"
    ) {
      personalName,
      familyName,
      id,
      userName,
      userFitnessCenterList {
        fitnessCenter {
          id
        }
      }
    }
  }
`

export const findUsersByFitnessCenter = (fitnessCenterId) => `
  query {
    getUsersByFitnessCenter (
      fitnessCenterId: "${fitnessCenterId}"
    ) {
      personalName,
      familyName,
      id,
      userName,
    }
  }
`
export const findFitnessCenter = (fitnessCenterId) => `
  query {
    getUserByFitnessCenter (
      fitnessCenterId: "${fitnessCenterId}"
    ) {
      name,
      address,
      phoneNumber,
      email
    }
  }
`