import * as Alert from '../../utils/Alerts';
import axios from "axios";
import { all, put, takeLatest } from 'redux-saga/effects';
import { filterTrainingsByDateAndExecutor,
  getClientsPreviousTrainings as getClientsPreviousTrainingsQuery,
  getIndependentUserPreviousTrainings as getIndependentUserPreviousTrainingsQuery,
  getTrainingsPagination as getTrainingsPaginationQuery,
  getClientsTrainingsPagination as getClientsTrainingsPaginationQuery,
  getPreviousExerciseInRoundValue as getPreviousExerciseInRoundValueQuery,
  getIndependentUserTrainingsPagination as getIndependentUserTrainingsPaginationQuery
 } from '../graphql/queries';
import {
  deleteTraining as deleteTrainingMutation,
  createTraining as createTrainingMutation,
  updateTraining as updateTrainingMutation,
  createRound as createRoundMutation,
  updateRound as updateRoundMutation,
  deleteRound as deleteRoundMutation,
  updateExerciseInRound as updateExerciseInRoundMutation,
  deleteExerciseInRound as deleteExerciseInRoundMutation,
  swapExercisesInRound as swapExercisesInRoundMutation,
} from '../graphql/trainingMutations';
import { TRAINING, TRAININGS, getTrainings, createTraining , deleteTraining, updateTraining, CLIENTS_TRAININGS, ROUND, createRound, updateRound, deleteRound, HOME_TRAININGS, getHomeTrainings, getClientsPreviousTrainings, updateExerciseInRound, deleteExerciseInRound, EXERCISE_IN_ROUND, CLIENTS_TRAININGS_PAGINATION, getClientsTrainingsPagination, PREVIOUS_EXERCISE_IN_ROUND, getClientsPreviousExerciseInRound, swapExercisesInRound, SWAP_EXERCISES_IN_ROUND, uploadPDF, UPLOAD_PDF, INDEPENDENT_USER_TRAININGS, INDEPENDENT_USERS_TRAININGS, getIndependentUserTrainings, getIndependentUsersPreviousTrainings } from '../actions/TrainingActions';
import { formatDateTimeDatabase } from '../../utils/utils';
import { addClientsTraining, removeClientsTraining } from '../actions/ClientActions';
import { graphQLEndpoint } from '../settings';
import { getHeaders } from './Helper';
import { uploadTrainingToS3 } from '../../utils/AWS';

export function* getTrainingsSaga({payload: { start, count, fitnessCenterId }}) {
  try {
    const query = getTrainingsPaginationQuery(start, count, fitnessCenterId);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();
    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(getTrainings.success({page: start, trainings: response?.data?.data?.getTrainingPagination.trainingList}));
  } catch (e) {
    yield put(getTrainings.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri učitavanju treninga");
  }
}


export function* filterTrainingsByDateAndExecutorSaga({payload: {startDate, endDate, userId}}) {
  try {
    const query = filterTrainingsByDateAndExecutor(userId, startDate, endDate);
    const body = JSON.stringify({query});
    console.log(body);
    const headers = yield getHeaders();
    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(getHomeTrainings.success(response?.data?.data?.getHomepageTrainingByTrainerBetweenDates?.trainingList));
  } catch (e) {
    yield put(getHomeTrainings.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri učitavanju treninga trenera");
  }
}


export function* getClientsTrainingsSaga({ payload: { clientId, startDate, source='edit', numberOfTrainings=3 } }) {
  try {
    const query = getClientsPreviousTrainingsQuery(clientId, startDate, numberOfTrainings);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();

    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(getClientsPreviousTrainings.success({
      clientId: clientId,
      trainings: response?.data?.data?.getClientTrainingsFromDate.trainingList,
      source: source,
    }))
  } catch (e) {
    yield put(getClientsPreviousTrainings.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri učitavanju treninga klijenta");
  }
}

export function* getIndependentUserTrainingsSaga({ payload: { userId, startDate, source='edit', numberOfTrainings=3 } }) {
  try {
    const query = getIndependentUserPreviousTrainingsQuery(userId, startDate, numberOfTrainings);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();
    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(getIndependentUsersPreviousTrainings.success({
      trainings: response?.data?.data?.getIndependentUserTrainingsFromDate.trainingList,
      source: source,
    }))
  } catch (e) {
    yield put(getIndependentUsersPreviousTrainings.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri učitavanju treninga nezavisnog koristnika.");
  }
}

export function* getClientsTrainingsPaginationSaga({ payload: { clientId, start, count } }) {
  try {
    const query = getClientsTrainingsPaginationQuery(clientId, start, count);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();
    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(getClientsTrainingsPagination.success({
      clientId: clientId,
      trainings: response?.data?.data?.getTrainingPagination.trainingList,
      page: start,
    }))
  } catch (e) {
    yield put(getClientsTrainingsPagination.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri učitavanju treninga klijenta");
  }
}

export function* getIndependentUserTrainingsPaginationSaga({ payload: { creatorId, start, count } }) {
  try {
    const query = getIndependentUserTrainingsPaginationQuery(creatorId, start, count);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();
    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(getIndependentUserTrainings.success({
      creatorId: creatorId,
      trainings: response?.data?.data?.getTrainingPagination.trainingList,
      page: start,
    }))
  } catch (e) {
    yield put(getIndependentUserTrainings.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri učitavanju treninga nezavisnog koristnika!");
  }
}

export function escapeSingleAndDoubleQuotes(str) {
  return str.replace(/\\([\s\S])|(")/g, '\\$1$2');
}

export function* createTrainingSaga({ payload: { training, fitnessCenterId} }) {
  try {
    training.trainingExecutor = training.trainingCreator;
    let updatedTraining = {...training}
    if (training?.pdfFile) {
      const uploadData = yield uploadTrainingToS3(training, fitnessCenterId, training?.pdfFile);
      const pdfURL = uploadData.Location;
      updatedTraining = {...training, pdfURL: pdfURL}
    }
    console.log(updateTraining);
    const query = createTrainingMutation(updatedTraining);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();

    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.errors);
    }
    const newTraining = {...response?.data?.data?.createTraining?.training, pdfURL: updatedTraining?.pdfURL}
    yield put(createTraining.success({...newTraining, date: formatDateTimeDatabase(training.date)}));
    if(training?.client){
      yield put(addClientsTraining(newTraining?.client?.id));
    }
    yield Alert.setSuccessAlert("Trening je uspešno kreiran");
  } catch (e) {
    yield put(createTraining.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri kreiranju treninga");
  }
}

export function* updateTrainingSaga({ payload: { training} }) {
  try {

    const query = updateTrainingMutation(training);

    const body = JSON.stringify({query});
    const headers = yield getHeaders();
    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.errors);
    }
    if (!response?.data?.data?.updateTraining?.message?.isRequestSuccessful) {
      throw new Error(response?.data?.data?.updateTraining?.message?.message);
    }
    yield put(updateTraining.success(response?.data?.data?.updateTraining?.training));
    yield Alert.setSuccessAlert("Trening je uspešno ažuriran");
  } catch (e) {
    yield put(updateTraining.failure(e));
    console.log(e)
    yield Alert.setErrorAlert("Desila se greška pri izmeni treninga");
  }
}

export function *deleteTrainingSaga({ payload: { training} }) {
  try {
    const query = deleteTrainingMutation(training?.id);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();

    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.data?.errors[0]?.message);
    }

    yield put(deleteTraining.success(training?.id));
    yield put(removeClientsTraining(training?.client?.id));
    yield Alert.setSuccessAlert("Trening je uspešno izbrisan.");
  } catch (e) {
    console.log(e)
    yield put(deleteTraining.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri brisanju treninga.");
  }
}

export function* createRoundSaga({ payload: { round, trainingId } }) {
  try {
    const query = createRoundMutation(round, trainingId);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();

    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.errors);
    }
    yield put(createRound.success(response?.data?.data?.createRound?.id));
  } catch (e) {
    yield put(createRound.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri kreiranju nove grupe");
  }
}


export function* updateRoundSaga({ payload: { roundId, newExercisesInRound } }) {
  try {
    const query = updateRoundMutation(roundId, newExercisesInRound);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();

    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.errors);
    }
    yield put(updateRound.success(response?.data?.data?.updateRound?.round));
    yield Alert.setSuccessAlert("Vežbe su uspešno dodate");
  } catch (e) {
    yield put(updateRound.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri dodavanju vežbi");
  }
}

export function* deleteRoundSaga({ payload: { roundId } }) {
  try {
    const query = deleteRoundMutation(roundId);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();
    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.errors);
    }
    yield put(deleteRound.success(roundId));
    yield Alert.setSuccessAlert("Grupa je uspešno izbrisana");
  } catch (e) {
    yield put(deleteRound.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri brisanju grupe");
  }
}


export function* updateExerciseInRoundSaga({ payload: { exerciseInRound } }) {
  try {
    const query = updateExerciseInRoundMutation(exerciseInRound);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();
    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.errors);
    }
    yield put(updateExerciseInRound.success(exerciseInRound));
    yield Alert.setSuccessAlert("Vežba je uspešno izmenjena");
  } catch (e) {
    yield put(updateExerciseInRound.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri izmeni vežbe");
  }
}

export function* deleteExerciseInRoundSaga({ payload: { exerciseInRoundId } }) {
  try {
    const query = deleteExerciseInRoundMutation(exerciseInRoundId);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();
    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.errors);
    }
    yield put(deleteExerciseInRound.success(response?.data?.data?.deleteExerciseInRound?.id));
    yield Alert.setSuccessAlert("Vežba je uspešno izbrisana");
  } catch (e) {
    yield put(deleteExerciseInRound.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri brisanju vežbe");
  }
}

export function* swapExercisesInRoundSaga({ payload: { roundId, exerciseInRoundId1, exerciseInRoundId2 } }) {
  try {
    const query = swapExercisesInRoundMutation(exerciseInRoundId1, exerciseInRoundId2);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();
    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.errors);
    }
    console.log(response?.data?.data?.swapExercisesInRound)
    yield put(swapExercisesInRound.success({roundId, exerciseInRoundId1, exerciseInRoundId2}));
  } catch (e) {
    yield put(swapExercisesInRound.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri zameni vežbi");
  }
}


export function* getPreviousExerciseInRoundValueSaga({ payload: { clientId, exerciseId } }) {
  try {
    console.log("Client ID : "+clientId);
    let query = null;
    if(clientId != undefined){
       query = getPreviousExerciseInRoundValueQuery(clientId, exerciseId);
    } else {
       query = getPreviousExerciseInRoundValueQuery(-1, exerciseId);
    }
    const body = JSON.stringify({query});
    const headers = yield getHeaders();

    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )

    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(getClientsPreviousExerciseInRound.success(response?.data?.data?.getPreviousExerciseInRoundValue.exerciseInRound))
  } catch (e) {
    yield put(getClientsPreviousExerciseInRound.failure(e));
  }
}

export function* uploadPDFSaga({ payload: { training, fitnessCenterId, pdfFile } }) {
  console.log("Upload file to s3")
  console.log(pdfFile)
  try {
    const uploadData = yield uploadTrainingToS3(training, fitnessCenterId, pdfFile);
    const pdfURL = uploadData.Location;
    const updatedTraining = {...training, pdfURL: pdfURL}
    const query = updateTrainingMutation(updatedTraining);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();
    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.errors);
    }
    if (!response?.data?.data?.updateTraining?.message?.isRequestSuccessful) {
      throw new Error(response?.data?.data?.updateTraining?.message?.message);
    }
    yield put(updateTraining.success(updatedTraining));
    yield Alert.setSuccessAlert("PDF je uspešno postavljen");
  } catch (e) {
    yield put(updateTraining.failure(e));
    console.log(e)
    yield Alert.setErrorAlert("Desila se greška pri postavljanja PDF-a");
  }
}

function* trainingSaga() {
    yield all([
      takeLatest(TRAININGS.GET.REQUEST, getTrainingsSaga),
      takeLatest(HOME_TRAININGS.GET.REQUEST, filterTrainingsByDateAndExecutorSaga),
      takeLatest(CLIENTS_TRAININGS.GET.REQUEST, getClientsTrainingsSaga),
      takeLatest(INDEPENDENT_USERS_TRAININGS.GET.REQUEST, getIndependentUserTrainingsSaga),
      takeLatest(CLIENTS_TRAININGS_PAGINATION.GET.REQUEST, getClientsTrainingsPaginationSaga),
      takeLatest(INDEPENDENT_USER_TRAININGS.GET.REQUEST, getIndependentUserTrainingsPaginationSaga),
      takeLatest(TRAINING.POST.REQUEST, createTrainingSaga),
      takeLatest(TRAINING.DELETE.REQUEST, deleteTrainingSaga),
      takeLatest(TRAINING.PUT.REQUEST, updateTrainingSaga),
      takeLatest(ROUND.POST.REQUEST, createRoundSaga),
      takeLatest(ROUND.PUT.REQUEST, updateRoundSaga),
      takeLatest(ROUND.DELETE.REQUEST, deleteRoundSaga),
      takeLatest(EXERCISE_IN_ROUND.PUT.REQUEST, updateExerciseInRoundSaga),
      takeLatest(EXERCISE_IN_ROUND.DELETE.REQUEST, deleteExerciseInRoundSaga),
      takeLatest(PREVIOUS_EXERCISE_IN_ROUND.GET.REQUEST, getPreviousExerciseInRoundValueSaga),
      takeLatest(SWAP_EXERCISES_IN_ROUND.POST.REQUEST, swapExercisesInRoundSaga),
      takeLatest(UPLOAD_PDF.POST.REQUEST, uploadPDFSaga),
    ])
  }
    
export default trainingSaga;
