import * as Alert from '../../utils/Alerts';
import axios from "axios";
import { all, put, takeLatest } from 'redux-saga/effects';
import { getExerciseGroups as getExerciseGroupsQuery } from '../graphql/queries';
import {
  deleteExerciseGroup as deleteExerciseGroupMutation,
  createExerciseGroup as createExerciseGroupMutation,
  updateExerciseGroup as updateExerciseGroupMutation,
} from '../graphql/exerciseGroupMutations';
import { deleteExerciseGroup, EXERCISE_GROUP, EXERCISE_GROUPS, getExerciseGroups, createExerciseGroup, updateExerciseGroup } from '../actions/ExerciseGroupActions';
import { graphQLEndpoint } from '../settings';
import { getHeaders } from './Helper';


export function* getExerciseGroupsSaga() {
  try {
    const query = getExerciseGroupsQuery();
    const body = JSON.stringify({query});
    const headers = yield getHeaders();

    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.errors) {
      throw new Error(response?.errors);
    }

    yield put(getExerciseGroups.success(response?.data?.data?.allExerciseGroup));
  } catch (e) {
    yield put(getExerciseGroups.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri učitavanju grupa vežbi");
  }
}

export function *deleteExerciseGroupSaga({ payload: { id } }) {
  try {
    const query = deleteExerciseGroupMutation(id);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();

    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.data?.errors[0]?.message);
    }

    yield put(deleteExerciseGroup.success(id));
    yield Alert.setSuccessAlert("Grupa vežbi je uspešno izbrisana");
  } catch (e) {
    console.log(e)
    yield put(deleteExerciseGroup.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri brisanju grupe vežbi");
  }
}

export function *createExerciseGroupSaga({ payload: { data } }) {
  try {
    const query = createExerciseGroupMutation(data);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();

    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.data?.errors[0]?.message);
    }

    yield put(createExerciseGroup.success(response?.data?.data?.createExerciseGroup?.exerciseGroup));
    yield Alert.setSuccessAlert("Grupa vežbi je uspešno kreirana");
  } catch (e) {
    console.log(e)
    yield put(createExerciseGroup.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri kreiranju grupe vežbi");
  }
}

export function *updateExerciseGroupSaga({ payload: { data } }) {
  try {
    const query = updateExerciseGroupMutation(data);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();

    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.data?.errors[0]?.message);
    }

    yield put(updateExerciseGroup.success(response?.data?.data?.updateExerciseGroup?.exerciseGroup));
    yield Alert.setSuccessAlert("Grupa vežbi je uspešno izmenjena");
  } catch (e) {
    console.log(e)
    yield put(updateExerciseGroup.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri izmeni grupe vežbi");
  }
}

function* exerciseGroupSaga() {
  yield all([
    takeLatest(EXERCISE_GROUPS.GET.REQUEST, getExerciseGroupsSaga),
    takeLatest(EXERCISE_GROUP.POST.REQUEST, createExerciseGroupSaga),
    takeLatest(EXERCISE_GROUP.DELETE.REQUEST, deleteExerciseGroupSaga),
    takeLatest(EXERCISE_GROUP.PUT.REQUEST, updateExerciseGroupSaga),
  ])
}
  
export default exerciseGroupSaga;
