import { faXmark } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text, ScrollView,  } from 'react-native';
import Modal from 'react-native-modal/dist/modal';
import { Button, List, withTheme } from 'react-native-paper';
import IconButton from '../IconButton';
import { deleteUserFitnessCenter, findUsersByEmail } from '../../../core/actions/UserActions';
import { useDispatch, useSelector } from 'react-redux';
import { filteredUsersSelector, userSelector, usersInFitnessCenterSelector } from '../../../core/selectors/UserSelector';


interface Props {
  theme: any;
  isDialogVisible: boolean;
  onUserSelected: (any) => void;
  onDismiss: () => void;
}

const roles = [
  {'name': 'Admin', 'description': 'Pun pristup svim funkcionalnostima'},
  {'name': 'Trener', 'description': 'Mogućnost kreiranja treninga bez prava na ažuriranje studija'}
]

const UserList = ({theme, isDialogVisible, onUserSelected, onDismiss}: Props) => {
  const themeStyles = styles(theme);
  const [searchQuery, setSearchQuery] = useState('');
  const foundUsers = useSelector(filteredUsersSelector);
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState(null);
  const user = useSelector(userSelector);

  const usersInFitnessCenter = useSelector(usersInFitnessCenterSelector);

  const openDeleteExerciseDialog = (item) => {
    console.log("Delete  user!!");
  //  setSelectedExercise(item);
   // setIsDeleteDialogOpen(true);
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchQuery) {
        dispatch(findUsersByEmail.request(searchQuery));
      }
    }, 1500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchQuery])

  useEffect(() => {
    setSelectedUser(null);
    setSelectedRole(null);
  }, [foundUsers]);

  const removeSelectedUser = () => {
    setSelectedUser(null);
    setSelectedRole(null);
  }

  const handleCancel = () => {
    onDismiss();
  }

  const groupNames = (item) => {
    let groupName = "";
    for(let i=0; i < item.exerciseGroups.length; i++){
      groupName += item.exerciseGroups[i].groupName;
      if(i != item.exerciseGroups.length-1){
        groupName += ", ";
      }
    }
    return groupName;
  }

  useEffect(() => {
    if(usersInFitnessCenter != null){
    const formattedUsers = preparedUsersData(usersInFitnessCenter)
    setFilteredUsers(formattedUsers);
    } else {
    }
  }, [usersInFitnessCenter]);

  const preparedUsersData = (users) => {
    return users.map((item) => {
      return {
        key: item?.id,
        value: `${item.userName}`
    }})
  }

const deleteUser = (filteredUsers) => {
  dispatch(deleteUserFitnessCenter.request(user?.currentFitnessCenter?.id, filteredUsers));
  onDismiss();
}

  return (
    <Modal isVisible={isDialogVisible} onDismiss={onDismiss}>
      <View style={themeStyles.modalContent}>
        <View style={[themeStyles.header, themeStyles.regularBackground]}>
          <View style={themeStyles.buttonHeaderContainer}>
            <IconButton width={10} icon={faXmark} iconSize={20} onPress={onDismiss}/>
          </View>
          <View style={themeStyles.headerTextContainer}>
            <Text style={themeStyles.headerText}>Brisanje korisnika iz Fitnes centra </Text>
          </View>
        </View>
        <ScrollView style={themeStyles.mainContent}>
          <View style={themeStyles.mainContentContainer}>

            <View>
                  {filteredUsers?.map((filteredUsers) =>
                    <List.Item
                      style={themeStyles.alphabetList}
                      title={filteredUsers['value']}
                      description={filteredUsers['value']}
                      onPress={() => deleteUser(filteredUsers['key'])}
                      left={null}
                      right={props => <List.Icon {...props} icon='trash-can-outline'/>}
                      titleStyle={themeStyles.listItem}
                    />
                  )}
            </View>
                <View style={themeStyles.contentContainer}>
                  {foundUsers?.map((user) =>
                    <List.Item
                      title={`${user.personalName} ${user.familyName} [${user.userName}]`}
                      onPress={() => setSelectedUser(user)}
                      right={props => <List.Icon {...props} icon='plus'/>}
                      titleStyle={themeStyles.listItem}
                    />
                  )}
                </View>
              </View>
            <View style={themeStyles.buttonContainer}>
              <Button uppercase={true} textColor={theme.colors.primaryText} style={themeStyles.cancelButton} onPress={handleCancel}>Otkaži</Button>
            </View>
        </ScrollView>
      </View>
    </Modal>
  )
}

const styles = theme => StyleSheet.create({
  regularBackground: {
    backgroundColor: theme.colors.tertiaryBackground,
  },
  header: {
    flexDirection: 'row',
    height: 70,
    width: '100%',
    borderBottomColor: theme.colors.primaryText,
    borderBottomWidth: 1,
  },
  listItem: {
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 16,
  },
  descriptionStyle: {
    fontFamily: theme.fontFamilies.secondaryFont,
    fontSize: 14,
  },
  selectedUser: {
    backgroundColor: '#ccd3c4',
    borderColor: theme.colors.tertiaryBackground,
    borderWidth: 2,
    borderRadius: 15,
  },
  contentContainer: {
    marginTop: 20,
  },
  rolesHeading: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
    borderBottom: theme.colors.primaryText,
    borderBottomWidth: 1,
  },
  rolesHeadingText: {
    fontFamily: theme.fontFamilies.secondaryFont,
    fontColor: theme.colors.primaryFont,
    paddingBottom: 10,
    fontSize: 16,
  },
  buttonHeaderContainer: {
    alignSelf: 'center',
    width: 50,
  },
  mainContentContainer: {
    margin: 15,
  },
  headerText: {
    color: theme.colors.primaryText,
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 20,
    fontWeight: '600',
    flexWrap: 'wrap',
  },
  headerTextContainer: {
    flexGrow: 1,
    alignSelf: 'center',
    marginLeft: -20,
    flex: 1,
  },
  containerStyle: {
    padding: 20,
  },
  modalContent: {
    borderRadius: 10,
    overflow: 'hidden',
  },
  mainContent: {
    flexDirection: 'column',
    backgroundColor: theme.colors.primaryBackground,
  },
  buttonContainer: {
    marginTop: 20,
    paddingBottom: 30
  },
  saveButton: {
    backgroundColor: theme.colors.tertiaryBackground,
    color: theme.colors.lightText,
    fontFamily: theme.fontFamilies.primaryFont,
    marginTop: 20,
    marginBottom: 10,
  },
  cancelButton: {
    backgroundColor: theme.colors.lightText,
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    borderWidth: 1,
    borderColor: theme.colors.primaryText,
  },
  alphabetList: {
    flex: 1,
  },
  indexLetters: {
    color: theme.colors.tertiaryBackground,
    fontSize: 12,
    margin: 10
  },
  itemExerciseGroupText: {
    color: theme.colors.secondaryText,
    fontFamily: theme.fontFamilies.secondaryFont,
    fontSize: 12,
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  exerciseTexts: {
    marginRight: 5,
    numberOfLines: 3,
    flex: 1,
  },
  itemExerciseText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 20,
    fontWeight: '600',
    marginBottom: 5,
  },
  itemExerciseContainer: {
    borderTopColor: theme.colors.primaryText,
    borderTopWidth: 1,
    paddingTop: 10,
    paddingBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  exerciseContainer: {
    flex: 6,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  itemContainer: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 0,
    marginTop: 10,
  },
  sectionHeaderText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 16,
    fontWeight: '600',
  },
  sectionHeader: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    marginTop: 20,
  },
  root: {
    flex: 1,
    backgroundColor: theme.colors.lightText,
  },
})

export default withTheme(UserList);

