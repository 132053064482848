import { action, createRequestTypes } from '../../utils/ReduxHelpers';

import { DELETE, GET, POST, PUT } from '../../utils/Constants';
import { IUser, IUserSettings } from '../models/Models';

export const USER_SETTINGS = createRequestTypes('USER_SETTINGS', [GET, POST, PUT, DELETE]);
export const USER_SETTINGS_LOGIN = 'USER_SETTINGS_LOGIN';

export const addClientsTraining = (data: number) => action(USER_SETTINGS_LOGIN, data);
export const userSattingsLogin = 'USER_SETTINGS_LOGIN';

export const getExercises = {
  request: () => action(USER_SETTINGS.GET.REQUEST),
  success: (payload: Array<IUserSettings>) => action(USER_SETTINGS.GET.SUCCESS, payload),
  failure: (error: string) => action(USER_SETTINGS.GET.FAILURE, error)
};

export const userLogin = {
  request: (username: string, password: string) => action(USER_SETTINGS.POST.REQUEST, { username, password }),
  //  request: (clientId: number, start: number, count: number) => action(CLIENTS_TRAININGS_PAGINATION.GET.REQUEST, { clientId, start, count }),
  success: (payload: any) => action(USER_SETTINGS.POST.SUCCESS, payload),
  failure: (error: string) => action(USER_SETTINGS.POST.FAILURE, error)
};

export const updateUserSettings = (settings: IUserSettings) => action(USER_SETTINGS.PUT, settings);
