import * as Alert from '../../utils/Alerts';
import axios from "axios";
import { all, put, takeLatest } from 'redux-saga/effects';
import { findFitnessCenter as getFitnessCenterByIdQuery } from '../graphql/queries';
import {
    createFitnessCenter as createFitnessCenterMutation,
    updateFitnessCenter as updateFitnessCenterMutation,
  } from '../graphql/fitnessCenterMutations';
import { graphQLEndpoint } from '../settings';
import { getHeaders } from './Helper';
import { FITNESS_CENTER, getFitnessCenter, deleteFitnessCenter, createFitnessCenter, updateFitnessCenter } from '../actions/FitnessCenterActions';
import { setUsersFitnessCenter, addUsersFitnessCenter } from '../actions/UserActions';

export function* getFitnessCenterSaga({payload: {fitnessCenterId}}) {
    try {
      const query = getFitnessCenterByIdQuery(fitnessCenterId);
      const body = JSON.stringify({query});
      const headers = yield getHeaders();
  
      const response = yield axios.post(
        graphQLEndpoint,
        body,
        headers
      )
      console.log(response)
      if (response?.errors) {
        throw new Error(response?.errors);
      }
  
      yield put(getFitnessCenter.success(response?.data?.data?.FitnessCenter));
    } catch (e) {
      yield put(getFitnessCenter.failure(e));
      yield Alert.setErrorAlert("Desila se greška pri učitavanju fitnes centra.");
    }
  }

export function *deleteFitnessCenterSaga({ payload: { id } }) {
  try {
    const query = '';//deleteExerciseGroupMutation(id);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();

    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.data?.errors[0]?.message);
    }

    yield put(deleteFitnessCenter.success(id));
    yield Alert.setSuccessAlert("Fitnes centar je uspešno izbrisan.");
  } catch (e) {
    console.log(e)
    yield put(deleteFitnessCenter.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri brisanju fitness centra!");
  }
}

export function *createFitnessCenterSaga({ payload: { name, address, phoneNumber, emailAddress, userId } }) {
    try {
    const query = createFitnessCenterMutation(name, address, phoneNumber, emailAddress, userId);
    const body = JSON.stringify({query});
    const headers = yield getHeaders();

    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.data?.errors[0]?.message);
    }

    yield put(createFitnessCenter.success(response?.data?.data?.createFitnessCenter?.fitnessCenter));
    yield put(setUsersFitnessCenter(response?.data?.data?.createFitnessCenter?.fitnessCenter));
    yield put(addUsersFitnessCenter(response?.data?.data?.createFitnessCenter?.fitnessCenter));
    yield Alert.setSuccessAlert("Fitnes centar je uspešno kreiran.");
  } catch (e) {
    console.log(e)
    yield put(createFitnessCenter.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri kreiranju fitnes centra!");
  }
}

export function *updateFitnessCenterSaga({ payload: { id, name, address, phoneNumber, emailAddress, userId } }) {
  try {
    const query = updateFitnessCenterMutation( id, name, address, phoneNumber, emailAddress, userId );
    const body = JSON.stringify({query});
    const headers = yield getHeaders();

    const response = yield axios.post(
      graphQLEndpoint,
      body,
      headers
    )
    console.log(response)
    if (response?.data?.errors) {
      throw new Error(response?.data?.errors[0]?.message);
    }

    yield put(updateFitnessCenter.success(response?.data?.data?.updateFitnessCenter?.fitnessCenter));
    yield Alert.setSuccessAlert("Fitnes centar je uspešno izmenjen.");
  } catch (e) {
    console.log(e)
    yield put(updateFitnessCenter.failure(e));
    yield Alert.setErrorAlert("Desila se greška pri izmeni fitnes centra.");
  }
}

function* fitnessCenterSaga() {
  yield all([
    takeLatest(FITNESS_CENTER.GET.REQUEST, getFitnessCenterSaga),
    takeLatest(FITNESS_CENTER.POST.REQUEST, createFitnessCenterSaga),
    takeLatest(FITNESS_CENTER.DELETE.REQUEST, deleteFitnessCenterSaga),
    takeLatest(FITNESS_CENTER.PUT.REQUEST, updateFitnessCenterSaga),
  ])
}

export default fitnessCenterSaga;
