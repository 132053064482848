import { IActivity, IFitnessCenter } from '../models/Models';
import { FITNESS_CENTER } from '../actions/FitnessCenterActions';
import { produce } from 'immer';

export interface IFitnessCenterState {
  fitnessCenter: IFitnessCenter;
  fitnessCenters: IFitnessCenter[];
  updatedFitnessCenters: IFitnessCenter[];
}

const initialState: IFitnessCenterState = {
  fitnessCenter: null,
  fitnessCenters: null,
  updatedFitnessCenters: null
};

const fitnessCenterReducer = (state = initialState, action: IActivity): IFitnessCenterState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case FITNESS_CENTER.DELETE.SUCCESS:
        draft.fitnessCenters = state.fitnessCenters.filter((fc) => fc.id !== payload);
        break;
      case FITNESS_CENTER.POST.SUCCESS:
        draft.fitnessCenter = payload;
        break;
      case FITNESS_CENTER.PUT.SUCCESS:
        let updateFitnessCenter = {...payload}
        draft.fitnessCenter = updateFitnessCenter;
        break;
      default:
        return draft;
    }
  });

export default fitnessCenterReducer;
