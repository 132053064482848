import React, { useEffect, useState } from 'react';
import { View, StyleSheet, ScrollView, Pressable, Image } from 'react-native';
import { withTheme, Button, Paragraph } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { OutlinedPasswordInput, OutlinedTextInput } from './components/InputComponents';
import { ErrorMessage } from './components/MessageComponents';
import { signUp } from '../core/actions/AuthActions';
import { Text } from 'react-native';
import { ImagesAssets } from '../assets/ImagesAssets';
import { getSignupCompletedSelector } from '../core/selectors/AuthSelector';


const SignUpScreen = ({theme, navigation}) => {

  const themeStyles = styles(theme);
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [username, setUsername] = useState('');
  const [errors, setErrors] = useState({
    username: false,
    email: false,
    name: false,
    familyName: false,
    password: false,
    repeatPassword: false,
    passwordsMatch: false,
  })
  const [passwordError, setPasswordError] = useState('')
  const signupCompleted = useSelector(getSignupCompletedSelector);


  const handleCacnel = () => {
    setName('');
    setFamilyName('');
    setEmail('');
    setPassword('');
    setRepeatPassword('');
  }


  const onCancel = () => {
    console.log("back")
  }

  const isEmailValid = (text) => {
    if (text.trim() == '') {
      return false;
    }
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    return reg.test(text) === true;
  }

  const isNotEmpty = (text) => {
    return text.trim() != '';
  }


  const validateEmail = () => {
    const isValid = isEmailValid(email);
    setErrors({...errors, email: !isValid});
  }

  const validateName = () => {
    const isValid = isNotEmpty(name);
    setErrors({...errors, name: !isValid});
  }

  const validateFamilyName = () => {
    const isValid = isNotEmpty(familyName);
    setErrors({...errors, familyName: !isValid});
  }


  const validateUsername = () => {
    const isValid = isNotEmpty(username);
    setErrors({...errors, username: !isValid});
  }

  const passwordValidation = (passwordInputValue) => {
    const uppercaseRegExp   = /(?=.*?[A-Z])/;
    const lowercaseRegExp   = /(?=.*?[a-z])/;
    const digitsRegExp      = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-_])/;
    const minLengthRegExp   = /.{8,}/;
    const passwordLength =      passwordInputValue.length;
    const uppercasePassword =   uppercaseRegExp.test(passwordInputValue);
    const lowercasePassword =   lowercaseRegExp.test(passwordInputValue);
    const digitsPassword =      digitsRegExp.test(passwordInputValue);
    const specialCharPassword = specialCharRegExp.test(passwordInputValue);
    const minLengthPassword =   minLengthRegExp.test(passwordInputValue);
    let errMsg ="";
    if(passwordLength===0){
      errMsg="Unesite lozinku";
    }else if(!uppercasePassword){
      errMsg='Bar jedno veliko slovo';
    }else if(!lowercasePassword){
      errMsg='Bar jedno malo slovo';
    }else if(!digitsPassword){
      errMsg='Bar jedna cifra';
    }else if(!specialCharPassword){
      errMsg='Bar jedan specijalni karakter';
    }else if(!minLengthPassword){
      errMsg='Bar 8 karaktera';
    }else{
      errMsg='';
    }
      return errMsg;
    }


  const validatePassword = () => {
    const errMsg = passwordValidation(password);
    setPasswordError(errMsg);
    console.log(errMsg);
    let passwordsMatch = true;
    if (repeatPassword) {
      passwordsMatch = repeatPassword == password;
    }
    setErrors({...errors, password: errMsg != '', passwordsMatch: !passwordsMatch});
  }

  const isPasswordsMatch = (password1, password2) => {
    let passwordsMatch = true;
    if (password2) {
      passwordsMatch = password1 == password2;
    }
    return passwordsMatch;
  }
  const validateRepeatedPassword = () => {
    setErrors({...errors, passwordsMatch: !isPasswordsMatch(password, repeatPassword)});
  }

  const onEmailChange = (text) => {
    const isValid = isEmailValid(email);
    if (errors.email && isValid) {
      setErrors({...errors, email: !isValid});
    }
    setEmail(text);
  }

  const onPasswordChange = (text) => {
    const errMsg = passwordValidation(text);
    console.log(errMsg)
    let passwordsMatch = true;
    if (repeatPassword) {
      passwordsMatch = repeatPassword == text;
    }
    setPasswordError(errMsg);
    setErrors({...errors, passwordsMatch: !isPasswordsMatch(text, repeatPassword)});
    setPassword(text);
  }

  const onRepeatedPasswordChange = (text) => {
    setErrors({...errors, passwordsMatch: !isPasswordsMatch(password, text)});
    setRepeatPassword(text);
  }

  const validateAllInputs = () => {
    return isEmailValid(email) && isNotEmpty(name) && isNotEmpty(familyName) &&
      passwordValidation(password) == '' && isNotEmpty(repeatPassword) && isPasswordsMatch(password, repeatPassword)
  }

  const handleSignUp = () => {
    dispatch(signUp.request({
      personalName: name, familyName: familyName, email: email, userName: username, password: password,
      isIndependentUser: false
    }));
  }

  useEffect(() => {
    if (signupCompleted) {
      navigateToLogin()
    }
  }, [signupCompleted, navigation]);

  const navigateToLogin = () => {
    console.log('Navigate to login');
    navigation.navigate('LoginScreen');
  }

  return (
    <View style={themeStyles.root}>
      <View style={styles(theme).header}>
        <Image resizeMode="contain" source={ImagesAssets.logoBig} style={styles(theme).headerImage} />
        <View style={styles(theme).headerTextContainer}>
          <Text style={styles(theme).headerText}>Registracija</Text>
        </View>
      </View>
      <ScrollView style={themeStyles.mainContent}>
        <View>
          <View style={themeStyles.inputContainer}>
            <OutlinedTextInput
              label='Korisničko ime'
              value={username}
              onChangeText={(text) => setUsername(text)}
              onBlur={validateUsername}
              />
              <Paragraph>
                {errors.name && <ErrorMessage text='Popunite korisničko ime'/>}
              </Paragraph>
          </View>
          <View style={themeStyles.inputContainer}>
            <OutlinedTextInput
              label="Email"
              value={email}
              onChangeText={(text) => onEmailChange(text)}
              onBlur={validateEmail}
            />
            <Paragraph>
              {errors.email && <ErrorMessage text='Nevalidna email adresa'/>}
            </Paragraph>
          </View>
          <View style={themeStyles.inputContainer}>
            <OutlinedTextInput
              label='Ime'
              value={name}
              onChangeText={(text) => setName(text)}
              onBlur={validateName}
              />
              <Paragraph>
                {errors.name && <ErrorMessage text='Popunite ime'/>}
              </Paragraph>
          </View>
          <View style={themeStyles.inputContainer}>
            <OutlinedTextInput
              label='Prezime'
              value={familyName}
              onChangeText={(text) => setFamilyName(text)}
              onBlur={validateFamilyName}
              />
              <Paragraph>
                {errors.familyName && <ErrorMessage text='Popunite prezime'/>}
              </Paragraph>
          </View>
          <View style={themeStyles.inputContainer}>
            <OutlinedPasswordInput
              label='Lozinka'
              value={password}
              onChangeText={(text) => onPasswordChange(text)}
              onBlur={validatePassword}
            />
            <Paragraph>
              {passwordError && <ErrorMessage text={passwordError}/>}
            </Paragraph>
          </View>
          <View style={themeStyles.inputContainer}>
            <OutlinedPasswordInput
              label='Ponovljena lozinka'
              value={repeatPassword}
              onChangeText={(text) => onRepeatedPasswordChange(text)}
              onBlur={validateRepeatedPassword}
              />
              <Paragraph>
                {errors.passwordsMatch && <ErrorMessage text='Lozinke nisu iste'/>}
              </Paragraph>
          </View>
        </View>
        <View style={themeStyles.buttonContainer}>
          <Button disabled={!validateAllInputs()} uppercase={true} textColor={'white'} style={themeStyles.signUpButton} onPress={handleSignUp}>Kreiraj nalog</Button>
        </View>
      </ScrollView>
      <View style={styles(theme).footer}>
        <Text style={styles(theme).footerText}>Već imate nalog?</Text>
        <Pressable onPress={navigateToLogin}>
          <Paragraph style={styles(theme).loginLink}>Ulogujte se</Paragraph>
        </Pressable>
      </View>
    </View>
  )
}

const styles = theme => StyleSheet.create({
  mainContent: {
    margin: 20,
  },
  root: {
    flex: 1,
    backgroundColor: '#F5F5F5',
  },
  header: {
    backgroundColor:  theme.colors.tertiaryBackground, // Light gray for the header background
    paddingTop: 20,
    paddingBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerImage: {
    width: 150,
    height: 150,
  },
  headerTextContainer: {
    alignSelf: 'flex-start',
    width: '100%',
    paddingLeft: 20,  // Adjust the padding as needed
  },
  headerText: {
    color: theme.colors.primaryText, // Color from theme for the text
    fontSize: 24,
    marginTop: 10,
    fontWeight: '600',
    fontFamily: theme.fontFamilies.primaryFont,
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    width: '100%',
    justifyContent: 'center',
  },
  fullWidthInput: {
    width: '100%'
  },
  numberContainer: {
    width: 100,
  },
  dateContainer: {
    width: 120,
  },
  inputBorderStyle: {
    borderRadius: 5,
    borderColor: theme.colors.primaryText
  },
  inputContainer: {
    marginBottom: 10,
    marginRight: 15
  },
  descriptionContainer: {
    marginTop: 20,
    marginBottom: 10,
  },
  buttonContainer: {
    // marginLeft: 10,
    marginRight: 10,
    marginTop: 20,
    paddingBottom: 30
  },
  signUpButton: {
    backgroundColor: theme.colors.tertiaryBackground,
    fontFamily: theme.fontFamilies.primaryFont,
  },
  text: {
    fontFamily: theme.fontFamilies.secondaryFont,
    fontSize: 20,
  },
  loginLink: {
    fontSize: 18,
    color: theme.colors.primaryText,
    fontWeight: 'bold',
    textDecorationLine: 'none',
    fontFamily: theme.fontFamilies.primaryFont,
  },
  footer: {
    paddingBottom: 20,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#F5F5F5', // or any other color as needed
},
  footerText: {
    fontSize: 18,
    marginRight: 5,
    fontFamily: theme.fontFamilies.primaryFont,
  },
});

export default withTheme(SignUpScreen);

