
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { Button, withTheme } from 'react-native-paper';
import { OutlinedTextInput, TextArea} from '../components/InputComponents';
import { IFitnessCenter } from '../../core/models/Models';
import { useDispatch, useSelector } from 'react-redux';
import { createFitnessCenter, updateFitnessCenter } from '../../core/actions/FitnessCenterActions';
import { userSelector } from '../../core/selectors/UserSelector';
import CreateEditFitnessCenterInfo from './CreateEditFitnessCenterInfo';
import { getFitnessCenterSelector } from '../../core/selectors/FitnessCenterSelector';

interface Props {
    theme: any;
    fitnessCenter?: IFitnessCenter;
    onSavePressed: (any) => void;
    onCancelPressed: () => void;
}

const CreateFitnessCenterContainer = ({theme, navigation}/*: Props*/) => {

  const user = useSelector(userSelector);
  const themeStyles = styles(theme)
  const dispatch = useDispatch();
  const fitnessCenterUpdated = useSelector(getFitnessCenterSelector);
//Atributi iz java fajla Fitness Center

  const onCancel = () => {
    console.log('Cancel pressed.');
    navigation.navigate('AppSettings');
  }

const onSaveFitnessCenter = (fitnessCenter) => {
    console.log('Handle save!');
      dispatch(createFitnessCenter.request(fitnessCenter.name, fitnessCenter.address, fitnessCenter.phoneNumber, fitnessCenter.emailAddress, user?.id));
      navigation.navigate('AppSettings');
  }

  const onUpdateFitnessCenter = (fitnessCenter) => {
    console.log('Handle update!');
      dispatch(updateFitnessCenter.request(fitnessCenter.id, fitnessCenter.name, fitnessCenter.address, fitnessCenter.phoneNumber, fitnessCenter.emailAddress, user?.id));
      navigation.navigate('AppSettings');
  }

  return (
    <View style={themeStyles.root}>
    <ScrollView style={themeStyles.mainContent}>
    <CreateEditFitnessCenterInfo onSavePressed={onSaveFitnessCenter} onUpdatePressed={onUpdateFitnessCenter} onCancelPressed={onCancel}/>
  </ScrollView>
  </View>
  )
}


const styles = theme => StyleSheet.create({
  root: {
    flex: 1,
  },
  mainContent: {
    backgroundColor: '#ffffff',
    margin: 20,
  },
  horizontalContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  fullWidthInput: {
    width: '100%'
  },
  numberContainer: {
    width: 100,
  },
  dateContainer: {
    width: 120,
  },
  row: {
    flexDirection: "row",
    marginRight: 20,
    flexWrap: 'wrap',
  },
  inputBorderStyle: {
    borderRadius: 5,
    borderColor: theme.colors.primaryText
  },
  inputContainer: {
    marginBottom: 10,
    marginRight: 15
  },
  descriptionContainer: {
    marginTop: 20,
    marginBottom: 10,
  },
  buttonContainer: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 20,
    paddingBottom: 30
  },
  saveButton: {
    backgroundColor: theme.colors.primaryText,
    color: theme.colors.lightText,
    fontFamily: theme.fontFamilies.primaryFont,
    marginTop: 20,
    marginBottom: 10,
  },
  cancelButton: {
    backgroundColor: theme.colors.lightText,
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    borderWidth: 1,
    borderColor: theme.colors.primaryText,
  },

})

export default withTheme(CreateFitnessCenterContainer);

