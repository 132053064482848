import { action, createRequestTypes } from '../../utils/ReduxHelpers';

import { DELETE, GET, POST, PUT } from '../../utils/Constants';
import { IFitnessCenter, IUser, IUserFitnessCenter } from '../models/Models';

export const USER = createRequestTypes('USER', [POST, PUT, DELETE, GET]);

export const FIND_USERS = createRequestTypes('FIND_USERS', [GET]);

export const FITNESS_CENTER_USERS = createRequestTypes('FITNESS_CENTER_USERS', [GET]);

export const USER_FITNESS_CENTER = createRequestTypes('USER_FITNESS_CENTER', [POST, PUT, DELETE]);

export const CLEAR_USER = "CLEAR_USER";

export const SET_FITNESS_CENTER = "SET_FITNESS_CENTER";

export const ADD_FITNESS_CENTER = "ADD_FITNESS_CENTER";


export const getUser = {
  request: () => action(USER.GET.REQUEST, {}),
  success: (payload: any) => action(USER.GET.SUCCESS, payload),
  failure: (error: string) => action(USER.GET.FAILURE, error)
};

export const findUsersByEmail = {
  request: (email: string) => action(FIND_USERS.GET.REQUEST, {email}),
  success: (payload: any) => action(FIND_USERS.GET.SUCCESS, payload),
  failure: (error: string) => action(FIND_USERS.GET.FAILURE, error)
};

export const findUsersByFitnessCenter = {
  request: (fitnessCenterId: number) => action(FITNESS_CENTER_USERS.GET.REQUEST, {fitnessCenterId}),
  success: (payload: Array<IUser>) => action(FITNESS_CENTER_USERS.GET.SUCCESS, payload),
  failure: (error: string) => action(FITNESS_CENTER_USERS.GET.FAILURE, error)
};

export const createUserFitnessCenter = {
  request: (userFitnessCenter: IUserFitnessCenter) => action(USER_FITNESS_CENTER.POST.REQUEST, {userFitnessCenter}),
  success: (payload: any) => action(USER_FITNESS_CENTER.POST.SUCCESS, payload),
  failure: (error: string) => action(USER_FITNESS_CENTER.POST.FAILURE, error)
};

export const deleteUserFitnessCenter = {
  request: (fitnessCenterId: number, id: number) => action(USER_FITNESS_CENTER.DELETE.REQUEST, {fitnessCenterId, id}),
  success: (payload: any) => action(USER_FITNESS_CENTER.DELETE.SUCCESS, payload),
  failure: (error: string) => action(USER_FITNESS_CENTER.DELETE.FAILURE, error)
};

export const clearUser = () => action(CLEAR_USER, {});

export const setUsersFitnessCenter = (fitnessCenter: IFitnessCenter) => action(SET_FITNESS_CENTER, {fitnessCenter});
export const addUsersFitnessCenter = (fitnessCenter: IFitnessCenter) => action(ADD_FITNESS_CENTER, {fitnessCenter});
