import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useDispatch,  useSelector } from 'react-redux';
import BottomMenu from '../components/BottomMenu';
import { getClientsSelector } from '../../core/selectors/ClientSelector';
import { getClients } from '../../core/actions/ClientActions';
import ContainerCard from '../components/ContainerCard';
import { withTheme } from 'react-native-paper';
import { IconTextInput } from '../components/InputComponents';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../components/IconButton';
import { userSelector } from '../../core/selectors/UserSelector';



const pluralizeTraining = (num) => {
  return num == 1 ? 'trening' : 'treninga';
}


const ClientsContainer = ({theme, navigation}) => {
  const dispatch = useDispatch();
  const clients = useSelector(getClientsSelector);
  const [filteredClients, setFilteredClients] = useState(clients);
  const themeStyles = styles(theme);
  const [searchQuery, setSearchQuery] = useState('')
  const user = useSelector(userSelector);

  useEffect(() => {
    if (!clients?.length) {
      dispatch(getClients.request(user?.currentFitnessCenter?.id));
    }
  }, []);

  useEffect(() => {
    setFilteredClients([...clients]);
  }, [clients]);


  const handleSearchClients = (query) => {
    const newFilteredClients = clients.filter((client) =>
      client.name.toLowerCase().includes(query.toLowerCase()) ||
        client.familyName.toLowerCase().includes(query.toLowerCase()));
    setFilteredClients(newFilteredClients);
    setSearchQuery(query);
  }


  const onCardPress = (client) => {
    navigation.navigate('ClientView', {clientId: client?.id})
  }

  const openCreateClient = () => {
    navigation.navigate('CreateClientContainer')
  }

  return (
    <View style={themeStyles.root}>
      <ScrollView style={themeStyles.mainContent}>
        <View style={themeStyles.row}>
          <View style={themeStyles.searchContainer}>
            <IconTextInput
              label="Pretraga"
              value={searchQuery}
              onChangeText={(text) => handleSearchClients(text)}
              icon={'magnify'}
            />
          </View>
          <View style={themeStyles.buttonContainer}>
            <IconButton icon={faPlus} onPress={openCreateClient} iconSize={35}/>
          </View>
        </View>
        <View style={themeStyles.cardsContainer}>
          {filteredClients?.map((client, index) => (
            <ContainerCard key={index} bottomText={`${client?.trainingCount} ${pluralizeTraining(client?.trainingCount)}`} cardText={`${client?.name} ${client?.familyName}`} onCardPress={() => onCardPress(client)}/>
          ))}
        </View>
      </ScrollView>
      <BottomMenu navigation={navigation} currentScreen='Clients'/>
    </View>
  );
}

const styles = theme => StyleSheet.create({
  root: {
    flex: 1,
  },
  mainContent: {
    margin: 20,
    marginBottom: 80,
  },
  inputContainer: {
    margin: 10,
  },
  searchContainer: {
    width: '70%',
    marginRight: 10,
    marginBottom: 20,
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 10,
  },
  cardsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 20,
  },
})

export default withTheme(ClientsContainer);
