import React, { useState } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { Card, withTheme } from 'react-native-paper';
import { IClient } from '../../../core/models/Models';


interface Props {
  theme: object;
  key: number;
  trainingTime: string;
  client: IClient;
  trainingNumber: number;
  isFinished?: boolean;
  trainerName?: string;
  isShowTrainer: boolean;
  onCardPressed: () => void;
}


const TrainingTimeCard = ({ theme, onCardPressed, trainerName, trainingTime, client, trainingNumber, isShowTrainer, isFinished = false }) => {
  const trainerText = isShowTrainer ? `Trener: ${trainerName}` : '';
  const themeStyles = styles(theme);

  return (
      <Card onPress={onCardPressed} style={themeStyles.card}>
          <View style={[themeStyles.cardContent, isFinished ? themeStyles.cardFinished : themeStyles.cardNotFinished]}>
              {/* Left Content */}
              <View style={[themeStyles.trainingCardNameContainer, themeStyles.leftContent]}>
                  <Text style={[themeStyles.trainingCardName, isFinished ? themeStyles.trainingCardNameContainerFinished : themeStyles.trainingCardNameContainerUpcoming]}>{trainingTime}</Text>
                  {isFinished && <Text style={themeStyles.finishedText}>Završen</Text>}
              </View>

              {/* Right Content */}
              <View style={[themeStyles.rightContainer, themeStyles.rightContent]}>
                  <Text style={themeStyles.trainingCardTitle}>{client ? `${client.name} ${client.familyName}` : `Trening broj ${trainingNumber}`}</Text>
                  {client && <Text style={themeStyles.trainingCardSubtitle}>Trening broj {trainingNumber}</Text>}
                  {isShowTrainer && <Text style={themeStyles.trainingCardSubtitle}>{trainerText}</Text>}
              </View>
          </View>
      </Card>
  );
};

export default withTheme(TrainingTimeCard);


const styles = theme => StyleSheet.create({
  card: {
    height: 100,
    cursor: 'pointer',
    marginBottom: 20,
  },
  cardContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  leftContent: {
      // flex: 1, // Takes up only necessary space, adjust flex value as needed for sizing
      // Your existing styles for the left content
  },
  rightContent: {
      flex: 1,
      alignItems: 'flex-start',
  },
  leftCardContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  rightContainer: {
    alignItems: 'flex-start',
    flex: 1,
  },
  finishedText: {
    color: theme.colors.secondaryText,
    textTransform: 'uppercase',
    fontWeight: '600',
  },
  trainingCardNameContainer: {
    marginRight: 20,
    marginLeft: 20,
    justifyContent: 'center',
    alignItems: 'center',
    height: 100,
    padding: 0,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  trainingCardName: {
    fontFamily: theme.colors.secondaryFont,
    fontSize: 32,
    fontWeight: '600',
  },
  trainingCardNameContainerUpcoming: {
    color: theme.colors.primaryText,
  },
  trainingCardNameContainerFinished: {
    color: theme.colors.secondaryText,
  },
  trainingCard: {
    marginBottom: 30,
    height: 100,
    boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.12)',
    borderRadius: 4
  },
  cardFinished: {
    backgroundColor: theme.colors.primaryBackground,
  },
  cardNotFinished: {
    backgroundColor: theme.colors.quaternaryBackground,
  },
  trainingCardTitle: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 18,
    textTransform: 'uppercase',
    fontWeight: '600',
  },
  trainingCardSubtitle: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: '600',
  },
})
