import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text, SafeAreaView, TouchableOpacity } from 'react-native';
import { Calendar, LocaleConfig } from 'react-native-calendars';
import {  Modal, Portal, withTheme } from 'react-native-paper';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';


interface Props {
  theme: any;
  isDialogVisible: boolean;
  onDateSelected: (any) => void;
  hideDialog: () => void;
}


const ClientsDialog = ({theme, isDialogVisible, onDateSelected, hideDialog}: Props) => {
  const themeStyles = styles(theme);


  LocaleConfig.locales['sr'] = {
    monthNames: [
      'Januar',
      'Februar',
      'Mart',
      'April',
      'Maj',
      'Jun',
      'Jul',
      'Avgust',
      'Septembar',
      'Octobar',
      'Novembar',
      'Decembar'
    ],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Avg', 'Sep', 'Okt', 'Nov', 'Dec'],
    dayNames: ['Nedelja', 'Ponedeljak', 'Utorak', 'Sreda', 'Četvrtak', 'Petak', 'Subota'],
    dayNamesShort: ['Ned', 'Pon', 'Uto', 'Sre', 'Čet', 'Pet', 'Sub'],
    today: "Danas"
  };
  LocaleConfig.defaultLocale = 'sr';

  const today = new Date().toISOString()

  return (
    <Portal>
      <Modal visible={isDialogVisible} onDismiss={hideDialog} style={themeStyles.containerStyle}>
        <SafeAreaView style={themeStyles.container}>
            <Calendar
              // Initially visible month. Default = now
              initialDate={today}
              // Handler which gets executed on day press. Default = undefined
              onDayPress={day => {onDateSelected(day)}}
              // Month format in calendar title. Formatting values: http://arshaw.com/xdate/#Formatting
              monthFormat={'MM yyyy'}
              // Handler which gets executed when visible month changes in calendar. Default = undefined
              onMonthChange={month => {
                  console.log('month changed', month);
              }}
              hideArrows={false}
              renderArrow={(direction) => {
                if (direction == 'left') {
                    return (<Text style={themeStyles.arrowsText}>Prethodni</Text>)
                } else {
                    return (<Text style={themeStyles.arrowsText}>Sledeći</Text>)
                }
            }}
              // If hideArrows = false and hideExtraDays = false do not switch month when tapping on greyed out
              // day from another month that is visible in calendar page. Default = false
              firstDay={1}
              // Handler which gets executed when press arrow icon left. It receive a callback can go back month
              onPressArrowLeft={subtractMonth => subtractMonth()}
              // Handler which gets executed when press arrow icon right. It receive a callback can go next month
              onPressArrowRight={addMonth => addMonth()}
              // Disable all touch events for disabled days. can be override with disableTouchEvent in markedDates
              disableAllTouchEventsForDisabledDays={true}
              theme={{
                backgroundColor: theme.colors.primaryBackground,
                calendarBackground: theme.colors.primaryBackground,
                textSectionTitleColor: theme.colors.secondaryText,
                textSectionTitleDisabledColor: theme.colors.secondaryText,
                selectedDayBackgroundColor: theme.colors.tertiaryBackground,
                selectedDayTextColor: theme.colors.lightText,
                todayTextColor: theme.colors.tertiaryBackground,
                dayTextColor:  theme.colors.primaryText,
                textDisabledColor: theme.colors.disabledText,
                indicatorColor: theme.colors.tertiaryBackground,
                textDayFontFamily: 'Source_Sans_Pro',
                textDayHeaderFontFamily: 'Montserrat',
                textDayFontWeight: '500',
                textMonthFontWeight: 'bold',
                textDayHeaderFontWeight: '100',
                textDayFontSize: 16,
                textMonthFontSize: 12,
                textDayHeaderFontSize: 12,
              }}
              />
        </SafeAreaView>
      </Modal>
    </Portal>
  );
};

const styles = theme => StyleSheet.create({
  inputContainer: {
    margin: 10,
  },
  containerStyle: {
    padding: 20,
    margin: 0, // This is the important style you need to set
    alignItems: undefined,
    justifyContent: undefined,
  },
  container: {
    flex: 1,
    backgroundColor: theme.colors.lightText,
  },
  modalContent: {
    borderRadius: 10,
    overflow: 'hidden',
  },
  mainContent: {
    flexDirection: 'column',
    backgroundColor: theme.colors.lightText,
  },
  sectionHeader: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    marginTop: 20,
  },
  itemExerciseContainer: {
    borderTopColor: theme.colors.primaryText,
    borderTopWidth: 1,
    paddingTop: 10,
    paddingBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  sectionHeaderText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 16,
    fontWeight: '600',
  },
  arrowsText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
  },
  itemContainer: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 0,
    marginTop: 10,
  },
  itemExerciseText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 20,
    fontWeight: '600',
    marginBottom: 5,
  },
  itemExerciseGroupText: {
    color: theme.colors.secondaryText,
    fontFamily: theme.fontFamilies.secondaryFont,
    fontSize: 12,
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  exerciseTexts: {
    marginRight: 5,
  },
  exerciseContainer: {
    flex: 6,
    flexDirection: 'row',
  },
  selectedContainer: {
    alignItems: 'flex-end',
    flex: 6,
  },
  indexLetters: {
    color: theme.colors.tertiaryBackground,
    fontSize: 12,
    margin: 10
  }
});



export default withTheme(ClientsDialog);

