import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { useState } from 'react';
import { View, StyleSheet, Text, Pressable } from 'react-native';
import { Card, withTheme } from 'react-native-paper';
import { IRound } from '../../../core/models/Models';


interface Props {
  theme: object;
  round: IRound;
  onCardPressed: () => void;
}


const RoundExerciseInRound = ({theme, exerciseInRound}) => {

  const themeStyles = styles(theme);
  const [isShowNotes, setIsShowNotes] = useState(false);

  const toggleNotes = () => {
    setIsShowNotes(!isShowNotes);
  }

  return (
    <View style={themeStyles.exerciseInRoundContainer} key={exerciseInRound.id}>
      <View>
        <Text style={themeStyles.exerciseGroupText}>{exerciseInRound?.exercise?.exerciseGroup?.name}</Text>
      </View>
      <View>
        <Text style={themeStyles.exerciseText}>{exerciseInRound?.exercise?.name} {exerciseInRound?.difficulty} {exerciseInRound?.numberOfRepetitions  && ('X '+ exerciseInRound?.numberOfRepetitions)}</Text>
      </View>
      {(!isShowNotes && exerciseInRound?.note) ? (
        <Pressable style={themeStyles.toggleInfo} onPress={toggleNotes}>
          <FontAwesomeIcon size={10} icon={faChevronDown} style={themeStyles.toggleIcon}/>
          <Text style={themeStyles.showInstructions}>Prikaži instrukcije</Text>
        </Pressable>
      ) : (exerciseInRound?.note ? (
          <Pressable onPress={toggleNotes}>
            <View style={themeStyles.toggleInfo}>
              <FontAwesomeIcon size={10} icon={faChevronUp} style={themeStyles.toggleIcon} onPress={toggleNotes}/>
              <Text style={themeStyles.showInstructions}>Skloni instrukcije</Text>
            </View>
            <Text>
              {exerciseInRound?.note}
            </Text>
          </Pressable>
      ) : null
      )}
  </View>
  )
}


// TODO on click on exercise, show full
const RoundCard = ({theme, round, onCardPressed}: Props) => {

  const themeStyles = styles(theme);

  return (
    <Pressable onPress={onCardPressed} key={`round_${round.id}`}>
      <Card style={themeStyles.card}>
        <Card.Content style={themeStyles.trainingCard}>
          <View style={themeStyles.roundNumberContainer}>
            <Text style={themeStyles.roundTitleText}>grupa</Text>
            <Text style={themeStyles.roundNumberText}>{round?.roundSequenceNumber}</Text>
          </View>
          <View style={themeStyles.cardMainContent}>
            {!round?.roundExerciseInRound?.length &&
              <View style={themeStyles.emptyTextContainer}>
                <Text style={themeStyles.emptyText}>Trenutno nema dodatih vežbi</Text>
                <Text style={themeStyles.emptyText}>Izbrišite grupu ili dodajte vežbe</Text>
              </View>
            }
            {round?.roundExerciseInRound?.map((exerciseInRound) =>
              <RoundExerciseInRound theme={theme} exerciseInRound={exerciseInRound}/>
            )}
          </View>
        </Card.Content>
      </Card>
    </Pressable>
  )
}

export default withTheme(RoundCard);


const styles = theme => StyleSheet.create({

  roundNumberContainer: {
    width: 100,
    marginRight: 50,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -16,
    backgroundColor: theme.colors.tertiaryBackground,
    marginTop: -16,
    marginBottom: -16,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  card: {
    margin: 10
  },
  cardMainContent: {
    flex: 1,
    flexWrap: 'wrap',
  },
  exerciseInRoundContainer: {
    // marginBottom: 10,
    // marginTop: 5,
    marginLeft: -10,
    // flex: 1,
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  emptyTextContainer: {
    marginLeft: -10,
    marginTop: 15,
  },
  emptyText: {
    fontFamily: theme.fontFamilies.secondaryFont,
    color: theme.colors.disabled,
    fontSize: 14,
    textAlign: 'center'
  },
  roundTitleText: {
    color: theme.colors.lightText,
    fontFamily: theme.fontFamilies.secondaryFont,
    fontSize: 12,
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  roundNumberText: {
    color: theme.colors.lightText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 60,
    fontWeight: '500',
  },
  cardContent: {
    alignContent: 'center',
    width: '100%',
    marginLeft: 60,
  },
  exerciseGroupText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.secondaryFont,
    fontSize: 11,
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  exerciseText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 20,
    fontWeight: '600',
    // marginTop: 5,
    // marginBottom: 5,
  },
  noteText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.secondaryFont,
    fontSize: 12,
    fontWeight: '400',
    borderBottom: 5,
  },
  showInstructions: {
    marginTop: 5,
    color: theme.colors.tertiaryBackground,
    fontFamily: theme.fontFamilies.secondaryFont,
    fontSize: 12,
    fontWeight: '700',
  },
  trainingCard: {
    minHeight: 100,
    backgroundColor: theme.colors.primaryBackground,
    boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row'
  },
  toggleInfo: {
    marginTop: 5,
    marginBottom: 5,
    marginRight: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  toggleIcon: {
    marginTop: 3,
    color: theme.colors.tertiaryBackground,
    marginRight: 3,
  },
})
