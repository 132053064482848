import { useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { withTheme } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { createExercise, updateExercise } from '../../core/actions/ExerciseActions';
import { getExercisesSelector } from '../../core/selectors/ExerciseSelector';
import BottomMenu from '../components/BottomMenu';
import CreateEditExerciseInfo from './CreateEditExerciseInfo';
import { userSelector } from '../../core/selectors/UserSelector';


const EditExerciseContainer = ({theme, navigation}) => {

  const themeStyles = styles(theme);
  const dispatch = useDispatch();
  const exercises = useSelector(getExercisesSelector);
  const route = useRoute();
  const [exercise, setExercise] = useState(undefined);
  const user = useSelector(userSelector);

  useEffect(() => {
    const exerciseId = route?.params["exerciseId"];
    const exercise = exercises?.find((exercise) => exercise?.id == exerciseId)
    setExercise(exercise);
  }, [route?.params]);


  const onSaveExercise = (updatedExercise) => {
    const newExercise = {...updatedExercise, id: exercise?.id}
    dispatch(updateExercise.request(newExercise, user?.currentFitnessCenter?.id));
    navigation.navigate('ViewExercises');
  }

  const onCancel = () => {
    navigation.navigate('ViewExercises');
  }


  return (
    <View style={themeStyles.root}>
      <ScrollView style={themeStyles.mainContent}>
        <CreateEditExerciseInfo exercise={exercise} onSavePressed={onSaveExercise} onCancelPressed={onCancel}/>
      </ScrollView>
      <BottomMenu navigation={navigation} currentScreen='Exercises'/>
    </View>
  )
}

const styles = theme => StyleSheet.create({
  mainContent: {
    backgroundColor: '#ffffff',
    margin: 20,
  },
  root: {
    flex: 1
  }
});

export default withTheme(EditExerciseContainer);

