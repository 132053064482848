// src/config/config.js

const config = {
  AWS_ACCESS_KEY_ID: 'AKIAQBG23PMMLNO7M6UK',
  AWS_SECRET_ACCESS_KEY: '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',
  AWS_REGION: 'eu-west-1',
  // ...other configurations
};

export default config;
