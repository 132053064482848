
export interface IExerciseGroup {
  id?: number;
  name: string;
  exercises?: [IExercise];
}

export interface IExercise {
  id?: number;
  name: string;
  description: string;
  isShared: boolean;
  //description?: string;
  //exerciseGroup: IExerciseGroup;
  exerciseGroups: IExerciseGroup[];
}

export interface IRound {
  id?: number;
  roundSequenceNumber: number;
  roundExerciseInRound: IExerciseInRound[];
}

export interface IExerciseInRound {
  id?: number;
  numberOfRepetitions?: string;
  difficulty?: string;
  note?: string;
  exercise?: IExercise;
  roundId?: number;
}


export enum TrainingStatus {
  DONE = 'DONE',
  READY = 'READY',
  DRAFT = 'DRAFT',
  CANCELED = 'CANCELED',
}


export interface ITraining {
  id?: number;
  date: Date;
  numberOfTrainings: number;
  rounds?: IRound[];
  status: TrainingStatus;
  client?: IClient;
  trainingExecutor?: IUser;
  trainingCreator?: IUser;
  pdfURL?: string;
  pdfFile?: File;
}

export interface IClient {
  id?: number;
  name?: string;
  familyName?: string;
  email?: string;
  phoneNumber?: string;
  additionalInfo?: string;
  trainingList?: [ITraining]
  trainingCount?: number;
  fitnessCenter?: IFitnessCenter;
}

export interface IGraphQLMessage {
  isRequestSuccessful: boolean
	message: string
	id: number
  //clientPackages?: [ClientPackage] 
}

export interface IGraphQLResponseClient {
  message: IGraphQLMessage
	client: IClient
  //clientPackages?: [ClientPackage] 
}

export interface IActivity {
  type: string;
  payload: any;
}


export interface IUserSettings {
  isDarkThemeOn: boolean;
}

export interface IUser {
  id?: number;
  personalName: string;
  familyName: string;
  userName?: string;
  email: string;
  password?: string;
  authorities: string;
  isIndependentUser?: boolean;
  currentFitnessCenter?: IFitnessCenter;
  userFitnessCenterList?: [IUserFitnessCenter];
}

export interface IFitnessCenter {
  id: number;
  name: string;
  address?: string;
  phoneNumber?: string;
  emailAddress?: string;
  userId?: number;
  userFitnessCenterList?: [IUserFitnessCenter];
}

export interface IUserFitnessCenter {
  id: number;
  authorities: string;
  fitnessCenter: IFitnessCenter;
  user?: IUser;
}


export interface IPreviousExercises {
  previous1: Array<number>;
  previous2: Array<number>;
  previous3: Array<number>;
}

