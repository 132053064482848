import { IActivity, IUser } from '../models/Models';
import { produce } from 'immer';
import { CLEAR_USER, FIND_USERS, FITNESS_CENTER_USERS, SET_FITNESS_CENTER, ADD_FITNESS_CENTER, USER, USER_FITNESS_CENTER } from '../actions/UserActions';

export interface IUserState {
  user: IUser;
  filteredUsers: [IUser];
  fitnessCenterUsers: [IUser];
  usersInFitnessCenter: IUser[];
}

const initialState: IUserState = {
  user: null,
  filteredUsers: null,
  fitnessCenterUsers: null,
  usersInFitnessCenter: [
  ]
};

const userReducer = (state = initialState, action: IActivity): IUserState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case USER.GET.SUCCESS: {
        let newUser = {...payload}
        if (payload?.userFitnessCenterList) {
          newUser = {...newUser, currentFitnessCenter: payload?.userFitnessCenterList[0]?.fitnessCenter}
        }
        draft.user = newUser;
        if(draft.user.userFitnessCenterList.length > 0){
          draft.user.isIndependentUser = false;
        } else {
          draft.user.isIndependentUser = true;
        }
        break;
      }
      case FIND_USERS.GET.SUCCESS: {
        const users = payload?.filter((u: IUser) => {
          const centerIds = u?.userFitnessCenterList?.map((center) => center?.fitnessCenter?.id);
          return !centerIds.includes(state.user?.currentFitnessCenter?.id)
        });
        draft.filteredUsers = users;
        break;
      }
      case FITNESS_CENTER_USERS.GET.SUCCESS: {
        draft.usersInFitnessCenter = payload;
        break;
      }
      case CLEAR_USER:
        draft.user = null;
        break;
      case SET_FITNESS_CENTER:
        draft.user.currentFitnessCenter = payload.fitnessCenter;
        draft.user.isIndependentUser = false;
        draft.fitnessCenterUsers = null;
        break;
      case ADD_FITNESS_CENTER:
        draft.user.userFitnessCenterList = payload.fitnessCenter.userFitnessCenterList;
        draft.user.isIndependentUser = false;
        draft.fitnessCenterUsers = null;
        break;
      case USER_FITNESS_CENTER.DELETE.SUCCESS:
        draft.usersInFitnessCenter = state.usersInFitnessCenter.filter((e) => e.id !== payload);
        break;
      default:
        return draft;
    }
  });

export default userReducer;
