import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text, SafeAreaView, TouchableOpacity, ScrollView } from 'react-native';
import { Chip, withTheme } from 'react-native-paper';
import { AlphabetList } from "react-native-section-alphabet-list";
import { useDispatch, useSelector } from 'react-redux';
import { deleteExercise, getExercises } from '../../core/actions/ExerciseActions';
import { deleteExerciseGroup, getExerciseGroups } from '../../core/actions/ExerciseGroupActions';
import { getExerciseGroupsSelector } from '../../core/selectors/ExerciseGroupSelectors';
import { getExercisesSelector } from '../../core/selectors/ExerciseSelector';
import { alphabetListIndex } from '../../utils/Constants';
import BottomMenu from '../components/BottomMenu';
import ConfirmationDialog from '../components/ConfirmationDialog';
import IconButton from '../components/IconButton';
import { IconTextInput } from '../components/InputComponents';


interface Props {
  theme: any;
  navigation: any;
}


const preparateExerciseGroupsData = (exerciseGroups) => {
  return exerciseGroups.map((item) => {
    return {
      key: item?.id,
      value: `${item.name}`,
  }})
}


const ExerciseGroupsContainer = ({navigation, theme}: Props) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [exerciseGroupsData, setExerciseGroupsData] = useState([]);
  const [filteredExerciseGroupsData, setFilteredExerciseGroupsData] = useState([]);
  const exerciseGroups = useSelector(getExerciseGroupsSelector);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedExerciseGroup, setSelectedExerciseGroup] = useState(undefined);
  const themeStyles = styles(theme)
  const dispatch = useDispatch();

  useEffect(() => {
    if (!exerciseGroups?.length) {
      dispatch(getExerciseGroups.request());
    }
  }, []);

  useEffect(() => {
    const formattedExerciseGroups = preparateExerciseGroupsData(exerciseGroups)
    setExerciseGroupsData(formattedExerciseGroups);
    setFilteredExerciseGroupsData(formattedExerciseGroups);
  }, [exerciseGroups]);



  const handleSearchExerciseGroups = (query) => {
    const newFilteredExerciseGroups = exerciseGroupsData.filter((exerciseGroup) =>
    exerciseGroup.value.toLowerCase().includes(query.toLowerCase()));
    setFilteredExerciseGroupsData(newFilteredExerciseGroups);
    setSearchQuery(query);
  }

  const handleDeleteExerciseGroup = () => {
    setSelectedExerciseGroup(undefined);
    setIsDeleteDialogOpen(false);
    dispatch(deleteExerciseGroup.request(selectedExerciseGroup.key));
  }

  const openDeleteExerciseGroupDialog = (item) => {
    setSelectedExerciseGroup(item);
    setIsDeleteDialogOpen(true);
  }

  const hideDeleteExerciseGroupDialog = () => {
    setIsDeleteDialogOpen(false);
  }

  const openCreateExerciseGroup = () => {
    navigation.navigate('CreateExerciseGroup')
  }

  const openEditExerciseGroup = (exerciseGroupItem) => {
    const selected = exerciseGroups?.find((exerciseGroup) => exerciseGroup?.id == exerciseGroupItem?.key)
    navigation.navigate('EditExerciseGroup', {exerciseGroup: selected})
  }

  const renderListItem = (item: any) => {
    return (
      <View style={themeStyles.itemContainer}>
        <View style={themeStyles.itemExerciseContainer}>
          <TouchableOpacity style={themeStyles.exerciseContainer} onPress={() => openEditExerciseGroup(item)}>
            <View style={themeStyles.exerciseTexts}>
              <Text style={themeStyles.itemExerciseText}>{item.value}</Text>
            </View>
            <View>
          </View>
          </TouchableOpacity>

          <IconButton icon={faTrash} onPress={() => openDeleteExerciseGroupDialog(item)} iconSize={20}/>
        </View>
      </View >
    );
  };

  const renderSectionHeader = (section: any) => {
    return (
      <View style={themeStyles.sectionHeader}>
        <Text style={themeStyles.sectionHeaderText}>{section.title}</Text>
      </View>
    );
  };

  const renderHeader = () => {
    return (
      <View>
        {/* <ScreenHeader buttonText='Dalje' onButtonPressed={openExerciseGroupsInfoDialog}/> */}
        <View style={themeStyles.inputContainer}>
          <View style={themeStyles.row}>
            <View style={themeStyles.searchContainer}>
              <IconTextInput
                label="Pretraga"
                value={searchQuery}
                onChangeText={(text) => handleSearchExerciseGroups(text)}
                icon={'magnify'}
              />
            </View>
            <View style={themeStyles.buttonContainer}>
              <IconButton icon={faPlus} onPress={openCreateExerciseGroup} iconSize={35}/>
            </View>
          </View>
        </View>
        <ConfirmationDialog
          title="Potvrda"
          text={`Da li ste sigurni da želite da izbrišete grupu vežbi "${selectedExerciseGroup?.value || ''}"?`}
          isOpen={isDeleteDialogOpen}
          hideDialog={hideDeleteExerciseGroupDialog}
          onCofirmClicked={handleDeleteExerciseGroup}
      />
      </View>
    );
  };

  return (
    <View style={themeStyles.root}>
      <ScrollView>
        {renderHeader()}
        <View>
          <AlphabetList
            style={themeStyles.alphabetList}
            data={filteredExerciseGroupsData}
            renderCustomItem={renderListItem}
            renderCustomSectionHeader={renderSectionHeader}
            indexLetterStyle={themeStyles.indexLetters}
            index={alphabetListIndex}
            />
        </View>
      </ScrollView>
      <BottomMenu navigation={navigation} currentScreen='ViewExerciseGroups'/>
    </View>
  );
};

const styles = theme => StyleSheet.create({
  inputContainer: {
    margin: 10,
  },
  containerStyle: {
    padding: 20,
  },
  root: {
    flex: 1,
    backgroundColor: theme.colors.lightText,
  },
  mainContent: {
    flexDirection: 'column',
    backgroundColor: theme.colors.lightText,
  },
  alphabetList: {
    flex: 1,
  },
  sectionHeader: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    marginTop: 20,
  },
  sectionHeaderText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 16,
    fontWeight: '600',
  },

  itemContainer: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 0,
    marginTop: 10,
  },
  itemExerciseText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 20,
    fontWeight: '600',
    marginBottom: 5,
  },
  itemExerciseGroupText: {
    color: theme.colors.secondaryText,
    fontFamily: theme.fontFamilies.secondaryFont,
    fontSize: 12,
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  itemExerciseContainer: {
    borderTopColor: theme.colors.primaryText,
    borderTopWidth: 1,
    paddingTop: 10,
    paddingBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  exerciseTexts: {
    marginRight: 5,
    numberOfLines: 3,
    flex: 1,
  },
  exerciseContainer: {
    flex: 6,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  selectedContainer: {
    alignItems: 'flex-end',
    flex: 6,
  },
  indexLetters: {
    color: theme.colors.tertiaryBackground,
    fontSize: 12,
    margin: 10
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 10,
  },
  searchContainer: {
    width: '70%',
    marginRight: 20,
    marginBottom: 20,
  },
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 20,
  },
});



export default withTheme(ExerciseGroupsContainer);

