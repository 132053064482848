import AsyncStorage from '@react-native-async-storage/async-storage';

export async function getHeaders() {
  const jwt = await AsyncStorage.getItem('@token')
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Token ${jwt}`
    }
  }
  return headers;
}
