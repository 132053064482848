import React, { useEffect, useState } from 'react';
import { Text, StyleSheet, View, FlatList, ScrollView } from 'react-native';
import { useDispatch,  useSelector } from 'react-redux';
import BottomMenu from '../components/BottomMenu';
import { ScreenHeader, SectionHeader } from '../components/Headers';
import { IClient } from '../../core/models/Models';
import { Button, withTheme } from 'react-native-paper';
import { faCalendarDays, faCheck, faPen, faSearch, faX } from '@fortawesome/free-solid-svg-icons';
import PreviousTrainingCard from '../components/trainings/PreviousTrainingCard';
import { useRoute } from '@react-navigation/native';
import { getClientsTrainingsSelector } from '../../core/selectors/TrainingSelector';
import { deleteClient, updateClient } from '../../core/actions/ClientActions';
import ConfirmationDialog from '../components/ConfirmationDialog';
import { getClientsTrainingsPagination, resetClientsPreviousTrainings, resetClientsTrainings, updateTrainingAction } from '../../core/actions/TrainingActions';
import { getClientByIdSelector } from '../../core/selectors/ClientSelector';
import { OutlinedTextInput, TextArea} from '../components/InputComponents';
import IconButton from '../components/IconButton';
import { emptyTraining } from '../../core/reducers/TrainingReducer';
import { faRunning, faTrash } from '@fortawesome/free-solid-svg-icons';
import { userSelector } from '../../core/selectors/UserSelector';


const ClientsView = ({navigation, theme}) => {
  const dispatch = useDispatch();
  const [clientId, setClientId] = useState(0);
  const client = useSelector(getClientByIdSelector(clientId));
  const clientsTrainings = useSelector(getClientsTrainingsSelector);
  const route = useRoute();
  const [isShowConfirmDialogVisible, setIsShowConfirmDialogVisible] = useState(false);
  const [page, setPage] = useState(0);
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState(client?.additionalInfo);
  const count = 5;
  const user = useSelector(userSelector);

  useEffect(() => {
    setAdditionalInfo(client?.additionalInfo);
  }, [client]);

  useEffect(() => {
    const clientId = route?.params["clientId"];
    setClientId(clientId);
    dispatch(getClientsTrainingsPagination.request(clientId, 0, count));
    setPage(count);
  }, [route?.params]);


  const toggleEditMode = () => {
    setIsEditModeOn(!isEditModeOn);
  }

  const themeStyles = styles(theme);

  const openEditContainer = () => {
    navigation.navigate('EditClientContainer', {client: client})
  }

  const removeClient = () => {
    dispatch(deleteClient.request(client?.id, user?.currentFitnessCenter?.id));
    navigation.navigate('ViewClients');
  }

  const loadMoreTrainings = () => {
    dispatch(getClientsTrainingsPagination.request(client?.id, page , 5));
    setPage(page + count);
  }

  const onClientUpdated = (valueProp) => {
    const updatedClient = {...client, ...valueProp}
    dispatch(updateClient.request(updatedClient, user?.currentFitnessCenter?.id));
  }

  const saveEdits = () => {
    onClientUpdated(
      {additionalInfo: additionalInfo}
    )
    toggleEditMode()
  }

  const cancelEdits = () => {
    setAdditionalInfo(client?.additionalInfo);
    toggleEditMode()
  }
  const openCreateTraining = () => {
    dispatch(updateTrainingAction(emptyTraining, 'create'));
    dispatch(resetClientsPreviousTrainings())
    navigation.navigate('CreateTrainingContainer', {client: client})
  }

  return (
    <View style={themeStyles.root}>
      <ScrollView style={themeStyles.mainContent}>
        <View style={themeStyles.clientHeaderContainer}>
          <View style={themeStyles.clientsAvatarContainer}>
            <View style={themeStyles.clientsAvatar}>
              <Text style={themeStyles.clientsAvatarText}>{client?.name[0]}{client?.familyName[0]}</Text>
            </View>
          </View>
          <View style={themeStyles.clientsInfo}>
            <Text style={themeStyles.clientsNameText}>{client?.name} {client?.familyName}</Text>
            <View style={themeStyles.clientsInfoData}>
              <Text style={themeStyles.clientsInfoDataText}>{client?.email}</Text>
              <Text style={themeStyles.clientsInfoDataText}>{client?.phoneNumber}</Text>
            </View>
            <View style={themeStyles.buttonsContainer}>
              <IconButton icon={faPen} iconSize={20} onPress={openEditContainer} />
              <IconButton icon={faTrash} iconSize={20} onPress={() => setIsShowConfirmDialogVisible(true)} />
              <IconButton icon={faRunning} iconSize={20} onPress={openCreateTraining} />

              {/* <Button uppercase={true} textColor={'white'} style={themeStyles.editButton} onPress={openEditContainer}>Izmena</Button> */}
              {/* <Button uppercase={true} textColor={'white'} style={themeStyles.editButton} onPress={() => setIsShowConfirmDialogVisible(true)}>Brisanje</Button> */}
              {/* <Button uppercase={true} textColor={'white'} style={themeStyles.editButton} onPress={openCreateTraining}>Kreiraj trening</Button> */}
            </View>
          </View>
        </View>

        <View>
          <SectionHeader text='Dodatne informacije' buttonIcon={faPen} onButtonPressed={toggleEditMode}/>
          {!isEditModeOn && client?.additionalInfo &&
              <View>
                <Text style={themeStyles.viewModeText}>{client?.additionalInfo} </Text>
              </View>
            }
            {isEditModeOn &&
              <View>
                <View style={themeStyles.inputContainer}>
                  <TextArea
                    value={additionalInfo}
                    onChangeText={(text) => setAdditionalInfo(text)}
                    label="Dodatne informacije"
                  />
                </View>
                <View style={themeStyles.editButtonContainer}>
                  {isEditModeOn &&
                  <View style={themeStyles.buttonsContainer}>
                    <IconButton  width={50} icon={faCheck} onPress={saveEdits}/>
                    <IconButton  width={50} icon={faX} onPress={cancelEdits}/>
                  </View>
                  }
                </View>
              </View>
            }
        </View>
        <View style={themeStyles.trainingsContainer}>
          <SectionHeader text={'Treninzi'}/>
          {clientsTrainings?.map((training, index) => (
            <PreviousTrainingCard navigation={navigation} training={training} previousTrainingNumber={index+1} key={index}/>
          ))}
        </View>
        {clientsTrainings?.length < client?.trainingCount &&
          <View>
            <Button uppercase={true} textColor={'white'} onPress={loadMoreTrainings} style={themeStyles.loadMoreButton}>Učitaj još</Button>
          </View>
        }
        <ConfirmationDialog
          title="Potvrda"
          text={`Da li ste sigurni da želite da obrišete klijenta"?`}
          isOpen={isShowConfirmDialogVisible}
          hideDialog={() => setIsShowConfirmDialogVisible(false)}
          onCofirmClicked={removeClient}
        />
      </ScrollView>
      <BottomMenu navigation={navigation} currentScreen='Clients'/>
    </View>
  );
}

const styles = theme => StyleSheet.create({
  redBorder: {
    borderTopColor: theme.colors.redMarker,
  },
  orangeBorder: {
    borderTopColor: theme.colors.orangeMarker,
  },
  yellowBorder: {
    borderTopColor: theme.colors.yellowMarker,
  },
  greenBorder: {
    borderTopColor: theme.colors.tertiaryBackground,
  },
  clientHeaderContainer: {
    marginTop: 30,
    flexDirection: 'row',
  },
  clientsAvatarContainer: {

  },
  clientsAvatarText: {
    fontFamily: theme.fontFamilies.primaryFont,
    color: theme.colors.lightText,
    fontSize: 50,
  },
  clientsNameText: {
    fontFamily: theme.fontFamilies.primaryFont,
    color: theme.colors.primaryText,
    fontSize: 20,
    fontWeight: '600',
    textAlign: 'center',
  },
  root: {
    flex: 1,
  },
  mainContent: {
    margin: 20,
    marginBottom: 80,
  },
  clientsAvatar: {
    width: 110,
    height: 110,
    borderRadius: 110/2,
    backgroundColor: theme.colors.tertiaryBackground,
    alignItems: 'center',
    justifyContent: 'center',
  },
  clientsInfo: {
    flexGrow: 1,
    flexWrap: 'wrap',
    marginLeft: 10,
    marginRight: 10,

  },
  clientsInfoData: {
    marginTop: 10,
    // flexDirection: 'row',
    flexWrap: 'wrap'
  },
  clientsInfoDataText: {
    fontFamily: theme.fontFamilies.secondaryFont,
    color: theme.colors.secondaryText,
    fontSize: 16,
    fontWeight: '400',
    flex: 2,
    textAlign: 'center',
    marginTop: 10,
  },
  editButton: {
    backgroundColor: theme.colors.primaryText,
    color: theme.colors.lightText,
    fontFamily: theme.fontFamilies.primaryFont,
    width: 100,
    alignSelf: 'center',
    margin: 20,
  },
  trainingsContainer: {

  },
  loadMoreButton: {
    backgroundColor: theme.colors.primaryText,
    color: theme.colors.lightText,
    fontFamily: theme.fontFamilies.primaryFont,
    width: 120,
    alignSelf: 'center',
    margin: 20,
  },
  buttonsContainer: {
    marginTop: 20,
    maringBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  viewContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 10,
  },
  viewModeText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 15,
    fontWeight: '600',
    marginBottom: 5,
    marginRight: 5,
  },
  editButtonContainer: {
    alignItems: 'flex-end',
    marginTop: 10,
    marginBottom: 10,
  },
  inputContainer: {
    marginBottom: 10,
    marginRight: 15
  }
})

export default withTheme(ClientsView);
