import { showMessage } from "react-native-flash-message";


const DURATION = 3000;

export const setSuccessAlert = (message: string) =>
  showMessage({message: message, type: 'success', duration: DURATION })


export const setInfoAlert = (message: string) =>
  showMessage({message: message, type: 'info', duration: DURATION })


export const setErrorAlert = (message: string) =>
  showMessage({message: message, type: 'danger', duration: DURATION })


export const setWarningAlert = (message: string) =>
  showMessage({message: message, type: 'warning', duration: DURATION })
