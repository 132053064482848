import React, { useState } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { withTheme,  Button, Paragraph } from 'react-native-paper';
import { useDispatch } from 'react-redux';
import BottomMenu from '../components/BottomMenu';
import { changePassword } from '../../core/actions/AuthActions';
import { useSelector } from 'react-redux';
import { userSelector } from '../../core/selectors/UserSelector';
import { OutlinedPasswordInput } from './../components/InputComponents';
import { PasswordChanges } from '../components/RepeatPasswordComponent';
import { validateAllInputs } from '../components/util/PasswordUtil';
interface Props {
  theme: any;
  navigation: any;
  password: string;
  oldPassword: string;
  repeatPassword: string;
  setPassword: (string) => void;
  setOldPassword: (string) => void;
  setRepeatPassword: (string) => void;
}

const UserSettings = ({navigation, theme, password, oldPassword, repeatPassword, setOldPassword, setPassword, setRepeatPassword}: Props) => {

  const themeStyles = styles(theme);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
 /* const [errors, setErrors] = useState({
    username: false,
    email: false,
    name: false,
    familyName: false,
    password: false,
    repeatPassword: false,
    passwordsMatch: false,
  })*/

  const handlePasswordChange = () => {
    dispatch(changePassword.request(oldPassword, password, user?.userName));
  }

  const onOldPasswordChange = (text) => {
    setOldPassword(text);
  }

  const renderContent = () => {
    return (
        <View style={themeStyles.root}>
          <ScrollView style={themeStyles.mainContent}>
            <View style={themeStyles.inputContainer}>
              <OutlinedPasswordInput
                label='Stara lozinka'
                value={oldPassword}
                onChangeText={(text) => onOldPasswordChange(text)}
                onBlur={null}
              />
            </View>
            <PasswordChanges
                    password={password}
                    repeatPassword={repeatPassword}
                    oldPassword={oldPassword}
                    setPassword={(text) => setPassword(text)}
                    setRepeatPassword={(text) => setRepeatPassword(text)}
                    />
            <View style={themeStyles.buttonContainer}>
              <Button disabled={!validateAllInputs(password, repeatPassword, oldPassword)} uppercase={true} textColor={'white'} style={themeStyles.signUpButton} onPress={handlePasswordChange}>Izmeni lozinku</Button>
            </View>
          </ScrollView>
        </View>
    );
  };

  return (
    <View style={themeStyles.root}>
      <ScrollView>
        {renderContent()}
      </ScrollView>
      <BottomMenu navigation={navigation} currentScreen='ViewExerciseGroups'/>
    </View>
  );
};

const styles = theme => StyleSheet.create({
  mainContent: {
    margin: 20,
  },
  inputContainer: {
    margin: 10,
  },
  text: {
    fontFamily: theme.fontFamilies.secondaryFont,
    fontSize: 20,
  },
  signUpButton: {
    backgroundColor: theme.colors.tertiaryBackground,
    fontFamily: theme.fontFamilies.primaryFont,
  },
  root: {
    flex: 1,
    backgroundColor: theme.colors.lightText,
  },
  itemExerciseText: {
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    fontSize: 20,
    fontWeight: '600',
    marginBottom: 5,
  },
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 20,
  },
});



export default withTheme(UserSettings);

