import React, { useState, useEffect } from 'react';
import { View, Platform, Text, TouchableOpacity, StyleSheet, ActivityIndicator } from 'react-native';
import { Linking } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome'; // You can use any other icon library
import { getFileUrl } from '../../utils/AWS';

interface IProps {
  pdfUrl?: string;
  pdfFile?: File;
}


const PdfOpener = ({ url }) => {

  const handleOpenPDF = async () => {
    // Check if the link is supported
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      // Open the link
      await Linking.openURL(url);
    } else {
      console.log(`Don't know how to open this URL: ${url}`);
    }
  };

  return (
    <TouchableOpacity style={styles.iconContainer} onPress={handleOpenPDF}>
      <Icon name="file-pdf-o" size={30} color="#FF5733" />
    </TouchableOpacity>
  );
};



const S3PDFDisplay = ({ pdfUrl, pdfFile }: IProps) => {
  const [fullPdfUrl, setFullPdfUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {

    if (pdfUrl && pdfUrl != 'undefined' && pdfUrl != 'null') {
      // Remove the protocol and split the URL by '.'
      const parts = pdfUrl.replace('https://', '').split('.s3.');

      // Extract the bucket name
      const bucket = parts[0];

      // Split the remainder of the URL by '/' and remove the first element to get the key
      const key = parts[1].split('/').slice(1).join('/');

      const fetchPdfUrl = async () => {
        try {
          const url = await getFileUrl(bucket, key);
          setFullPdfUrl(url);
        } catch (err) {
          setError(err.message);
          console.error('Error fetching PDF URL:', err);
        } finally {
          setLoading(false);
        }
      };

      fetchPdfUrl();
    }
  }, [pdfUrl]);

  useEffect(() => {
    if (pdfFile) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        setFullPdfUrl(e.target.result);
        setLoading(false);
      };
      fileReader.onerror = (e) => {
        setError('Error loading file');
        setLoading(false);
      };
      fileReader.readAsDataURL(pdfFile);
    }
  }, [pdfFile]);


  if (error) {
    return <Text>Desila se greška pri učitavanju PDF-a</Text>;
  }

  if (loading) {
    return <ActivityIndicator size="large" color="#0000ff" />;
  }

  // For iOS, directly loading the PDF URL works, but for Android, using Google Docs Viewer is a workaround for viewing PDFs
  const sourceUri = Platform.OS === 'android' ? `https://docs.google.com/gview?embedded=true&url=${'http://www.pdf995.com/samples/pdf.pdf'}` : pdfUrl;

  return (
    <View style={styles.container}>
      {Platform.OS === 'web' ? (
      <iframe
        width="100%"
        height="600px"
        loading="lazy"
        src={`${fullPdfUrl}#view=fitH`}
      />
      ) : (
        // <Text>PDF trenutno nije podžan u mobilnoj verziji</Text>
        <PdfOpener url={fullPdfUrl}/>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  webview: {
    width: '100%',
    height: '100%',
  },
  iconContainer: {
    // Style your container
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default S3PDFDisplay;
