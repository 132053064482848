import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Text, ScrollView } from 'react-native';
import { Button, withTheme } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmationDialog from '../components/ConfirmationDialog';
import { faTrash, faUpDown } from '@fortawesome/free-solid-svg-icons';
import { SectionHeader } from '../components/Headers';
import ExerciseInRoundCard from '../components/trainings/ExerciseInRoundCard';
import { useRoute } from '@react-navigation/native';
import { getCurrentTrainingSelector } from '../../core/selectors/TrainingSelector';
import { getNewTrainingSelector } from '../../core/selectors/TrainingSelector';
import IconButton from '../components/IconButton';
import { deleteExerciseInRound, deleteRound, swapExercisesInRound, updateExerciseInRound, updateTrainingAction } from '../../core/actions/TrainingActions';
import ExerciseInfoDialog from '../training/ExerciseInfoDialog';
import { IExerciseInRound, ITraining } from '../../core/models/Models';
import { userSelector } from '../../core/selectors/UserSelector';


const AddUpdateRound = ({theme, navigation}) => {

  const route = useRoute();
  const dispatch = useDispatch();
  const [isShowConfirmExistDialogVisible, setIsShowConfirmExitDialogVisible] = useState(false);
  const themeStyles = styles(theme);
  const editTraining = useSelector(getCurrentTrainingSelector);
  const newTraining = useSelector(getNewTrainingSelector);
  const [currentTraining, setCurrentTraining] = useState(undefined);
  const [mode, setMode] = useState('');
  const [round, setRound] = useState(undefined);
  const [roundNumber, setRoundNumber] = useState(-1);
  const [isExerciseInfoDialogOpen, setIsExerciseDialogOpen] = useState(false);
  const [exerciseInRoundToEdit, setExerciseInRoundToEdit] = useState<IExerciseInRound>(undefined);
  const [isExistingTraining, setIsExistingTraining] = useState(currentTraining?.id ? true : false);
  const user = useSelector(userSelector);

  useEffect(() => {
    const modeValue = route.params['mode']
    const roundNumber = route.params["roundNumber"];
    setMode(modeValue);
    if (modeValue == 'create') {
      setCurrentTraining(newTraining);
      setRound(newTraining?.rounds?.find((item) => item?.roundSequenceNumber == roundNumber));
    } else {
      setCurrentTraining(editTraining);
      setRound(editTraining?.rounds?.find((item) => item?.roundSequenceNumber == roundNumber));
    }
    setRoundNumber(roundNumber);
  }, [route?.params]);


  useEffect(() => {
    if (currentTraining && roundNumber > -1) {
      setRound(currentTraining?.rounds?.find((item) => item?.roundSequenceNumber == roundNumber));
    }
    setIsExistingTraining(currentTraining?.id ? true : false);
  }, [currentTraining]);

  useEffect(() => {
    if (mode == 'edit') {
      if (editTraining && roundNumber > -1) {
        setRound(editTraining?.rounds?.find((item) => item?.roundSequenceNumber == roundNumber));
      }
      setIsExistingTraining(editTraining?.id ? true : false);
      setCurrentTraining(editTraining);
    }
  }, [editTraining]);


  useEffect(() => {
    if (mode == 'create') {
      if (newTraining && roundNumber > -1) {
        setRound(newTraining?.rounds?.find((item) => item?.roundSequenceNumber == roundNumber));
      }
      setIsExistingTraining(newTraining?.id ? true : false);
      setCurrentTraining(newTraining);
    }
  }, [newTraining]);


  const openAddExercisesDialog = () => {
      navigation.navigate('AddExercises', {roundNumber: round?.roundSequenceNumber, mode: mode});
  }


  const removeExerciseInRound = (exerciseInRound) => {
    if (!isExistingTraining) {
      const newExercisesInRound = round?.roundExerciseInRound?.filter(
        (item) => item?.exercise?.id != exerciseInRound?.exercise?.id
      );
      const newRound = {...round, roundExerciseInRound: newExercisesInRound};
      const newRounds = currentTraining.rounds.map((r) => r?.roundSequenceNumber == round?.roundSequenceNumber ? newRound : r);
      const newTraining = {...currentTraining, rounds: newRounds};
      dispatch(updateTrainingAction(newTraining, 'create'));
    } else {
      const exerciseInRoundId = exerciseInRound?.id;
      dispatch(deleteExerciseInRound.request(exerciseInRoundId));
    }
  }

  const swapTwoExercisesInRound = (index) => {
    const exerciseInRound1 =  round?.roundExerciseInRound[index-1];
    const exerciseInRound2 =  round?.roundExerciseInRound[index];
    if (!isExistingTraining) {
      const newExercisesInRound = [...round?.roundExerciseInRound]
      newExercisesInRound[index-1] = exerciseInRound2;
      newExercisesInRound[index] = exerciseInRound1;
      const newRound = {...round, roundExerciseInRound: newExercisesInRound};
      const newRounds = currentTraining.rounds.map((r) => r?.roundSequenceNumber == round?.roundSequenceNumber ? newRound : r);
      const newTraining = {...currentTraining, rounds: newRounds};
      dispatch(updateTrainingAction(newTraining, 'create'));
    }
    else {
      dispatch(swapExercisesInRound.request(round?.id, exerciseInRound1?.id, exerciseInRound2?.id));
    }
  }


  const handleSaveExercise = (numberOfRepetition, measurementUnits, note) => {
    if (!exerciseInRoundToEdit) {
      return
    }
    const newExerciseInRound = {...exerciseInRoundToEdit, numberOfRepetitions: numberOfRepetition, note: note, difficulty: measurementUnits};
    if (!isExistingTraining) {
      const newExercisesInRound = round?.roundExerciseInRound.map((item) =>
        item?.exercise?.id == newExerciseInRound?.exercise?.id ? newExerciseInRound : item
      )
      const newRound = {...round, roundExerciseInRound: newExercisesInRound};
      const newRounds = currentTraining.rounds.map((r) => r?.roundSequenceNumber == round?.roundSequenceNumber ? newRound : r);
      const newTraining = {...currentTraining, rounds: newRounds};
      dispatch(updateTrainingAction(newTraining, 'create'));
      setIsExerciseDialogOpen(false);
    } else {
        dispatch(updateExerciseInRound.request(newExerciseInRound));
        setIsExerciseDialogOpen(false);
    }
  }

  const closeExerciseDialog = () => {
    setIsExerciseDialogOpen(false);
  }

  const openExercisesInfoDialog = (exerciseInRound) => {
    setExerciseInRoundToEdit(exerciseInRound);
    setIsExerciseDialogOpen(true);
  }

  const removeRound = () => {
    if (mode == 'create') {
      const newRounds = currentTraining.rounds.filter((r) => r?.roundSequenceNumber != round?.roundSequenceNumber);
      const newTraining = {...currentTraining, rounds: newRounds};
      dispatch(updateTrainingAction(newTraining, 'create'));
      navigation.navigate("CreateTrainingContainer");
    } else {
      dispatch(deleteRound.request(round.id))
      navigation.navigate("EditTrainingContainer");
    }
  }

  const openConfirmationDialog = () => {
    setIsShowConfirmExitDialogVisible(true);
  }

  const navigateToTraining = () => {
    if (mode == 'create') {
      navigation.navigate("CreateTrainingContainer");
    } else {
      navigation.navigate("EditTrainingContainer");
    }
  }

  return (
    <View>
      <ScrollView style={themeStyles.mainContent}>
        <View>
          <SectionHeader text={`Grupa ${round?.roundSequenceNumber}`} buttonText={'Nazad'} onButtonPressed={navigateToTraining}/>
        </View>
        {!round?.roundExerciseInRound?.length && (
          <View>
            <Text style={themeStyles.emptyText}>Trenutno nema dodatih vežbi</Text>
            <Text style={themeStyles.emptyText}>Izbrišite grupu ili dodajte vežbe</Text>
          </View>
        )}
        <View>
          {round?.roundExerciseInRound?.map((exerciseInRound, index) =>
            <View key={index}>
              <View style={themeStyles.editRemoveButtons}>
                {index > 0 &&
                  <IconButton width={50} icon={faUpDown} onPress={() => {swapTwoExercisesInRound(index)}}/>
                }
                <IconButton width={50} icon={faTrash} onPress={() => {removeExerciseInRound(exerciseInRound)}}/>
              </View>
              <ExerciseInRoundCard onCardPressed={() => openExercisesInfoDialog(exerciseInRound)} exerciseInRound={exerciseInRound}/>
            </View>
          )}
        </View>
        <View style={themeStyles.buttonContainer}>
          <Button uppercase={true} textColor={theme.colors.lightText} style={themeStyles.addExercisesButton} onPress={openAddExercisesDialog}>Dodaj vezbe</Button>
          <Button uppercase={true} textColor={theme.colors.primaryText} style={themeStyles.deleteExercisesButton} onPress={openConfirmationDialog}>Izbrisi grupu</Button>
        </View>
      </ScrollView>
      <ConfirmationDialog
        title="Potvrda"
        text={`Da li ste sigurni da želite da obrišete grupu"?`}
        isOpen={isShowConfirmExistDialogVisible}
        hideDialog={() => setIsShowConfirmExitDialogVisible(false)}
        onCofirmClicked={removeRound}
      />
      <ExerciseInfoDialog
        exerciseInRound={exerciseInRoundToEdit}
        isDialogVisible={isExerciseInfoDialogOpen}
        isLastExercise={true}
        onDismiss={closeExerciseDialog}
        onSave={handleSaveExercise}
        previousExercises={undefined}
        currentTraining={currentTraining}
        />
    </View>
  )
}


const styles = theme => StyleSheet.create({
  previousTrainingsContainer: {
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  mainContent: {
    backgroundColor: '#ffffff',
    margin: 20,
  },
  headerText: {
    fontFamily: theme.fontFamilies.primaryFont,
    color: theme.colors.primaryText,
    fontSize: 18,
    textAlign: 'center'
  },
  hederSaveText: {
    fontFamily: theme.fontFamilies.secondaryFont,
    color: theme.colors.primaryText,
    fontSize: 16,
    textAlign: 'right'
  },
  headerContainer: {
    height: 36,
    justifyContent: 'center',
    flex: 8,
  },
  saveButton: {
    marginTop: 5,
    marginLeft: 'auto'
  },
  inputStyle: {
    margin: 5
  },
  buttonContainer: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 20,
    paddingBottom: 30
  },
  emptyText: {
    fontFamily: theme.fontFamilies.secondaryFont,
    color: theme.colors.disabled,
    fontSize: 14,
    textAlign: 'center'
  },
  addExercisesButton: {
    backgroundColor: theme.colors.primaryText,
    color: theme.colors.lightText,
    fontFamily: theme.fontFamilies.primaryFont,
    marginTop: 20,
    marginBottom: 10,
  },
  deleteExercisesButton: {
    backgroundColor: theme.colors.lightText,
    color: theme.colors.primaryText,
    fontFamily: theme.fontFamilies.primaryFont,
    borderWidth: 1,
    borderColor: theme.colors.primaryText,
  },
  editRemoveButtons: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    width: "100%",
    margin: 5,
  }
})

export default withTheme(AddUpdateRound);
